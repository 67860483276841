/** @jsxImportSource theme-ui */
export const Tablet = ({ color = 'black', secondaryColor = 'white', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      sx={{
        transition: 'transform 0.3s',
        transform: 'rotate(180deg)', 
      }}
    >
      <path
        d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-384c0-35.3-28.7-64-64-64L64 0z"
        sx={{ fill: color }}
      />
      <path
        d="M176 432l96 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-96 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
        sx={{ fill: secondaryColor }}
      />
    </svg>
  );
};
