import { Card, Box, Flex, Input, Text, Button } from "theme-ui";
import Loader from "../../../Shared/Loader";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { useEffect, useMemo, useState } from "react";
import {
  getCashDevices,
  getStoreLocations,
} from "@brinks/common/reducers/storeLocationSlice";
import {
  setSealBagnumber,
  setLocation,
  setCashDevice,
} from "@brinks/common/reducers/registerSealbagSlice";
import Location from "./Location";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../../utils/secure-storage-utils";
import CashDevice from "./CashDevice";
import { CashDevice as ICashDevice, MerchantsAndLocations } from "@brinks/common/api/Api";
import { setCurrentMerchant } from "@brinks/common/reducers/merchantsDetailSlice";
import SelectMerchants from "./Merchants";
import { useOktaAuth } from "@okta/okta-react";
import { getCurrentUserForApi } from "@brinks/common/utils";

export default function SelectLocation({ nextStep }: any) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // TESTE USER
  const { oktaAuth } = useOktaAuth();
  const token: any = oktaAuth.getAccessToken();
  const userRole = getCurrentUserForApi(token);
  const user = getCurrentUser();
  const userType = userRole;

  const { currentMerchant,loadingMerchants } = useAppSelector((state) => state.merchantsDetailSlice);

  const { storeLocations, cashDevices, loading } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const { bagNumber, selectedLocation, selectedCashDevice } = useAppSelector(
    (state) => state.registerSealbagSlice
  );

  const [isValid, setIsvalid] = useState(false);
  const [isCashDeviceNotSelected, setCashDeviceNotSelected] = useState(false);
  const [isLocationSelected, setIsvalidLocation] = useState(false);
  const [isMerchantSelected, setIsMerchantSelected] = useState(false);

  const [location, setLocationDetails] = useState(selectedLocation);
  const [number, setBagnumber] = useState(bagNumber);

  const [merchantFilter, setMerchantFilter] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [merchantsArray, setMerchantsArray] = useState<MerchantsAndLocations[]>([]);

  useEffect(() => {
    const fetchStoreLocations = async () => {
      try {
        await dispatch(getStoreLocations({ hasCashDevices: true, userType: userType }));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching store locations:", error);
      }
    };

    fetchStoreLocations();
  }, []);

  const locations = useMemo(() => {
    if(!storeLocations || loading) return [];

    const locations = storeLocations;

    return merchantFilter
      ? locations.filter((location) => location.merchantId === merchantFilter)
      : locations;

  }, [merchantFilter, storeLocations]);

  useEffect(() => {
    if (!storeLocations || loading) return;

    const merchantMap = new Map();

    storeLocations
      .filter(storeLocation => storeLocation.merchant)
      .forEach(storeLocation => {
        const merchantId = storeLocation?.merchant?.id;

        if (!merchantMap.has(merchantId)) {
          merchantMap.set(merchantId, storeLocation.merchant);
        }
      });

    const uniqueMerchants = Array.from(merchantMap.values());
    setMerchantsArray(uniqueMerchants);

  }, [storeLocations]);


  useEffect(() => {
    if (cashDevices && cashDevices.length === 1)
      onUpdateCashDevice(cashDevices[0]);
  }, [cashDevices]);

  const onUpdateMerchant = (data: any) => {
    if (data) {
      setIsLoading(true);
      dispatch(setCurrentMerchant(data));
      setMerchantFilter(data.id);
      setIsMerchantSelected(false);
      dispatch(setLocation(null));
      setIsLoading(false);
    }
  };

  const onUpdateLocation = (data: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Deposit sealbag | Add deposit",
        Action: "Select location",
      },
    });
    setLocationDetails(data);
    dispatch(setLocation(data));
    dispatch(getCashDevices({ storeId: data.id }));
    onUpdateCashDevice(null);
    setIsvalidLocation(false);

    if (currentMerchant)
    {
      setIsMerchantSelected(false);
    }
  };

  const onUpdateCashDevice = (data: ICashDevice | null) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Deposit sealbag | Add deposit",
        Action: "Select cash-device",
      },
    });
    dispatch(setCashDevice(data));
    setCashDeviceNotSelected(false);
  };

  const onUpdateBagnumber = (data: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Deposit sealbag | Add deposit",
        Action: "Enter bag number" + data,
      },
    });
    setBagnumber(data);
    setIsvalid(false);
  };

  const checkNextStep = () => {

    if (!currentMerchant) {
      setIsMerchantSelected(true);
      return;
    }

    if (!selectedLocation) {
      setIsvalidLocation(true);
      return;
    }

    if (!selectedCashDevice) {
      setCashDeviceNotSelected(true);
      return;
    }

    if (!bagNumber) {
      setIsvalid(true);
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Deposit sealbag | Add deposit",
        Action: "Next",
      },
    });

    nextStep();
  };

  // @TODO: the loading states are not being handled properly and it triggers a flicker on the screen
  if (loading || loadingMerchants || isLoading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Card
        bg="white"
        data-testid="LocationDetails-container-card1"
        sx={{
          alignItems: "center",
          margin: "0 auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
          padding: "39px 32px",
        }}
      >
        <Box
          sx={{
            width: "311px;",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <SelectMerchants merchants={merchantsArray} onUpdateMerchant={onUpdateMerchant}></SelectMerchants>
          {isMerchantSelected && (
            <Flex mt={10} color="red">
              {t("ManageSealBags.select_merchant_msg")}
            </Flex>
          )}
          <Location
            locations={locations}
            onUpdateLocation={(data) => onUpdateLocation(data)}
          />
          {isLocationSelected || isMerchantSelected && (
            <Flex mt={10} color="red">
              {t("ManageSealBags.select_location_msg")}
            </Flex>
          )}
          <CashDevice
            cashDevices={cashDevices}
            onUpdateCashDevice={(data) => onUpdateCashDevice(data)}
          />
          {isCashDeviceNotSelected && (
            <Flex mt={10} color="red">
              {t("ManageSealBags.empty_cash_device_error")}
            </Flex>
          )}
          <Flex
            sx={{
              width: "355px",
              flexDirection: "column",
            }}
          >
            <Box
              data-testid="advanceFilter-Location-box"
              className="custome-select"
              sx={{
                pt: "20px",
              }}
            >
              <Text
                sx={{
                  fontWeight: "body",
                  lineHeight: "register_bag",
                  fontSize: "subText",
                }}
                data-testid="flex-location-text"
              >
                {t("ManageSealBags.bag_number")}
              </Text>
            </Box>
            <Box
              data-testid="advanceFilter-Location-box"
              className="custome-select"
              sx={{
                pt: "8px",
              }}
            >
              <Input
                data-testid="advanceFilterAmountinput-min"
                placeholder={t("ManageSealBags.enter_bag_number")}
                onChange={(e: any) => {
                  onUpdateBagnumber(e.target.value);
                  dispatch(setSealBagnumber(e.target.value));
                }}
                value={bagNumber}
                maxLength={48}
                sx={{
                  width: 350,
                  height: 61,
                  border: "1px solid",
                  borderColor: "shade_200",
                  borderRadius: "6px",
                }}
              />
            </Box>
          </Flex>
          {isValid && (
            <Flex mt={10} color="red">
              {t("ManageSealBags.enter_bagNumber_msg")}
            </Flex>
          )}

          {
            user &&
            user.profile &&
            user.profile.firstName &&
            user.profile.lastName &&
            <Flex
              sx={{
                width: "355px",
                flexDirection: "column",
              }}
            >
              <Box
                data-testid="advanceFilter-Location-box"
                sx={{
                  pt: "20px",
                }}
              >
                <Text
                  sx={{
                    fontWeight: "body",
                    lineHeight: "register_bag",
                    fontSize: "subText",
                  }}
                  data-testid="flex-location-text"
                >
                  {t("ManageSealBags.prepared_by")}
                </Text>
              </Box>
              <Box
                data-testid="advanceFilter-Location-box"
                sx={{
                  mt: "8px",
                }}
              >
                <Text
                  sx={{
                    fontWeight: "normal",
                    lineHeight: "label",
                    fontSize: "body",
                  }}
                  data-testid="flex-location-text"
                >
                  {user.profile.firstName} {user.profile.lastName}
                </Text>
              </Box>
            </Flex>
          }
        </Box>
        <Flex
          mt={20}
          sx={{
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Deposit sealbag | Add deposit",
                  Action: "Cancel",
                },
              });
              navigate("/");
            }}
            mr={20}
            variant="muted"
            data-testid="register-sealbag-step1-next-btn"
            sx={{
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              height: 49,
              width: ["100%", "169px"],
              pointerEvents: `auto`,
              borderRadius: "40px",
              cursor: "pointer",
              backgroundColor: "white",
              color: "royalBlue_500",
              border: "1px solid",
              borderColor: "royalBlue_500",
            }}
          >
            {t("ManageSealBags.cancel")}
          </Button>
          <Button
            onClick={(e: any) => checkNextStep()}
            variant="primary"
            data-testid="register-sealbag-step1-next-btn"
            sx={{
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              height: 49,
              width: ["100%", "169px"],
              pointerEvents: `auto`,
              borderRadius: "40px",
              cursor: "pointer",
              float: "right",
            }}
          >
            {t("ManageSealBags.next")}
          </Button>
        </Flex>
      </Card>
    </>
  );
}
