import { CSSProperties } from "react";

export const InputDisabledStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  height: "61px",
  paddingLeft: "16px",
  paddingRight: "16px",
  color: "var(--theme-ui-colors-shade_400)",
  backgroundColor: "var(--theme-ui-colors-background)",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: "var(--theme-ui-colors-borderColor)",
  userSelect: "none",
};
