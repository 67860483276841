// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";

import { useOktaAuth } from "@okta/okta-react";
import { useTranslation } from "react-i18next";
import { Flex, Heading, Button, Box } from "theme-ui";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useLayoutEffect } from "react";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import OtpModal from "../OtpModal";
import Error from "../../../Shared/Error";
import Loader from "../../../Shared/Loader";
import UserLocations from "./UserLocations";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import UserLocationsToSelect from "./UserLocationsToSelect";
import UserAccountDetailsAvatar from "./UserAccountDetailsAvatar";

import { Api } from "@brinks/common/api/auth";
import { UserType } from "@brinks/common/constants/users";
import { BackIcon } from "@brinks/common/Icons/Components/back";
import { StoreLocation } from "@brinks/common/api/Api";
import { getCurrentUser } from "../../../utils/secure-storage-utils";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { getUsers, resetState, updateUser, } from "@brinks/common/reducers/userSlice";
import { UserLocation, useUserWithLocations } from "../../../Hooks/useUserWithLocations";
import { getCurrentUser as getCurrentUserFromToken, getDeviceId } from "@brinks/common/utils";

import { EditIcon } from "@brinks/common/Icons/Components/edit";

export default function UserAccountDetails() {
  const naviagte = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const { t } = useTranslation();
  const { oktaAuth } = useOktaAuth();
  const {
    filteredUsers,
    allDistinctLocations,
    loading: allUsersLoading,
  } = useUserWithLocations({});

  const { errMsg, success, isFetching, isUpdateUserError } = useAppSelector(
    (state) => state.userSlice
  );

  const token: any = oktaAuth.getAccessToken();
  const userRole = getCurrentUserFromToken(token);

  const [isEdit, setIsEdit] = useState(false);
  const [factorId, setFactorId] = useState(null);
  const [otpErrorCount, setOtpErrorCount] = useState(0);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [user, setUser] = useState<null | UserLocation>(null);
  const [isVerifyOtpError, setIsVerifyOtpError] = useState(false);
  const [merchantSearch, setMerchantSearch] = useState<null | string>(null);
  const [locationsSearch, setLocationsSearch] = useState<null | string>(null);
  const [locations, setLocations] = useState<StoreLocation[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<StoreLocation[]>([]);

  const isSuperUser = user?.type === UserType.SUPER_USER ? true : false;

  const defaultLocationsSelected = () => {
    if(isSuperUser) {
      return allDistinctLocations;
    }

    const user = filteredUsers.find((user) => user.userId === id);

    const userLocations = allDistinctLocations.filter(distinctLocation => {
      return user?.locations.some(userLocation => {
        return distinctLocation.id === userLocation.id;
      });
    });

    return userLocations;
  }

  const validateUser = () => {
    const user = filteredUsers.find((user) => user.userId === id);
    setUser(user ?? null);
  }

  useEffect(() => {
    validateUser();
    setSelectedLocations(defaultLocationsSelected());
  }, [user, allDistinctLocations])

  const handleValidateLocationNameAndCode = ({ name, locationCode }: StoreLocation, filter: string): boolean => {
    if (filter && name?.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }

    if (filter && locationCode?.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    let locationsFiltered: StoreLocation[] = allDistinctLocations;

    if(locationsSearch) {
      locationsFiltered = locationsFiltered.filter(location => {
        return handleValidateLocationNameAndCode(location, locationsSearch);
      })
    }

    if(merchantSearch) {
      locationsFiltered = locationsFiltered.filter(location => {
        return location.merchantId === merchantSearch;
      })
    }

    setLocations(locationsFiltered);
  }, [
    merchantSearch,
    locationsSearch,
    allDistinctLocations
  ])

  const sendOtp = async () => {
    setOtpErrorCount(0);
    setIsVerifyOtpError(false);

    const users = getCurrentUser();
    const data: any = { userId: users.id };

    try {
      const smsResp: any = await new Api().auth.sendSmsCode(data);
      setFactorId(smsResp?.data.factorId);
    } catch (error) {
      // setIsOtpError(true);
    }
  };

  const verifyOtp = async (otp: any) => {
    setIsVerifyOtpError(false);

    const users = getCurrentUser();

    const data: any = {
      userId: users.id,
      securityCode: otp,
      factorId: factorId,
    };

    const params: any = {
      headers: {
        "device-id": getDeviceId(),
      },
    };

    setIsVerifyingOtp(true);
    try {
      const res = await new Api().auth.exchangeFactor(data, params);

      if (res.data.token) {
        localStorage.setItem("mfaToken", res.data.token);
        setIsVerifyingOtp(false);
        const updatedUser: any = { ...user };
        updatedUser.phone = user?.phone ?  user.phone : ''
        updatedUser.locations = selectedLocations.map(location => location.id);
        dispatch(updateUser(updatedUser));
        dispatch(getUsers({}));
        setOpenOtpModal(false);
      }
    } catch (error) {
      setIsVerifyOtpError(true);
      setIsVerifyingOtp(false);
      setOtpErrorCount(otpErrorCount + 1);
    }
  };

  useEffect(() => {
    if (userRole === UserType.STANDARD_USER) {
      naviagte(`/`);
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    setOpenOtpModal(false)
  },[isUpdateUserError])

  useLayoutEffect(() => {
    if (success) {
      dispatch(resetState());
      setOtpErrorCount(0);
      naviagte(`/users`);
    }
  }, [success]);

  const handleSelectLocation = (id: string, isSelected: boolean) => {
    if(isSelected){
      const selectedLocation = allDistinctLocations.find((location) => location.id === id);
      selectedLocation && setSelectedLocations([...selectedLocations, selectedLocation]);
    }
    else {
      const newSelectedLocations = selectedLocations.filter((location) => location.id !== id);
      setSelectedLocations(newSelectedLocations);
    }
  };

  if (!user || isFetching || allUsersLoading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <PageHeader>
        <Flex
          sx={{
            width: "75%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Heading>
            <button
              onClick={(e: any) => window.history.back()}
              style={{ marginRight: "20px", background: "transparent", border: "none", cursor: "pointer" }}
            >
              <BackIcon color={"black"} />
            </button>

            {t("Header.user_account")}
          </Heading>
          {!isSuperUser && !isEdit && (
            <Button
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    Action: "Edit user",
                    event: "Click_event",
                    Page_title: "User management | User account details",
                  },
                });

                setIsEdit(true);
              }}
              variant="ghost"
              sx={{
                height: 48
              }}
            >
              {t("UserAccount.edit_user")}<EditIcon color={"currentColor"} />
            </Button>
          )}
          {isEdit && (
            <Flex sx={{ width: "25%", justifyContent: "flex-end", gap: 2 }}>
              <Button
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      Action: "Cancel",
                      event: "Click_event",
                      Page_title: "User management | Update user account",
                    },
                  });

                  setIsEdit(false);
                }}
                sx={{
                  height: 48
                }}
                variant="ghost"
              >
                {t("UserAccount.cancel")}
              </Button>
              <Button
                disabled={selectedLocations.length === 0}
                variant={`${selectedLocations.length === 0 ? "gray" : "default"}`}
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      Action: "Save",
                      event: "Click_event",
                      Page_title: "User management | Update user account",
                    },
                  });
                  setOpenOtpModal(true);
                  sendOtp();
                }}
                sx={{
                  height: 48,
                }}
              >
                {t("UserAccount.save")}
              </Button>
            </Flex>
          )}
        </Flex>
      </PageHeader>
      <Wrapper>
        <UserAccountDetailsAvatar {...user} />
        {!isEdit && (
          <Heading sx={{ fontSize: "medium" }}>
            {t("UserAccount.assigned_locations")}
          </Heading>
        )}
        {
          !isEdit ?
            <UserLocations
              loading={false}
              userLocations={selectedLocations}
            />
            :
            <UserLocationsToSelect
              user={user}
              isEdit={isEdit}
              loading={false}
              locationsFiltered={locations}
              selectedLocations={selectedLocations}
              searchMerchant={(value) => setMerchantSearch(value)}
              searchLocation={(value: string) => setLocationsSearch(value)}
              handleSelectLocation={(id, value) => { handleSelectLocation(id, value) }}
            />
        }
        <OtpModal
          sendOtp={sendOtp}
          verifyOtp={verifyOtp}
          isFetching={isFetching}
          openOtpModal={openOtpModal}
          otpErrorCount={otpErrorCount}
          isVerifyingOtp={isVerifyingOtp}
          isVerifyOtpError={isVerifyOtpError}
        />
        {isUpdateUserError && <Error message={errMsg} />}
      </Wrapper>
    </>
  );
}
