import { CaseFile } from "@brinks/common/api/Api";
import { Flex } from "theme-ui";

interface Props {
  files: CaseFile[];
}

export const DisplayAttachedFiles = ({ files }: Props) => {
  const isFileExists = files && files.length > 0;
  return (
    <>
      {isFileExists ? (
        <Flex
          data-testid="Support-files"
          sx={{
            flexDirection: "column",
          }}
        >
          {/* <Box
            data-testid="files-label-wrapper"
            sx={{
              fontSize: "body",
              fontWeight: "body",
              lineHeight: "label",
              color: "black",
              fontFamily: "body",
            }}
          >
            {t("support.files")}
          </Box>
          <Box
            data-testid="files-FieldText"
            sx={{
              fontSize: "body",
              fontWeight: "weight_400",
              lineHeight: "body",
              color: "black",
            }}
          > */}
            <ol
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "auto",
                marginBottom: 0,
              }}
            >
              {files?.map(function (item, i) {
                return (
                  <li
                    key={i}
                    data-testid="Created-date-FieldText"
                    style={{
                      color: "var(--theme-ui-colors-text)",
                      marginBottom: 8,
                      fontSize: "14px",
                      cursor: "pointer"
                    }}
                    onClick={(e) =>
                      window.open(item.contentDownloadUrl, "_blank")
                    }
                  >
                    {item.name}
                  </li>
                );
              })}
            </ol>
          {/* </Box> */}
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};
