import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";
import { useState, useEffect, CSSProperties } from "react";
import { Case } from "@brinks/common/api/Api";
import { useAppSelector } from "@brinks/common/hooks/hooks";
import { Flex, Text } from "theme-ui";
import { getStoreLocations } from "@brinks/common/reducers/storeLocationSlice";
import { useAppDispatch } from "@brinks/common/hooks/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { getCurrentUserForApi } from "@brinks/common/utils";

const customControlStyles: CSSProperties = {
  minWidth: "240px",
  height: "54px",
  marginTop: "4px"
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

interface Options {
  label: string;
  value: string;
}

interface SupportListMerchantSelectorProps {
  cases: Case[];
  onChange: (status: string | null) => void;
}

export const SupportListMerchantSelector = ({
  cases,
  onChange,
}: SupportListMerchantSelectorProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { oktaAuth } = useOktaAuth();
  const token: any = oktaAuth.getAccessToken();
  const userRole = getCurrentUserForApi(token);
  const { merchants, loadingMerchants } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );
  const [isLoading, setIsLoading] = useState(true);

  const [options, setOptions] = useState<Options[]>([]);

  const { storeLocations, loading } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  useEffect(() => {
    if (storeLocations) return;

    dispatch(getStoreLocations({ userType: userRole }))
  }, [dispatch, storeLocations, userRole]);


  useEffect(() => {
    if (!storeLocations) return;

    const merchantMap = new Map();

    storeLocations
      .filter((storeLocation) => storeLocation.merchant)
      .forEach((storeLocation) => {
        const merchantId = storeLocation?.merchant?.id;
        if (!merchantMap.has(merchantId)) {
          merchantMap.set(merchantId, storeLocation.merchant);
        }
      });
    const uniqueMerchants = Array.from(merchantMap.values());

    const merchantOptions = uniqueMerchants.map((obj: any) => {
      return { ...obj, label: obj.name, value: obj.id };
    });

    setOptions([
      { value: "ALL", label: t("UserLocationFilter.all") },
      ...merchantOptions,
    ]);

    setIsLoading(false);
  }, [storeLocations, t]);

  const handleChange = (e: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Action: "Merchant " + e.value,
        Page_title: "User management | User overview",
      },
    });

    const option = options.find((option) => option.value === e.value);

    if (option) {
      onChange(option.value === "ALL" ? null : option.value);
    }
  };

  if (loadingMerchants || options.length === 0 || isLoading) {
    return (
      <Flex sx={{ alignItems: "center" }}>
        <Select
          placeholder={`Loading...`}
          options={[]}
          styles={customStyles}
          isDisabled
        />
      </Flex>
    );
  }

  return options.length === 2 && options.find((x) => x.value !== "ALL") ? (
    <Text>
      {options[1].label}
    </Text>
  ) : (
    <Select
      options={options}
      styles={customStyles}
      onChange={handleChange}
      data-testid="advanceFilter-UserLocationMerchant-select"
      placeholder={`${t(
        "CasesFilter.merchant_placeholder"
      )}: ${options[0].label.toUpperCase()}`}
    />
  );
};
