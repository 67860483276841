import moment, { tz } from "moment-timezone";
import { BusinessRule, LocationService } from "@brinks/common/api/Api";
import { BusinessRuleLocationServicesMoneyOrder } from "@brinks/common/api/Api";

interface ValidateOrderChangeDateParams {
  startDate: Date;
  locationBusinessRules: BusinessRuleLocationServicesMoneyOrder;
  locationServices: LocationService;
}

/**
 * This function is responsible for evaluating the start date for
 * the money exchange order.
 * @param ValidateOrderChangeDateParams
 * @returns Date
 */
export const validateOrderChangeDate = ({
  startDate,
  locationBusinessRules,
  locationServices,
}: ValidateOrderChangeDateParams): Date => {
  return validateOrderChangeNextAllowedDay(
    startDate,
    locationBusinessRules,
    locationServices
  );
};

/**
 * This function is responsible for when delivery is carried out
 * on a different day, to carry out the delivery on the first
 * possible day for transport based on the established rules.
 * @param ValidateOrderChangeNextAllowedDayParams
 * @returns Date
 */
export const validateOrderChangeNextAllowedDay = (
  startDate: Date,
  businessRule: BusinessRuleLocationServicesMoneyOrder,
  locationService: LocationService
): Date => {
  const { orderCutOffTime, orderLeadDays } = businessRule;

  const initialDate = moment(startDate);

  let isHourBeforeOrderCot: Boolean = false;

  if (orderCutOffTime !== undefined) {
    const timeParts = orderCutOffTime.split(":");
  
    const localTime = new Date();
    localTime.setUTCHours(parseInt(timeParts[0], 10));
    localTime.setUTCMinutes(parseInt(timeParts[1], 10));
  
    const convertedTime = localTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    isHourBeforeOrderCot = validateOrderChangeDateHour(
      convertedTime,
      startDate
    );
  }

  if (orderLeadDays != null) {
    if (isHourBeforeOrderCot) {
      if (initialDate.day() === 6) {
        //saturday
        initialDate.add(2, "days");
      } else if (initialDate.day() === 0) {
        //sunday
        initialDate.add(1, "days");
      }
    } else {
      if (initialDate.day() === 5) {
        //friday
        initialDate.add(3, "days");
      } else if (initialDate.day() === 6) {
        //saturday
        initialDate.add(2, "days");
      } else {
        initialDate.add("1", "days");
      }
    }
    initialDate.add(orderLeadDays, "days");
  }

  const allowedDayCounter: number = handleGetAllowedDaysInOrderCounter(
    initialDate.toDate(),
    locationService
  );

  return initialDate.add(allowedDayCounter, "days").toDate();
};

interface HandleGetAllowedDaysInOrderCounterParams {
  startDate: Date;
  businessRule: BusinessRule;
}

/**
 * This function is responsible for finding the next day available
 * for transport based on an initial date and, finally, returning
 * the number of days until this specific date
 * (based on the initial date)
 * @param HandleGetAllowedDaysInOrderCounterParams
 * @returns number
 */
export const handleGetAllowedDaysInOrderCounter = (
  startDate: Date,
  businessRuleLocationService: LocationService
): number => {
  const initialDate = moment(startDate);
  const {
    servicingOnSunday,
    servicingOnMonday,
    servicingOnTuesday,
    servicingOnWednesday,
    servicingOnThursday,
    servicingOnFriday,
    servicingOnSaturday,
  } = businessRuleLocationService;

  const initialDateNumber = initialDate.day();
  const allowedDays = [
    servicingOnSunday,
    servicingOnMonday,
    servicingOnTuesday,
    servicingOnWednesday,
    servicingOnThursday,
    servicingOnFriday,
    servicingOnSaturday,
  ];

  const adjustedAllowedDays = [
    ...allowedDays.slice(initialDateNumber),
    ...allowedDays.slice(0, initialDateNumber),
  ];

  return adjustedAllowedDays.indexOf(true);
};

export const validateOrderChangeDateHour = (
  orderCutOffTime: string,
  startDate: Date
): Boolean => {
  const startTime = moment(startDate);
  const cutOffTime = moment(orderCutOffTime, "HH:mm");

  return startTime.format("HH:mm") < cutOffTime.format("HH:mm");
};
