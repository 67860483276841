// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { Flex } from "theme-ui";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
export interface RowElementProps {
    width: string;
    children: any;
    orientation: 'left' | 'center' | 'right';
}

export const RowElement = ({ 
    width,
    children,
    orientation
 }: RowElementProps) => {
    
    return (
        <Flex
            sx={{
                width,
                color: "shade_800",
                alignItems: 'center',
                textAlign: orientation,
                fontWeight: "weight_400",
                justifyContent: orientation,
            }}
        >
            {children}
        </Flex>
    )
}
