interface Props {
  selectedFiles: File[];
}

export const DisplayFiles = ({ selectedFiles }: Props) => {
  return (
    <>
      {Array.from(selectedFiles).length > 0 && (
        <ol
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginBottom: 0,
          }}
        >
          {Array.from(selectedFiles).map(function (item, i) {
            return (
              <li
                key={i}
                style={{
                  color: "var(--theme-ui-colors-text)",
                  marginBottom: 8,
                  fontSize: "14px",
                }}
              >
                {item.name}
              </li>
            );
          })}
        </ol>
      )}
    </>
  );
};
