// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { Divider, Flex } from "theme-ui";
import { useTranslation } from "react-i18next";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import Label from "./Label";
import FieldText from "./FieldText";

import { StoreLocationWithContactAndMerchant } from "@brinks/common/api/Api";

interface LocationDetailsProps {
    locationDetails: StoreLocationWithContactAndMerchant | null;
}

export const LocationDetails = ({ 
  locationDetails 
}: LocationDetailsProps) => {

  const { t } = useTranslation();

  return (
    <>
      <Flex
        mt={39}
        data-testid="LocationDetails-field1"
        sx={{
          flexDirection: "column",
        }}
      >
        <Label data-testid="LocationDetails-location-name"> {t("ManageLocations.location_code")} </Label>
        <FieldText data-testid="LocationDetails-location-name-value">
          {locationDetails?.locationCode}
        </FieldText>
      </Flex>
      {locationDetails?.tradeName &&
        <Flex
          mt={39}
          data-testid="LocationDetails-field2"
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Label data-testid="LocationDetails-trade-name"> {t("ManageLocations.trade_name")}</Label>
          <FieldText data-testid="LocationDetails-trade-name-value">
            {locationDetails?.tradeName}
          </FieldText>
        </Flex>
      }
      <Flex
        mt={39}
        data-testid="LocationDetails-field3"
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Label data-testid="LocationDetails-location-name"> {t("ManageLocations.location_name")}</Label>
        <FieldText data-testid="LocationDetails-location-name-value">
          {locationDetails?.name}
        </FieldText>
      </Flex>
      {locationDetails?.physicalLocation &&
        <>
          <Flex
            mt={39}
            data-testid="LocationDetails-field4"
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Label data-testid="LocationDetails-address-name">{t("address")}</Label>
            <FieldText data-testid="LocationDetails-address-name-value">
            {locationDetails?.physicalLocation?.street}, {locationDetails?.physicalLocation?.houseNumber}, {locationDetails?.physicalLocation?.postalCode}, {locationDetails?.physicalLocation?.city}, {locationDetails?.physicalLocation?.country}
            </FieldText>
          </Flex>
          <Flex
            mt={39}
            data-testid="LocationDetails-field5"
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Label data-testid="LocationDetails-country-name">{t("country")}</Label>
            <FieldText data-testid="LocationDetails-country-name-value">
              {locationDetails?.physicalLocation?.country}
            </FieldText>
          </Flex>
        </>
      }
      {locationDetails?.merchant &&
        <>
          <Divider mt={30} color="divider" />
          <Flex
            mt={39}
            data-testid="LocationDetails-field5"
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Label data-testid="LocationDetails-country-name">{t("LocationDetails.merchant_name")}</Label>
            <FieldText data-testid="LocationDetails-merchant-name-value">
              {locationDetails?.merchant?.name}
            </FieldText>
          </Flex>
          <Flex
            mt={39}
            data-testid="LocationDetails-field4"
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Label data-testid="LocationDetails-address-name">{t("LocationDetails.merchant_address")}</Label>
            <FieldText data-testid="LocationDetails-address-name-value">
              {locationDetails?.merchant?.street}, {locationDetails?.merchant?.postalCode}, {locationDetails?.merchant?.city}, {locationDetails?.merchant?.country}
            </FieldText>
          </Flex>
        </>
      }
    </>
  );
}
export default LocationDetails;