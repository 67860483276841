/** @jsxImportSource theme-ui */

import { Flex, Text } from "theme-ui";
import { LoaderIcon } from "@brinks/common/Icons/Components/loader";
interface LoadingProps {
  customStyle?: number;
}
export default function Loader<T>({customStyle}:LoadingProps) {
  return (
    <Flex
      mt={customStyle || 200}
      data-testid="laoder"
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Flex
        data-testid="laoder-flex"
        sx={{
          width: 120,
          height: 120,
          borderRadius: "16px",
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <LoaderIcon color={'primary'} data-testid="laoder-icon" />
        <Text
          data-testid="laoder-icon-text"
          sx={{
            fontSize: "body",
            fontWeight: "weight_400",
            color: "shade_400",
          }}
        >
          Loading...
        </Text>
      </Flex>
    </Flex>
  );
}
