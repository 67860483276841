import { Text, Flex } from "theme-ui";

export const LoctionHeader = ({ locationDetails }: any) => {
  return (
    <>
      <Flex
        data-testid="LoctionHeader-flex-title"
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Text
          data-testid="LoctionHeader-flex-title-text"
          sx={{
            fontSize: "location_lable",
            color: "black",
            lineHeight: "location_lable",
            fontWeight: "bold",
            fontFamily: "heading",
          }}
        >
          {locationDetails?.name}
        </Text>
      </Flex>
      <Flex data-testid="LoctionHeader-flex-status" py={26}>
        <Text
          data-testid="LoctionHeader-flex-status-text"
          sx={{
            flexBasis: 100,
            borderRadius: 4,
            textAlign: "center",
            px: "8px",
            py: "4px",
            color: `${
              locationDetails?.status === "ACTIVE"
                ? "fern_700"
                : "raspberry_700"
            }`,
            backgroundColor: `${
              locationDetails?.status === "ACTIVE" ? "fern_50" : "raspberry_50"
            }`,
          }}
        >
          {locationDetails?.status}
        </Text>
        <Text
          ml={20}
          pt={10}
          sx={{
            fontSize: "body",
            fontWeight: "weight_400",
            color: "shade_400",
            lineHeight: "register_bag",
          }}
        >
          {locationDetails?.locationCode}
        </Text>
      </Flex>
    </>
  );
};
export default LoctionHeader;
