import React, { useEffect, useState } from "react";
import { Flex, Text, Button, Card } from "theme-ui";
import { ErrorDarkIcon } from "@brinks/common/Icons/Components/error-dark";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";

interface Props{
  msg:string
}
const LoginErrorCard =({msg}:Props) => {
  const [text, setText] = useState("");
  const { t } = useTranslation();
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    switch (language) {
      case "en":
        setText(
          "Something went wrong while logging in. Please try again later or contact us."
        );
        break;
      case "nl":
        setText(
          "Er ging iets mis tijdens het inloggen. Probeer het later nogmaals of neem contact met ons op."
        );
        break;
      default:
        setText(
          "Something went wrong while logging in. Please try again later or contact us."
        );
    }
    TagManager.dataLayer({ 
      dataLayer: {
        event: "Click_event",
        Page_title: "Sign in | Sign in to BLUbeem",
        Action: "Error message: email or password incorrect"
      }
    })
  }, []);

  return (
    <Flex
      data-testid="DeactivatedAccount-wrapper"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        overflow: "hidden",
        margin: "0 auto",
      }}
    >
      <Flex
        bg={"white"}
        sx={{
          alignItems: "center",
          boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.1)",
          borderRadius: "16px",
          width: "326px",
          height: "290px",
          py: 20,
          px: 24,
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <ErrorDarkIcon color={"shade_300"} />
        <Text
          style={{
            fontWeight: "weight_400",
            textAlign: "center",
          }}
        >
          {msg || t("Registration.login_error")}
        </Text>
        <Button
          style={{
            margin: "32px auto 0 auto",
            padding: "14px 132px",
            borderRadius: "32px",
          }}
          onClick={() => window.location.reload()}
        >
          {t("Registration.ok")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default LoginErrorCard;
