import { kFormatter } from "@brinks/common/utils";
import {
  VictoryChart,
  VictoryLine,
  createContainer,
  VictoryAxis,
  VictoryArea,
} from "victory";
import { colors as style_color } from "@brinks/common/colors";
import { useAppSelector } from "@brinks/common/hooks/hooks";
import { useThemeUI } from "theme-ui";
interface DashboardLineChartProps {
  labels: string[];
  datasets: { data: number[]; color: string }[];
  width: any;
}
const uniqueId = () => Math.random().toString(36).substr(2);

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

export default function DashboardLineChart({
  labels,
  datasets,
  width,
}: DashboardLineChartProps) {
  const { selectedTransactionFilterType } = useAppSelector(
    (state) => state.dashboardSlice
  );

  const colors: string[] = [];
  const context = useThemeUI();

  const { colorMode } = context;
  const data = datasets.map((dataset, i) => {
    if (colorMode === "brinks") {
      if (dataset.color === "#0A408F") {
        colors.push("#0A4A8E");
      }
      if (dataset.color === "#11CCA8") {
        colors.push("#2C9942");
      }
      if (dataset.color === "#0F61D8") {
        colors.push("#97CAEB");
      }
    } else {
      colors.push(dataset.color);
    }
    return dataset.data.map((d, index) => {
      return { x: labels[index], y: d };
    });
  });
  const xAxisMax = (data.length && data[0].length) || 1;
  let yAxisMax = 1;
  data.forEach((d) => {
    yAxisMax = Math.ceil(
      Math.max(
        yAxisMax,
        Math.max.apply(
          Math,
          d.map(function (o) {
            return o.y;
          })
        )
      )
    );
  });
  return (
    <VictoryChart padding={{ left: 100, right: 80 }} width={width} domain={{ x: [0, xAxisMax], y: [0, yAxisMax] }}>
      {data.map((dataArray, key) => {
        return (
          <VictoryArea
            key={`area_${uniqueId()}`}
            name={`area_${key}`}
            style={{
              data: {
                fill: colors[key],
                fillOpacity: 0.05,
                strokeWidth: 0,
              },
            }}
            data={dataArray}
            x="x"
            y="y"
          />
        );
      })}
      {data.map((dataArray, key) => {
        return (
          <VictoryLine
            key={`line_${uniqueId()}`}
            name={`line_${key}`}
            style={{
              data: {
                stroke: colors[key],
                strokeWidth: 3,
              },
            }}
            data={dataArray}
            x="x"
            y="y"
          />
        );
      })}
      <VictoryAxis
        tickValues={[0, xAxisMax]}
        tickCount={2}
        style={{
          axis: { stroke: style_color.beaver, strokeWidth: 1 },
          tickLabels: {
            fill: style_color.beaver,
            fontFamily: "Arial",
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={[0, yAxisMax / 2, yAxisMax]}
        tickFormat={(t) =>
          selectedTransactionFilterType.action == "count"
            ? `${Math.ceil(t)}`
            : `€${kFormatter(t)}`
        }
        style={{
          grid: { stroke: style_color.beaver, strokeDasharray: "4,5" },
          axis: { strokeWidth: 0 },
          tickLabels: {
            fill: style_color.beaver,
          },
        }}
      />
    </VictoryChart>
  );
}
