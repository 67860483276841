import { Box, Text, Flex, Divider, Label } from "theme-ui";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

export const PickupDetails = ({
  startDate,
  endDate,
  frequency,
  heading,
  dateLable,
}: any) => {
  const { t } = useTranslation();

  return (
    <Flex
      mt={32}
      bg="white"
      sx={{
        p: 35,
        borderBottom: "1px solid",
        borderColor: "borderColor",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: 8,
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: 1000 }}>
        <Flex
          pt={20}
          sx={{
            fontSize: "medium",
            fontFamily: "heading",
            fontWeight: "bold",
          }}
        >
          {heading}
        </Flex>
        <Divider sx={{ width: 945 }} mt={16} mb={24} color="divider" />

        {/* <Flex
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "170%",
            mb: 60,
          }}
        >
          We will deliver your change order during the next servicing of your
          safe, if that servicing is at least 6 days from today.
        </Flex> */}
        <Flex
          data-testid="LocationDetails-field1"
          sx={{
            flexDirection: "column",
          }}
        >
          <Label
            sx={{
              fontSize: "subText",
              color: "seal_bag",
              fontWeight: "normal",
              lineHeight: "register_bag",
            }}
            data-testid="LocationDetails-name"
          >
            {dateLable}
          </Label>
          <Text
            sx={{
              fontSize: "body",
              fontWeight: "body",
              lineHeight: "label",
              fontStyle: "normal",
            }}
            mt={10}
            data-testid="LocationDetails-name-value"
          >
            {moment(startDate).format("DD-MM-YYYY")}
          </Text>
        </Flex>
        {frequency && (
          <Flex
            mt={39}
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            data-testid="LocationDetails-email"
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="LocationDetails-email-label"
            >
              {t("ManageOrders.frequency")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="LocationDetails-email-value"
            >
              {frequency?.label}
            </Text>
          </Flex>
        )}
        {frequency?.value !== "ONCE" && endDate ? (
          <Flex
            mt={39}
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            data-testid="LocationDetails-phone"
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="LocationDetails-phone-label"
            >
              {t("ManageOrders.end_date")} ({t("ManageOrders.optional")})
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="LocationDetails-phone-value"
            >
              {moment(endDate).format("DD-MM-YYYY")}
            </Text>
          </Flex>
        ) : (
          ""
        )}
      </Box>
    </Flex>
  );
};
export default PickupDetails;
