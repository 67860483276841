/** @jsxImportSource theme-ui */
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Flex, Button } from "theme-ui";
// @ts-ignore
import Modal from "react-modal";
import Sidebar from "../Sidebar";
import { CloseIcon } from "@brinks/common/Icons/Components/close_modal";
import { WarningIcon } from "@brinks/common/Icons/Components/warning";
import "./index.scss";
import { useTranslation } from "react-i18next";
import IdleTimer from "react-idle-timer";
import { useEffect, useRef, useState } from "react";
import { getCurrentUser } from "@brinks/common/utils";
import { getCurrentUser as getCurrentUserFromSecureLocalStorage } from "../../utils/secure-storage-utils";
import { UserType } from "@brinks/common/constants/users";
import { DashboardIcon } from "@brinks/common/Icons/Components/dashboard";
import { UserIcon } from "@brinks/common/Icons/Components/user";
import { FolderIcon } from "@brinks/common/Icons/Components/folder";
import { LocationIcon } from "@brinks/common/Icons/Components/location";
import { SettingsIcon } from "@brinks/common/Icons/Components/settings";
import { LogoutIcon } from "@brinks/common/Icons/Components/logout";
import { OrderChangeIcon } from "@brinks/common/Icons/Components/orderChange";
import { SealBagIcon } from "@brinks/common/Icons/Components/seal-bag";
import TagManager from "react-gtm-module";
import { useOktaAuth } from "@okta/okta-react";
import { REACT_APP_NATIVE_APP_STAGE } from "@brinks/common/utils/Config";
import jwt_decode from "jwt-decode";
import { Tablet } from "@brinks/common/Icons/Components/tablet";
import { useAppSelector } from "@brinks/common/hooks/hooks";
interface MenuItem {
  label: string;
  name: string;
  icon_standard: JSX.Element;
  icon_active: JSX.Element;
}

let countdownInterval: any;
let timeout: any;

const bg = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "420",
    height: "322",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    backgroundColor: "white",
    width: "45rem",
    maxWidth: "27%",
    padding: "30px",
    maxHeight: "50%",
    overflowY: "auto",
    position: "relative",
    borderRadius: "16px",
  },
};

export const sidebarMenu: any = [
  {
    label: "Sidebar.dashboard",
    name: "",
    icon_standard: <DashboardIcon color={"icon_standard"} />,
    icon_active: <DashboardIcon color={"icon_active"} />,
  },
  {
    label: "Sidebar.sealbag",
    name: "register-sealbag",
    icon_standard: <SealBagIcon color={"icon_standard"} />,
    icon_active: <SealBagIcon color={"icon_active"} />,
  },
  {
    label: "Sidebar.orderChange",
    name: "orders",
    icon_standard: <OrderChangeIcon color={"icon_standard"} />,
    icon_active: <OrderChangeIcon color={"icon_active"} />,
  },
  {
    label: "Sidebar.locations",
    name: "locations",
    icon_standard: <LocationIcon color={"icon_standard"} />,
    icon_active: <LocationIcon color={"icon_active"} />,
  },
  {
    label: "Sidebar.deviceManagement",
    name: "device-management",
    icon_standard: <Tablet color={"icon_standard"} />,
    icon_active: <Tablet color={"icon_active"} />,
  },
  {
    label: "Sidebar.users",
    name: "users",
    icon_standard: <UserIcon color={"icon_standard"} />,
    icon_active: <UserIcon color={"icon_active"} />,
  },
  {
    label: "support.support_label",
    name: "support",
    icon_standard: <FolderIcon color={"icon_standard"} />,
    icon_active: <FolderIcon color={"icon_active"} />,
  },

  {
    label: "Sidebar.settings",
    name: "settings",
    icon_standard: <SettingsIcon color={"icon_standard"} />,
    icon_active: <SettingsIcon color={"icon_active"} />,
  },
  {
    label: "Sidebar.logout",
    name: "logout",
    icon_standard: <LogoutIcon color={"icon_standard"} />,
    icon_active: <LogoutIcon color={"icon_active"} />,
  },
];

const TIME_OUT_IN_SECONDS = 1200;
const Layout = ({ children }: any) => {
  const { authState, oktaAuth } = useOktaAuth();
  const token: any = oktaAuth.getAccessToken();
  const { t } = useTranslation();
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);

  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const navigate = useNavigate();
  const idleTimer = useRef(null);
  const isAuthenticated = authState?.isAuthenticated;
  const [menu, setMenu] = useState<MenuItem[]>(sidebarMenu);

  const userRole = getCurrentUser(token);
  const location = useLocation();

  const { merchants } = useAppSelector((state) => state.merchantsDetailSlice);

  const filterMenu = (menu: any[], namesToRemove: string[]) =>
    menu.filter(({ name }: any) => !namesToRemove.includes(name));

  useEffect(() => {
    let showDeviceManagement = true;

    if (merchants && merchants.length > 0) {
      showDeviceManagement = merchants.some(
        (merchant) => merchant.country === "NL"
      );
    }

    const allMenus = [...sidebarMenu];
    const menusToRemove: string[] = [];

    if (!showDeviceManagement) {
      menusToRemove.push("device-management");
    }

    if (userRole === UserType.STANDARD_USER) {
      menusToRemove.push("users");
    }
    const updatedMenu = filterMenu(allMenus, menusToRemove);
    setMenu(updatedMenu);
  }, [merchants, userRole]);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = async (isTimedOut = false) => {
    try {
      setTimeoutModalOpen(false);
      clearSessionInterval();
      clearSessionTimeout();
      navigate(`/logout`);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (authState && !authState.accessToken) {
      navigate("/logout");
      //setWarningModalOpen(true);
    }
  }, [authState]);

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const autoLogoutIfApplicable = () => {
    try {
      const decoded: any = jwt_decode(oktaAuth.getAccessToken() || "");
      const user = getCurrentUserFromSecureLocalStorage();
      if (
        Date.now() / 1000 - (TIME_OUT_IN_SECONDS + 30) > decoded?.exp ||
        user?.profile?.firstName === undefined
      ) {
        handleLogout(true);
      }
    } catch (e) {
      handleLogout(true);
    }
  };

  const onIdle = () => {
    const delay = 1000 * 1;
    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 30;
        TagManager.dataLayer({
          dataLayer: {
            event: "Click_event",
            Page_title:
              "Inactive notification | Inactive notification 1 - Portal",
            Action: "OK",
          },
        });
        autoLogoutIfApplicable();
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          autoLogoutIfApplicable();
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };

  return (
    <>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          variant: "layout.root",
        }}
        data-testid="layout-wrapper"
      >
        <div id="overlay"></div>

        <div
          sx={{
            display: "flex",
            flexWrap: "wrap",
            height: "100%",
          }}
        >
          <aside
            sx={{
              flexGrow: 1,
              width: "25%",
              flexBasis: "sidebar",
            }}
          >
            <Sidebar sideMenus={menu} />
          </aside>
          <main
            sx={{
              flexGrow: 99999,
              width: "75%",
              variant: "layout.main",
            }}
            data-testid="layout-main"
          >
            <Outlet />
          </main>
        </div>
      </div>
      <IdleTimer
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={TIME_OUT_IN_SECONDS * 1000}
      />
      <Modal
        backdropOpacity={0.5}
        closeTimeoutMS={500}
        isOpen={timeoutModalOpen}
        style={bg}
        transparent={true}
        center
      >
        <Box>
          <Flex sx={{ flexDirection: "row-reverse" }}>
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => handleContinue()}
            >
              <CloseIcon
                style={{
                  cursor: "pointer",
                }}
                color={"shade_300"}
              />
            </span>
          </Flex>
          <Flex
            mt={10}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column-reverse",
            }}
          >
            <WarningIcon color={"shade_300"} />
          </Flex>
          <Flex
            pt={10}
            sx={{
              fontFamily: "heading",
              fontWeight: "bold",
              fontSize: "heading_4",
              alignContent: "center",
              lineHeight: "register_bag",
              flexDirection: "column-reverse",
              textAlign: "center",
            }}
          >
            {t("session_heading")}
          </Flex>
          <Flex
            pt={10}
            sx={{
              fontFamily: "body",
              fontWeight: "normal",
              fontSize: "body",
              lineHeight: "label",
              color: "beaver",
            }}
          >
            {t("session_timeout", { timeoutCountdown })}
          </Flex>
          <Flex
            pt={10}
            sx={{
              fontFamily: "body",
              fontWeight: "normal",
              fontSize: "body",
              lineHeight: "label",
              color: "beaver",
            }}
          >
            {t("continue_session")}
          </Flex>
          <Flex
            sx={{
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Button
              mt={20}
              mr={20}
              variant="muted"
              onClick={() => navigate(`/logout`)}
              data-testid="register-sealbag-step1-next-btn"
              sx={{
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                height: 49,
                width: ["100%", "120px"],
                pointerEvents: `auto`,
                borderRadius: "40px",
                cursor: "pointer",
                backgroundColor: "white",
                color: "royalBlue_500",
                border: "1px solid",
                borderColor: "primary",
              }}
            >
              {t("Sidebar.logout")}
            </Button>
            <Button
              mt={20}
              onClick={() => handleContinue()}
              variant="primary"
              data-testid="register-sealbag-step1-next-btn"
              sx={{
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                height: 49,
                width: ["100%", "120px"],
                pointerEvents: `auto`,
                borderRadius: "40px",
                cursor: "pointer",
                float: "right",
              }}
            >
              {t("continue")}
            </Button>
          </Flex>
        </Box>
      </Modal>

      <Modal
        backdropOpacity={0.5}
        closeTimeoutMS={500}
        isOpen={warningModalOpen}
        style={bg}
        transparent={true}
        center
      >
        <Box>
          <Flex
            mt={10}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column-reverse",
            }}
          >
            <WarningIcon color={"shade_300"} />
          </Flex>
          <Flex
            pt={10}
            sx={{
              fontFamily: "heading",
              fontWeight: "bold",
              fontSize: "heading_4",
              alignContent: "center",
              lineHeight: "register_bag",
              flexDirection: "column-reverse",
              textAlign: "center",
            }}
          >
            {t("session_heading")}
          </Flex>
          <Flex
            pt={10}
            sx={{
              fontFamily: "body",
              fontWeight: "normal",
              fontSize: "body",
              lineHeight: "label",
              color: "beaver",
            }}
          >
            {t("session_expired")}
          </Flex>
          <Flex
            sx={{
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Button
              mt={20}
              onClick={() => {
                navigate("/logout");
              }}
              variant="primary"
              data-testid="register-sealbag-step1-next-btn"
              sx={{
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                height: 49,
                pointerEvents: `auto`,
                borderRadius: "40px",
                cursor: "pointer",
                float: "right",
              }}
            >
              {t("continue_to_login")}
            </Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};

export default Layout;
