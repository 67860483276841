import { Box, Text, Flex } from "theme-ui";
import Select, { StylesConfig } from "react-select";
import { CSSProperties, useEffect } from "react";
import { useAppSelector } from "@brinks/common/hooks/hooks";
import { useTranslation } from "react-i18next";
import { InputDisabledStyle } from "../../../helpers/styles";

const customControlStyles: CSSProperties = {
  height: "61px",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

interface MerchantProps {
  merchants: any;
  onUpdateMerchant: (id : string) => void;
}

export const SelectMerchants = ({merchants, onUpdateMerchant }: MerchantProps) => {

  const { t } = useTranslation();

  const { currentMerchant } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );

  const displayedMerchants =  merchants &&  merchants.map((obj: any) => { return { ...obj, label: obj.name, value: obj.id }; });

const test = displayedMerchants && !currentMerchant && displayedMerchants.length === 1;

  const returnMerchant = (displayedMerchants: any) => {
    if (displayedMerchants && !currentMerchant && displayedMerchants.length === 1) {
      onUpdateMerchant(displayedMerchants[0]);
      return displayedMerchants[0];
    } else {
      return currentMerchant;
    }
  }

  useEffect(() => {
    if (test) {
      onUpdateMerchant(displayedMerchants[0]);
    }
  }, [test]);

  return (
    <Flex
      data-testid="advanceFilterLocation"
      bg={"white"}
      sx={{
        flexDirection: "column",
        zIndex: 999,
      }}
    >
      <Text
        sx={{
          fontWeight: "body",
          fontSize: "subText",
        }}
        data-testid="flex-location-text"
      >
        {t("ManageSealBags.merchant_label")}
      </Text>
      <Box
        data-testid="advanceFilter-Location-box"
        className="custome-select"
        sx={{
          mt: 2,
          width: "343px",
        }}
      >
        {displayedMerchants && displayedMerchants.length === 1 ? (
          <div data-testid="merchant-label" style={InputDisabledStyle}>
              {displayedMerchants[0].label}
          </div>
        ) : (
          <Select
            data-testid="advanceFilter-Location-select"
            value={returnMerchant(displayedMerchants)}
            isSearchable={true}
            styles={customStyles}
            onChange={(e: any) => {
              onUpdateMerchant(e);
            }}
            options={displayedMerchants || "Fetching..."}
            placeholder={t("ManageSealBags.select_merchant")}
          />
        )}
      </Box>
    </Flex>
  );
};

export default SelectMerchants;
