// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { Flex, Text } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { StoreLocation } from "@brinks/common/api/Api";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface RowLocationElementProps {
    locations: StoreLocation[];
}

export const RowLocationElement = ({ 
    locations, 
 }: RowLocationElementProps) => {
    if(!locations || (locations && locations.length === 0)) {
        return (
            <Text sx={{ color: "shade_600", fontWeight: "weight_400"}}>
                {`--`}
            </Text>
        )
    };

    return (
        <>
            <Flex
                sx={{
                    justifyItems: "center",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Text sx={{ color: "shade_600", fontWeight: "weight_400"}}>
                    {locations[0]?.name ?? '--'}
                </Text>
                <Text sx={{ fontSize: "small", color: "shade_400" }}>
                    {locations[0]?.locationCode ?? '--'}
                </Text>
            </Flex>
            {locations.length > 1 && 
                <Flex
                    ml={3}
                    sx={{
                        width: 45,
                        height: 32,
                        borderRadius: 4,
                        bg: "borderColor",
                        color: "shade_500",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    >
                    {`+ ${locations.length - 1}`}
                </Flex>
            }
        </>
    );
}
