import {
  Card,
  Box,
  Grid,
  Flex,
  Heading,
  Text,
  Divider,
  Textarea,
} from "theme-ui";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../../Shared/Loader";
import { useTranslation } from "react-i18next";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import PageHeader from "../../../Shared/PageHeader";
import { ReactComponent as Back } from "@brinks/common/Icons/back_arrow.svg";
import TagManager from "react-gtm-module";
import {
  getCases,
  getPosts,
  getComments,
  submitPost,
  submitComment,
  resetComment,
  resetCommentFiles,
  setCommentFiles,
  resetPostFiles,
  setPostFiles,
} from "@brinks/common/reducers/supportCaseSlice";
import { Comment, Post } from "@brinks/common/api/Api";
import {
  convertHTMLEntity,
  getBackgroundColor,
  getBase64,
  getDateTimeFormat,
  getDisplayName,
  getNameInitials,
} from "@brinks/common/utils";
import { ArrowBottomIcon } from "@brinks/common/Icons/Components/arrow-bottom";
import { FileUpload } from "../FileUpload";
import { DisplayFiles } from "../DispalyFiles";
import { SaveFileRequest } from "@brinks/common/api/Api";
import { DisplayAttachedFiles } from "../DispalyaAttachedFiles";

export default function SupportDetail() {
  const [newPostString, setNewPostString] = useState<string>();
  const [newCommentString, setNewCommentString] = useState<string>();
  const [openPostSalesforceId, setOpenPostSalesforceId] = useState<string>();
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Support | Detail view",
      },
    });
  }, []);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    cases,
    loading,
    newPostResponse,
    posts,
    comments,
    newCommentResponse,
    loadingPost,
    loadingComment,
    selectedPostFiles,
    selectedCommentFiles,
  } = useAppSelector((state) => state.supportCaseSlice);
  useEffect(() => {
    dispatch(getCases({}));
    dispatch(getPosts(id));
  }, [id]);

  useEffect(() => {
    if (newPostResponse && newPostResponse.posts.length > 0) {
      setNewPostString("");
      dispatch(getPosts(id));
    }
    
    if (newCommentResponse && newCommentResponse.comments.length > 0) {
      setNewCommentString("");
      dispatch(
        getComments({
          caseSalesforceId: id,
          postSalesforceId: openPostSalesforceId,
        })
      );
    }
  }, [newPostResponse, newCommentResponse]);

  const caseDetail = cases?.find((item) => item.caseSalesforceId == id);

  const toggleComments = (postSalesforceId: string) => {
    dispatch(resetComment());
    if (postSalesforceId === openPostSalesforceId) {
      setOpenPostSalesforceId("");
    } else {
      dispatch(getComments({ caseSalesforceId: id, postSalesforceId }));
      setOpenPostSalesforceId(postSalesforceId);
    }
  };

  if (loading || loadingComment) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  const renderComment = (index: number, comment: Comment): JSX.Element => {
    const dislayName = getDisplayName(
      comment?.userDisplayName,
      comment?.createdByOds || false,
      caseDetail?.locationName || ``
    );
    return (
      <Flex
        key={index}
        sx={{
          flex: "0 0 100%",
          padding: "10px 0 5px",
          borderTop: "1px solid #f2f2f2",
        }}
      >
        <Flex title={dislayName} mr={10} sx={{ flex: "0 0 34px" }}>
          <Flex
            p={2}
            bg={getBackgroundColor(dislayName)}
            color="white"
            sx={{
              width: 34,
              height: 34,
              borderRadius: 99999,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
            }}
          >
            {getNameInitials(dislayName)}
          </Flex>
        </Flex>
        <Flex
          sx={{
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          <Text
            sx={{
              fontSize: 13,
              color: "shade_400",
              fontWeight: "weight_400",
            }}
          >
            {getDateTimeFormat(comment.createdDate)}
          </Text>
          <Text
            sx={{
              color: "black",
              fontSize: 14,
              fontWeight: "weight_400",
            }}
          >
            {convertHTMLEntity(comment.text)}
          </Text>
          <DisplayAttachedFiles files={comment.files} />
        </Flex>
      </Flex>
    );
  };

  const renderPost = (data: Post, index: number): JSX.Element => {
    const dislayName = getDisplayName(
      data?.userDisplayName,
      data?.createdByOds || false,
      caseDetail?.locationName || ``
    );
    return (
      <Flex sx={{ width: "100%", flexDirection: "column" }} mb={20} key={index}>
        <Flex
          sx={{ cursor: "pointer" }}
          onClick={(e) => toggleComments(data.postSalesforceId)}
        >
          <Flex title={dislayName} mr={15} sx={{ flex: "0 0 48px" }}>
            <Flex
              p={2}
              bg={getBackgroundColor(dislayName)}
              color="white"
              sx={{
                width: 48,
                height: 48,
                borderRadius: 99999,
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                fontSize: 12,
              }}
            >
              {getNameInitials(dislayName)}
            </Flex>
          </Flex>
          <Flex
            sx={{
              position: "relative",
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            <Text
              sx={{
                position: "absolute",
                right: 1,
                top: 12,
                transform: `rotate(${
                  openPostSalesforceId === data.postSalesforceId ? 0 : 180
                }deg)`,
              }}
            >
              <ArrowBottomIcon
                color={"shade_400"}
                style={{
                  cursor: "pointer",
                }}
              />
            </Text>
            <Text
              sx={{
                fontSize: 13,
                color: "shade_400",
                fontWeight: "weight_400",
              }}
            >
              {getDateTimeFormat(data.createdDate)}
            </Text>
            <Text
              sx={{
                color: "black",
                fontSize: 14,
                fontWeight: "weight_400",
                paddingRight: 30,
              }}
            >
              {convertHTMLEntity(data.text)}
            </Text>
            <DisplayAttachedFiles files={data.files} />
          </Flex>
        </Flex>
        {openPostSalesforceId === data.postSalesforceId && (
          <Flex
            sx={{
              margin: "5px 0px 5px 65px",
              flexDirection: "column",
            }}
          >
            {comments ? (
              comments.map((comment, index) => renderComment(index, comment))
            ) : (
              <Loader customStyle={20} />
            )}
            <Box
              sx={{
                position: "relative",
                backgroundColor: "#f2f2f2",
                borderRadius: "6px",
              }}
            >
              <Textarea
                onChange={(e) => {
                  setNewCommentString(e.target.value);
                }}
                disabled={loadingComment}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    dispatch(
                      submitComment({
                        caseSalesforceId: id,
                        postSalesforceId: data.postSalesforceId,
                        text: newCommentString,
                        files: await Promise.all(
                          Array.from(selectedCommentFiles).map(
                            async (item): Promise<SaveFileRequest> => {
                              const fileContent = (await getBase64(
                                item
                              )) as string;
                              return {
                                fileContent:
                                  fileContent && fileContent.split(",")[1],
                                fileName: item.name,
                              };
                            }
                          )
                        ),
                      })
                    );
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "Click_event",
                        Page_title: "Support | case detail - add Comment",
                        Action: "Create Comment",
                      },
                    });
                  }
                }}
                value={newCommentString}
                placeholder={t("support.type_here")}
                sx={{
                  width: "100%",
                  height: 48,
                  borderWidth: "0",
                  resize: "none",
                  borderRadius: "6px",
                  fontSize: "body",
                  fontWeight: "weight_400",
                  lineHeight: "body",
                }}
              />
              <FileUpload
                selectedFiles={selectedCommentFiles}
                handleResetFiles={() => dispatch(resetCommentFiles())}
                handleFileChange={(files) => dispatch(setCommentFiles(files))}
                right={0}
                bottom={0}
              />
            </Box>
            <DisplayFiles selectedFiles={selectedCommentFiles} />
          </Flex>
        )}
      </Flex>
    );
  };
  return (
    <>
      <PageHeader>
        <div
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Support | Detail view",
                Action: "Back",
              },
            });
            navigate(`/support/list`);
          }}
        >
          <span style={{ cursor: "pointer" }}>
            <Back />
          </span>
          <Text
            mx={10}
            sx={{
              fontSize: "body",
              fontWeight: "weight_400",
              color: "primary",
              cursor: "pointer",
            }}
          >
            {t("ManageSealBags.back")}
          </Text>
        </div>
        <Heading ml={20}> {t("support.case_details")}</Heading>
      </PageHeader>
      <Wrapper>
        <Grid gap={2} columns={[1, "1fr 4fr"]}>
          <Box>
            <Flex
              pt={20}
              pl={4}
              sx={{
                fontSize: "medium",
                fontFamily: "heading",
                fontWeight: "bold",
              }}
            >
              {t("support.communicate_with")}
            </Flex>
          </Box>
          <Box sx={{ width: 746 }}>
            <Card
              bg="white"
              data-testid="Support-container-card1"
              sx={{
                alignItems: "center",
                margin: "0 auto",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                padding: "39px 32px 19px",
              }}
            >
              {posts && posts.map((data: Post, key) => renderPost(data, key))}
              {posts && posts.length > 0 && (
                <Divider
                  color="divider"
                  sx={{ marginLeft: -32, marginRight: -32 }}
                />
              )}
              <Box sx={{ position: "relative" }}>
                <Textarea
                  onChange={(e) => {
                    setNewPostString(e.target.value);
                  }}
                  disabled={loadingPost}
                  onKeyDown={async (e) => {
                    if (e.key === "Enter") {
                      dispatch(
                        submitPost({
                          id,
                          text: newPostString,
                          files: await Promise.all(
                            Array.from(selectedPostFiles).map(
                              async (item): Promise<SaveFileRequest> => {
                                const fileContent = (await getBase64(
                                  item
                                )) as string;
                                return {
                                  fileContent:
                                    fileContent && fileContent.split(",")[1],
                                  fileName: item.name,
                                };
                              }
                            )
                          ),
                        })
                      );
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "Click_event",
                          Page_title: "Support | case detail - add post",
                          Action: "Create Post",
                        },
                      });
                    }
                  }}
                  value={newPostString}
                  placeholder={t("support.type_here")}
                  sx={{
                    width: "100%",
                    height: 48,
                    borderWidth: "0",
                    resize: "none",
                    borderRadius: "6px",
                    fontSize: "body",
                    fontWeight: "weight_400",
                    lineHeight: "body",
                  }}
                />
                <FileUpload
                  selectedFiles={selectedPostFiles}
                  handleResetFiles={() => dispatch(resetPostFiles())}
                  handleFileChange={(files) => dispatch(setPostFiles(files))}
                  right={0}
                  bottom={0}
                />
              </Box>
              <Flex
                sx={{
                  flexDirection: "column",
                }}
              >
                <DisplayFiles selectedFiles={selectedPostFiles} />
              </Flex>
              <Flex
                mt={32}
                sx={{
                  flexDirection: "row-reverse",
                }}
              ></Flex>
            </Card>
          </Box>
        </Grid>
        {caseDetail && (
          <Grid gap={2} pt={54} columns={[1, "1fr 4fr"]}>
            <Box>
              <Flex
                pt={20}
                pl={4}
                sx={{
                  fontSize: "medium",
                  fontFamily: "heading",
                  fontWeight: "bold",
                }}
              >
                {t("support.case_details")}
              </Flex>
            </Box>
            <Box sx={{ width: 746 }}>
              <Card
                bg="white"
                data-testid="Support-container-card1"
                sx={{
                  alignItems: "center",
                  margin: "0 auto",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "5px",
                  padding: "39px 32px",
                }}
              >
                <Flex
                  data-testid="Support-field1"
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Box
                    data-testid="Location-label-wrapper"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      color: "black",
                      fontFamily: "body",
                    }}
                  >
                    {t("support.location_name")}
                  </Box>
                  <Box
                    data-testid="Location-FieldText"
                    pt={"8px"}
                    sx={{
                      fontSize: "body",
                      fontWeight: "weight_400",
                      lineHeight: "body",
                      color: "black",
                    }}
                  >
                    {caseDetail.locationName}
                  </Box>
                </Flex>
                <Flex
                  data-testid="Support-field2"
                  mt={39}
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Box
                    data-testid="Status-label-wrapper"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      color: "black",
                      fontFamily: "body",
                    }}
                  >
                    {t("support.status")}
                  </Box>
                  <Box
                    data-testid="Status-FieldText"
                    pt={"8px"}
                    sx={{
                      fontSize: "body",
                      fontWeight: "weight_400",
                      lineHeight: "body",
                      color: "black",
                    }}
                  >
                    {caseDetail.status}
                  </Box>
                </Flex>
                <Flex
                  data-testid="Support-field2"
                  mt={39}
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Box
                    data-testid="Createdby-label-wrapper"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      color: "black",
                      fontFamily: "body",
                    }}
                  >
                    {t("support.created_by")}
                  </Box>
                  <Box
                    data-testid="Createdby-FieldText"
                    pt={"8px"}
                    sx={{
                      fontSize: "body",
                      fontWeight: "weight_400",
                      lineHeight: "body",
                      color: "black",
                    }}
                  >
                    {caseDetail?.firstname?.trim() ||
                    caseDetail?.lastname?.trim()
                      ? `${caseDetail.firstname} ${caseDetail.lastname}`
                      : caseDetail.createdByName}
                  </Box>
                </Flex>
                <Flex
                  data-testid="Support-field3"
                  mt={39}
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Box
                    data-testid="Subject-label-wrapper"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      color: "black",
                      fontFamily: "body",
                    }}
                  >
                    {t("support.subject")}
                  </Box>
                  <Box
                    data-testid="Subject-FieldText"
                    pt={"8px"}
                    sx={{
                      fontSize: "body",
                      fontWeight: "weight_400",
                      lineHeight: "body",
                      color: "black",
                    }}
                  >
                    {caseDetail.subject}
                  </Box>
                </Flex>
                <Flex
                  data-testid="Support-field4"
                  mt={39}
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Box
                    data-testid="Description-label-wrapper"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      color: "black",
                      fontFamily: "body",
                    }}
                  >
                    {t("support.description")}
                  </Box>
                  <Box
                    data-testid="Description-FieldText"
                    pt={"8px"}
                    sx={{
                      fontSize: "body",
                      fontWeight: "weight_400",
                      lineHeight: "body",
                      color: "black",
                    }}
                  >
                    <Text
                      sx={{
                        fontSize: "body",
                        fontWeight: "weight_400",
                        lineHeight: "body",
                        color: "black",
                        whiteSpace: "pre-wrap",
                      }}
                      data-testid="Description-name-value"
                      dangerouslySetInnerHTML={{
                        __html: caseDetail.description.replace(/\n/g, "<br />"),
                      }}
                    ></Text>
                  </Box>
                </Flex>
                <Flex
                  data-testid="Support-field2"
                  mt={39}
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Box
                    data-testid="Created-date-label-wrapper"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      color: "black",
                      fontFamily: "body",
                    }}
                  >
                    {t("support.date_created")}
                  </Box>
                  <Box
                    data-testid="Created-date-FieldText"
                    pt={"8px"}
                    sx={{
                      fontSize: "body",
                      fontWeight: "weight_400",
                      lineHeight: "body",
                      color: "black",
                    }}
                  >
                    {getDateTimeFormat(caseDetail.createdDate)}
                  </Box>
                </Flex>
                <Flex
                  data-testid="Support-files"
                  mt={39}
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Box
                    data-testid="files-label-wrapper"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      color: "black",
                      fontFamily: "body",
                    }}
                  >
                    {t("support.files")}
                  </Box>
                  <Box
                    data-testid="files-FieldText"
                    sx={{
                      fontSize: "body",
                      fontWeight: "weight_400",
                      lineHeight: "body",
                      color: "black",
                    }}
                  >
                    <ol
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 0,
                        paddingLeft: 30,
                      }}
                    >
                      {caseDetail.files && caseDetail.files.map(function (item, i) {
                        return (
                          <li
                            key={i}
                            data-testid="Created-date-FieldText"
                            style={{
                              fontSize: "body",
                              fontWeight: "weight_400",
                              lineHeight: "body",
                              cursor: "pointer",
                              textDecorationLine: "underline",
                            }}
                            onClick={(e) =>
                              window.open(item.contentDownloadUrl, "_blank")
                            }
                          >
                            {item.name}
                          </li>
                        );
                      })}
                    </ol>
                  </Box>
                </Flex>
              </Card>
            </Box>
          </Grid>
        )}
      </Wrapper>
    </>
  );
}
