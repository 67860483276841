import { formatCurrency } from "@brinks/common/utils";
import { Flex, Text, Divider } from "theme-ui";
import { useAppSelector } from "@brinks/common/hooks/hooks";

interface Props {
  data: any;
}
export default function DenominationSummaryRow({ data }: Props) {
  const { selectedPickupLocation } = useAppSelector(
    (state) => state.orderDetailsSlice
  );

  const merchantCountry = selectedPickupLocation.merchant?.country;

  return (
    <>
      <Divider color="divider" mt={10} />
      <Flex
        sx={{
          width: 800,
          justifyContent: "space-between",
        }}
      >
        <Text
          sx={{
            color: "shade_500",
            fontSize: "body",
            fontWeight: "weight_400",
            width: 70,
          }}
        >
          {formatCurrency(data.faceValue, data.currency, merchantCountry)}
        </Text>
        <Text
          sx={{
            color: "shade_500",
            fontSize: "body",
            fontWeight: "weight_400",
            width: 100,
          }}
        >
          {data.currencyType === "Coin" ? data.sku : data.increments}
        </Text>
        <Text
          sx={{
            color: "shade_500",
            fontSize: "body",
            fontWeight: "weight_400",
            width: 150,
          }}
        >
          {data.currencyType === "Note"
            ? data.denominationQuantity
            : data.denominationQuantity * data.increments}
        </Text>
      </Flex>
    </>
  );
}
