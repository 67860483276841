/** @jsxImportSource theme-ui */
export const AddUserIcon = ({ color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" sx={{fill:color}} xmlns="http://www.w3.org/2000/svg">
      <path d="M8.1 5.4a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Z" />
      <path d="M5.4 9.9a4.5 4.5 0 0 1 4.5 4.5v.9h-9v-.9a4.5 4.5 0 0 1 4.5-4.5Z" />
      <path d="M13.066 6.174a.55.55 0 0 1 .55.55V8.45h1.726a.55.55 0 0 1 0 1.1h-1.727v1.726a.55.55 0 0 1-1.099 0V9.55h-1.727a.55.55 0 0 1 0-1.1h1.727V6.724a.55.55 0 0 1 .55-.55Z" />
    </svg>
  );
};
