import { Box, Text } from "theme-ui";

interface Props {
  children: React.ReactNode;
}

export default function Heading({ children, ...props }: Props) {
  return (
    <Box mt={39} data-testid="heading-wrapper" {...props}>
      <Text
        data-testid="heading"
        {...props}
        sx={{
          fontSize: "medium",
          fontWeight: "body",
          lineHeight: "22px",
          color: "royalBlue_700",
          fontFamily: "heading",
        }}
      >
        {children}
      </Text>
    </Box>
  );
}
