// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { Box, Flex, Text } from "theme-ui";
import { useTranslation } from "react-i18next";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import Loader from "../../../Shared/Loader";

import { StoreLocation } from "@brinks/common/api/Api";
import { useUserWithLocations } from "../../../Hooks/useUserWithLocations";
import { RowMerchantElementToLocation } from "./RowMerchantElementToLocation";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface UserLocationsProps {
  loading: boolean;
  userLocations: StoreLocation[];
}
export default function UserLocations({
  loading,
  userLocations,
}: UserLocationsProps) {

  const { t } = useTranslation();
  const { loading: loadingUsersOptions } = useUserWithLocations({});

  return (
    <>
      <Flex
        data-testid="location"
        sx={{
          px: 36,
          pt: 26,
          mt: 35,
          width: "100%",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          flexDirection: "column",
          backgroundColor: `white`,
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Flex sx={{ width: "100%" }}>
          <Text color={"shade_800"} sx={{ flex: 2 }}>
            Location Names
          </Text>
          <Text color={"shade_800"} sx={{ flex: 2 }}>
            {t("UserList.merchant")}
          </Text>
          <Text color={"shade_800"} sx={{ flex: 3 }}>
            Address
          </Text>
        </Flex>
      </Flex>
      <Box sx={{ height: "635px", overflowY: "scroll" }}>
        {loading || loadingUsersOptions ? (
          <Box>
            <Loader />
          </Box>
        ) : (
          userLocations &&
          userLocations.length > 0 &&
          userLocations.map((location: any, index: number) => {
            return (
              <Flex
                bg="white"
                key={index}
                data-testid="location"
                sx={{
                  px: 36,
                  pt: 26,
                  pb: 21,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Flex sx={{ width: "100%", flexWrap: "wrap" }}>
                  <Box sx={{ flex: 2, flexWrap: "wrap"}}>
                    {location.name}
                  </Box>
                  <Box sx={{ flex: 2, flexWrap: "wrap", color: "shade_400" }}>
                    <RowMerchantElementToLocation location={location} />
                  </Box>
                  <Text
                    sx={{
                      flex: 3,
                      flexWrap: "wrap",
                      color: "shade_800",
                      fontWeight: "weight_400",
                    }}
                  >
                    {location?.physicalLocation?.street}{" "}
                    {location?.physicalLocation?.houseNumber}{" "}
                    {location?.physicalLocation?.postalCode}{" "}
                    {location?.physicalLocation?.city}{" "}
                  </Text>
                </Flex>
              </Flex>
          )})
        )}
      </Box>
    </>
  );
}
