// ------------------------------------------------------------
// EXTERNAL IMPORTS
// ------------------------------------------------------------
import TagManager from "react-gtm-module";

import { Column } from "react-table";
import { Box, Flex, Heading } from "theme-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

// ------------------------------------------------------------
// INTERNAL IMPORTS
// ------------------------------------------------------------
import Loader from "../../../Shared/Loader";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import GlobalSearch from "../../Users/AddNewUser/GlobalSearch";

import { Table } from "../../ReactTable/ReactTable";
import { StoreLocationWithMerchant } from "@brinks/common/api/Api";
import { LocationMerchantDetails } from "./LocationMerchantDetails";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { LocationListMerchantSelector } from "./LocationListMerchantSelector";
import { getStoreLocations } from "@brinks/common/reducers/storeLocationSlice";
import { useOktaAuth } from "@okta/okta-react";
import { getCurrentUserForApi } from "@brinks/common/utils";
import { getMerchants } from "@brinks/common/reducers/merchantsDetailSlice";

export const LocationList = () => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [merchantFilter, setMerchantFilter] = useState<null | string>(null);

  const { oktaAuth } = useOktaAuth();
  const token: any = oktaAuth.getAccessToken();
  const userRole = getCurrentUserForApi(token);
  const userType = userRole;

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Manage locations | Overview",
      },
    });
  }, []);

  const { loading,storeLocations } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const { loadingMerchants,merchants } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );

  useEffect(() => {
    dispatch(getStoreLocations({ userType: userType}));
    dispatch(getMerchants({ userType: userType}));
  }, []);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const dataOriginID = (e: any) => {
    navigate(`/locations/${e.id}`);
  };

  const columns: Column<any>[] = useMemo( // a type of Column<any>[] is used here to avoid a TS error
    () => [
      {
        id: "name",
        accessor: "name",
        disableFilters: true,
        Header: () => {
          return t("ManageLocations.location_name");
        },
      },
      {
        id: "merchant",
        disableFilters: true,
        Header: () => {
          return t("ManageLocations.merchant");
        },
        accessor: (location: StoreLocationWithMerchant) =>
          <LocationMerchantDetails location={location} />
      },
      {
        disableFilters: true,
        id: "storeLocationCode",
        accessor: "locationCode",
        Header: () => {
          return t("ManageLocations.code");
        },
      },
      {
        id: "address",
        disableFilters: true,
        Header: () => {
          return t("address");
        },
        accessor: (info: StoreLocationWithMerchant) => {
          return  `${info.physicalLocation?.street}, ${info.physicalLocation?.houseNumber}, ${info.physicalLocation?.postalCode}, ${info.physicalLocation?.city}, ${info.physicalLocation?.country}`
        },
      },
    ],
    []
  );

  const locations = useMemo(() => {
    if(!storeLocations) return [];

    const locations = storeLocations;

    return merchantFilter
      ? locations.filter((location) => location.merchantId === merchantFilter)
      : locations;

  }, [merchantFilter, storeLocations]);

  const merchantsArray = useMemo(() => {
    if (!storeLocations) return [];

    const merchantMap = new Map();

    storeLocations
      .filter(storeLocation => storeLocation.merchant)
      .forEach(storeLocation => {
        const merchantId = storeLocation?.merchant?.id;

        if (!merchantMap.has(merchantId)) {
          merchantMap.set(merchantId, storeLocation.merchant);
        }
      });

    const uniqueMerchants = Array.from(merchantMap.values());

    return uniqueMerchants;
  }, [storeLocations]);


  if (loading || loadingMerchants) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <PageHeader>
        <Heading>{t("Header.locations")}</Heading>
      </PageHeader>
      <Wrapper>
        <Flex sx={{ gap: 2, alignItems: "center", justifyContent: "end" }} mb={18}>
          <LocationListMerchantSelector
            locationsMerchants={merchantsArray || []}
            onChange={(value) => setMerchantFilter(value)}
          />
          <GlobalSearch
            placeholder="Search locations"
            searchLocation={(value) => setGlobalFilter(value)}
          />
        </Flex>
        <Flex
          bg="white"
          data-testid="OrderList"
          sx={{
            borderRadius: 8,
            flexDirection: "column",
            borderBottom: "1px solid #E2E5E9",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Table
            data={locations || []}
            globalFilter={globalFilter}
            columns={columns as Column[]}
            dataOriginID={(e: any) => dataOriginID(e)}
          />
        </Flex>
      </Wrapper>
    </>
  );
}
