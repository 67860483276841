/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";
import "./Header.scss";
import Logo from "@brinks/common/Icons/Logo";
import useTheme from '../../Hooks/useTheme';

export default function Header() {
  const themeType = useTheme();
  return (
    <>
      <Flex
        data-testid="flex-header"
        sx={{
          width:'100%',
          my: 39,
          mr: 24,
          pl:35
        }}
      >
        <Logo themeType={themeType} />
      </Flex>
    </>
  );
}
