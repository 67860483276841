import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { formatDenominationValues } from "../utils";
import {
  Api,
  Order,
  Denomination,
  OrderDetails,
  MerchantWithMerchantsAssociated,
  MerchantsAndLocations,
  OrderWithBusinessRules,
  CashDevice,
} from "../api/Api";
import { OrderChangeType } from "../constants/orderChangeType";
import axios from "axios";
import config from "@brinks/common/config";

// Define a type for the slice state
interface orderDetailsSliceState {
  id: string;
  loadingSaveOrder: boolean;
  loadingOrder: boolean;
  loadingUpdateOrder: boolean;
  loadingCashDevice: boolean;
  countryDeviceId: number;
  loadingCalendarDates: boolean;
  grandTotal: number;
  totalOfNotes: number;
  totalOfCoins: number;
  pickupFrequency: any;
  pickupStartDate: Date;
  orders: OrderWithBusinessRules[] | null;
  declaredNoteValues: any;
  declaredCoinValues: any;
  orderChangeResponse: any;
  orderUpdateResponse: any;
  totalWithCurrency: String;
  order: OrderDetails | null;
  locationBusinessRules: any;
  pickupendDate: Date | null;
  selectedPickupLocation: any;
  customerReference: string;
  orderTransportResponse: any;
  successOrderChange: Boolean;
  successOrderPickup: Boolean;
  showAllDenomination: Boolean;
  locationListDisabled: boolean;
  reviewDenominations: [] | null;
  selectedPickupDate: Date | null;
  totalOfNotesWithCurrency: String;
  totalOfCoinsWithCurrency: String;
  orderChangeResponseError: string;
  deliveryDateResponseError: boolean;
  currentDenominationStateType: any;
  denominations: Denomination[] | null;
  firstSelectablePickupStartDate: Date | null;
  selectedOrderMerchant: null | MerchantWithMerchantsAssociated;
  selectedOrderChangeMerchant: null | MerchantsAndLocations;
  selectedPickupOrderMerchant: null | MerchantsAndLocations;
  selectedPickupOrderMerchantAndChild: null | MerchantsAndLocations;
  selectedCurrency: any;
  selectedTypeOfChange: OrderChangeType;
  calendarDates: any;
  calendarError: string;
}

// Define the initial state using that type
const initialState: orderDetailsSliceState = {
  id: "",
  order: null,
  orders: null,
  grandTotal: 0,
  countryDeviceId: 0,
  loadingSaveOrder: false,
  loadingOrder: false,
  loadingUpdateOrder: false,
  loadingCashDevice: false,
  loadingCalendarDates: false,
  calendarError: "",
  totalOfNotes: 0,
  totalOfCoins: 0,
  pickupendDate: null,
  denominations: null,
  totalWithCurrency: "0",
  reviewDenominations: [],
  orderChangeResponse: [],
  orderUpdateResponse: "",
  selectedPickupDate: null,
  declaredNoteValues: null,
  declaredCoinValues: null,
  successOrderChange: false,
  successOrderPickup: false,
  locationBusinessRules: {},
  orderTransportResponse: [],
  showAllDenomination: false,
  selectedPickupLocation: "",
  customerReference: "",
  locationListDisabled: true,
  pickupStartDate: new Date(),
  orderChangeResponseError: "",
  deliveryDateResponseError: false,
  selectedOrderMerchant: null,
  selectedOrderChangeMerchant: null,
  selectedPickupOrderMerchant: null,
  selectedPickupOrderMerchantAndChild: null,
  totalOfNotesWithCurrency: "0",
  totalOfCoinsWithCurrency: "0",
  firstSelectablePickupStartDate: null,
  currentDenominationStateType: "notes",
  pickupFrequency: { value: "ONCE", label: "Once" },
  selectedCurrency: null,
  selectedTypeOfChange: OrderChangeType.DYNAMIC,
  calendarDates: null,
};

export const getDenominations = createAsyncThunk<any, any, {}>(
  "denominations",
  (data, thunkAPI) => {
    const res = new Api().denominations.getDenominations(data.transactionType);
    return res;
  }
);

export const getOrders = createAsyncThunk<any, any, {}>(
  "get-orders",
  (data, thunkAPI) => {
    const res = new Api().orders.getOrders(data);
    return res;
  }
);

export const getOrder = createAsyncThunk<any, any, {}>(
  "get-order",
  (orderId, thunkAPI) => {
    const res = new Api().orders.getOrder(orderId, {});
    return res;
  }
);

export const updateOrder = createAsyncThunk<any, any, {}>(
  "get-order",
  (orderId, thunkAPI) => {
    const res = new Api().orders.updateOrder(orderId, { status: "CANCELLED" });
    return res;
  }
);

export const getLocationBusinessRules = createAsyncThunk<any, any, {}>(
  "business-rules",
  (data: any, thunkAPI) => {
    const res = new Api().locations.getLocationBusinessRules(data.salesforceId);
    return res;
  }
);

export const submitOrderChange = createAsyncThunk<any, any, {}>(
  "submitOrderChange",
  (data: any, thunkAPI) => {
    const res = new Api().orders.saveOrders(data); //
    return res;
  }
);

export const submitOrderTransport = createAsyncThunk<any, any, {}>(
  "submitOrderTransport",
  (data: any, thunkAPI) => {
    const res = new Api().collections.saveCollections(data);
    return res;
  }
);
export interface GetCashDevicesQueryParams {
  storeId: string;
}
export const getCashDevices = createAsyncThunk<
  any,
  GetCashDevicesQueryParams,
  {}
>("cashDevices", (data, thunkAPI) => {
  const res = new Api().devices.getCashDevices(data);
  return res;
});

export const getCalendar = createAsyncThunk<any, any, {}>(
  "getCalendar",
  async (data, thunkAPI) => {
    const today = new Date();
    const sixMonthsLater = new Date(
      today.getFullYear(),
      today.getMonth() + 6,
      today.getDate()
    );

    const params = {
      countryCode: "NL",
      partnerLocationId: data.partnerLocationId,
      startDate: today.toISOString(),
      endDate: sixMonthsLater.toISOString(),
    };

    try {
      const response = await axios.get(`${config.calendar_api.url}/transport`, {
        params: params,
        headers: {
          "x-api-key": config.calendar_api.apiKey,
        },
      });
      return response.data; 
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ error: { message: error.message } });
      }
    }
  }
);

export const saveTransportOrder = createAsyncThunk<any, any, {}>(
  "saveTransportOrder",
  async (body, thunkAPI) => {
    try {
      const response = await axios.post(`${config.calendar_api.url}/transport`, body, {
        headers: {
          "x-api-key": config.calendar_api.apiKey,
        },

      });
      return response.data; 
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ error: { message: error.message } });
      }
    }
  }
);

export const orderDetailsSlice = createSlice({
  name: "orderDetailsSlice",
  initialState,
  reducers: {
    setLoadingSaveOrder: (state, action) => {
      state.loadingSaveOrder = action.payload;
    },
    setDenominations: (state, action) => {
      state.reviewDenominations = action.payload;
    },
    setPickupLocation: (state, action) => {
      state.selectedPickupLocation = action.payload;
    },
    setCustomerReference: (state, action) => {
      state.customerReference = action.payload;
    },
    setCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
    setTypeOfChange: (state, action) => {
      state.selectedTypeOfChange = action.payload;
    },
    setSelectedOrderMerchant: (state, action) => {
      state.selectedOrderMerchant = action.payload;
    },
    setselectedOrderChangeMerchant: (state, action) => {
      state.selectedOrderChangeMerchant = action.payload;
    },
    setSelectedPickupOrderMerchant: (state, action) => {
      state.selectedPickupOrderMerchant = action.payload;
    },
    setSelectedPickupOrderMerchantAndChild: (state, action) => {
      state.selectedPickupOrderMerchantAndChild = action.payload;
    },
    setGrandTotal: (state, action) => {
      state.grandTotal = action.payload;
    },
    setTotalWithCurrency: (state, action) => {
      state.totalWithCurrency = action.payload;
    },
    setNoteTotal: (state, action) => {
      state.totalOfNotes = action.payload;
    },
    setNoteTotalWithCurrency: (state, action) => {
      state.totalOfNotesWithCurrency = action.payload;
    },
    setLocationListDisabled: (state, action) => {
      state.locationListDisabled = action.payload;
    },
    setCoinTotal: (state, action) => {
      state.totalOfCoins = action.payload;
    },
    setCoinTotalWithCurrency: (state, action) => {
      state.totalOfCoinsWithCurrency = action.payload;
    },
    handleDeclaredNoteQuantity: (state, action) => {
      state.declaredNoteValues = action.payload;
    },
    handleDeclaredCoinQuantity: (state, action) => {
      state.declaredCoinValues = action.payload;
    },
    setPickupStartDate: (state, action) => {
      state.pickupStartDate = action.payload;
      if (state.pickupendDate && state.pickupStartDate > state.pickupendDate) {
        state.pickupendDate = action.payload;
      }
    },
    setFirstSelectablePickUpStartDate: (state, action) => {
      state.firstSelectablePickupStartDate = action.payload;

      if (
        state.pickupendDate &&
        state.firstSelectablePickupStartDate &&
        state.firstSelectablePickupStartDate > state.pickupendDate
      ) {
        state.pickupendDate = action.payload;
      }
    },
    setSelectedPickupDate: (state, action) => {
      state.selectedPickupDate = action.payload;
    },
    setPickupendDate: (state, action) => {
      state.pickupendDate = action.payload;
    },
    setPickupFrequency: (state, action) => {
      state.pickupFrequency = action.payload;
    },
    setSuccessOrderChangeMsg: (state, action) => {
      state.successOrderChange = action.payload;
    },
    setSuccessOrderPickupMsg: (state, action) => {
      state.successOrderPickup = action.payload;
    },
    setCurrentDenominationType: (state, action) => {
      state.currentDenominationStateType = action.payload;
    },
    setBusinessRules: (state, action) => {
      state.locationBusinessRules = action.payload;
    },
    setShowAllDenomination: (state, action) => {
      state.showAllDenomination = action.payload;
    },
    resetOrderUpdateResponse: (state, action) => {
      state.orderUpdateResponse = action.payload;
    },
    resetResponseError: (state) => {
      state.orderChangeResponseError = "";
    },
    resetCalendarError: (state) => {
      state.calendarError = "";
    },
    resetDeliveryDateError: (state) => {
      state.deliveryDateResponseError = false;
    },
    resetState: () => initialState,
  },
  extraReducers: {
    [getDenominations.pending.toString()]: (state: orderDetailsSliceState) => {
      (state.loadingOrder = true), (state.denominations = null);
    },
    [getDenominations.fulfilled.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      (state.loadingOrder = false),
        (state.denominations = payload.data.denominations),
        (state.declaredNoteValues = formatDenominationValues(
          payload.data.denominations,
          "Note"
        )),
        (state.declaredCoinValues = formatDenominationValues(
          payload.data.denominations,
          "Coin"
        ));
    },
    [getDenominations.rejected.toString()]: (state: orderDetailsSliceState) => {
      state.loadingOrder = false;
    },
    [getOrders.pending.toString()]: (state: orderDetailsSliceState) => {
      (state.loadingOrder = true), (state.orders = null);
    },
    [getOrders.fulfilled.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      (state.loadingOrder = false),
        (state.orders = payload.data.orders.sort(function (
          a: { orderDateTime: string },
          b: { orderDateTime: string }
        ) {
          return b.orderDateTime > a.orderDateTime ? 1 : -1;
        }));
    },
    [getOrders.rejected.toString()]: (state: orderDetailsSliceState) => {
      state.loadingOrder = false;
    },
    [getOrder.pending.toString()]: (state: orderDetailsSliceState) => {
      (state.loadingOrder = true), (state.order = null);
    },
    [getOrder.fulfilled.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      (state.loadingOrder = false), (state.order = payload.data.orders[0]);
    },
    [getOrder.rejected.toString()]: (state: orderDetailsSliceState) => {
      state.loadingOrder = false;
    },
    [submitOrderChange.pending.toString()]: (state: orderDetailsSliceState) => {
      state.loadingOrder = true;
    },
    [submitOrderChange.fulfilled.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      (state.loadingOrder = false),
        (state.orderChangeResponse = payload.data.orders);
      if (payload.data.errors?.length > 0) {
        const firstError = payload.data.errors[0];
        if (
          firstError.detail.error ===
          "selected delivery date is not available any more."
        ) {
          state.deliveryDateResponseError = true;
        }
      }
    },
    [submitOrderChange.rejected.toString()]: (
      state: orderDetailsSliceState
    ) => {
      state.loadingOrder = false;
      state.orderChangeResponseError = "Something went wrong";
    },
    [updateOrder.pending.toString()]: (state: orderDetailsSliceState) => {
      state.loadingUpdateOrder = true;
    },
    [updateOrder.fulfilled.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      (state.loadingUpdateOrder = false),
        (state.orderUpdateResponse = "Success");
    },
    [updateOrder.rejected.toString()]: (state: orderDetailsSliceState) => {
      state.loadingUpdateOrder = false;
      state.orderUpdateResponse = "Error";
    },
    [submitOrderTransport.pending.toString()]: (
      state: orderDetailsSliceState
    ) => {
      state.loadingOrder = true;
    },
    [submitOrderTransport.fulfilled.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      state.loadingOrder = false;
      state.orderTransportResponse = payload.data.collections;
    },
    [submitOrderTransport.rejected.toString()]: (
      state: orderDetailsSliceState
    ) => {
      state.loadingOrder = false;
    },
    [submitOrderTransport.pending.toString()]: (
      state: orderDetailsSliceState
    ) => {
      state.loadingOrder = true;
    },
    [submitOrderTransport.fulfilled.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      (state.loadingOrder = false),
        (state.orderTransportResponse = payload.data.collections);
    },
    [submitOrderTransport.rejected.toString()]: (
      state: orderDetailsSliceState
    ) => {
      state.loadingOrder = false;
    },
    [getLocationBusinessRules.pending.toString()]: (
      state: orderDetailsSliceState
    ) => {
      state.loadingOrder = true;
    },
    [getLocationBusinessRules.fulfilled.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      if (payload.data["businessRules"]) {
        (state.loadingOrder = false),
          (state.locationBusinessRules =
            payload?.data["businessRules"]?.length > 0 &&
            payload?.data["businessRules"][0]),
          (state.currentDenominationStateType =
            state.locationBusinessRules.allowNotes === false
              ? "coins"
              : "notes");
      }
    },
    [getLocationBusinessRules.rejected.toString()]: (
      state: orderDetailsSliceState
    ) => {
      state.loadingOrder = false;
    },
    [getCashDevices.pending.toString()]: (state: orderDetailsSliceState) => {
      (state.loadingCashDevice = true), (state.countryDeviceId = 0);
    },
    [getCashDevices.fulfilled.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      state.loadingCashDevice = false;
      const devices = payload.data.devices;
      const deviceWithCountryId = devices.find(
        (device: CashDevice) => device.countryDeviceId !== null
      );
      if (deviceWithCountryId) {
        state.countryDeviceId = deviceWithCountryId.countryDeviceId;
      }
    },
    [getCashDevices.rejected.toString()]: (
      state: orderDetailsSliceState
    ) => {
      state.loadingCashDevice = false;
    },
    [getCalendar.pending.toString()]: (state: orderDetailsSliceState) => {
      state.loadingCalendarDates = true;
    },
    [getCalendar.fulfilled.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      state.loadingCalendarDates = false;
      state.calendarDates = payload.dates;
    },
    [getCalendar.rejected.toString()]: (
      state: orderDetailsSliceState,
      { payload }: any
    ) => {
      state.loadingCalendarDates = false;
      if (payload && payload.error && payload.error.message) {
        state.calendarError = payload.error.message;
      } else {
        state.calendarError = "An unknown error occurred.";
      }
    },
  },
});

export const {
  resetState,
  setCoinTotal,
  setNoteTotal,
  setGrandTotal,
  setDenominations,
  setPickupendDate,
  setBusinessRules,
  setPickupLocation,
  setCustomerReference,
  setCurrency,
  setTypeOfChange,
  setPickupStartDate,
  setFirstSelectablePickUpStartDate,
  setSelectedPickupDate,
  setPickupFrequency,
  resetResponseError,
  resetCalendarError,
  resetDeliveryDateError,
  setTotalWithCurrency,
  setShowAllDenomination,
  setCoinTotalWithCurrency,
  setNoteTotalWithCurrency,
  setSuccessOrderChangeMsg,
  setSuccessOrderPickupMsg,
  setSelectedOrderMerchant,
  setselectedOrderChangeMerchant,
  setSelectedPickupOrderMerchant,
  setSelectedPickupOrderMerchantAndChild,
  setCurrentDenominationType,
  handleDeclaredNoteQuantity,
  handleDeclaredCoinQuantity,
  setLocationListDisabled,
  setLoadingSaveOrder,
  resetOrderUpdateResponse,
} = orderDetailsSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default orderDetailsSlice.reducer;
