import { Box, Flex } from "theme-ui";

import Label from "./Label";
import FieldText from "./FieldText";
import Heading from "./Heading";

import { TransactionDetails } from "@brinks/common/api/Api";
import { useTranslation } from "react-i18next";
interface Props {
  transactionDetails: TransactionDetails | null;
}
export default function CustomerDetails({ transactionDetails }: Props) {
   const { t } = useTranslation();
  return (
    <Box data-testid="CustomerDetails">
      <Heading data-testid="CustomerDetails-heading">{t("TransactionDetails.customer_details")}</Heading>
      {transactionDetails?.type === "CARDS" ? (
        <>
          <Flex
            mt={39}
            sx={{
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            data-testid="CustomerDetails-card"
          >
            <Label data-testid="CustomerDetails-card"> {t("TransactionDetails.card_number")}</Label>
            <FieldText data-testid="CustomerDetails-card-value">{transactionDetails?.cardNumber}</FieldText>
          </Flex>

          <Flex
            mt={39}
            sx={{
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            data-testid="Bank"
          >
            <Label data-testid="Bank-info">{t("TransactionDetails.bank")}</Label>
            <FieldText data-testid="Bank-info-value">{transactionDetails?.bankName}</FieldText>
          </Flex>
        </>
      ) : (
        <>
          <Flex
            data-testid="CustomerDetails-field1"
            mt={39}
            sx={{
              flexDirection: "column",
            }}
          >
            <Label data-testid="CustomerDetails-label">{t("name")}</Label>
            <FieldText data-testid="CustomerDetails-label-value">
            {transactionDetails?.customerName}
            </FieldText>
          </Flex>

          <Flex
            mt={39}
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            data-testid="CustomerDetails-customer"
          >
            <Label data-testid="CustomerDetails-customerid">{t("TransactionDetails.customer_Id")}</Label>
            <FieldText data-testid="CustomerDetails-customerid-value">
              {transactionDetails?.customerId}
            </FieldText>
          </Flex>

          <Flex
            mt={39}
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            data-testid="CustomerDetails-email"
          >
            <Label data-testid="CustomerDetails-email-label">{t("email")}</Label>
            <FieldText data-testid="CustomerDetails-email-value">
              {transactionDetails?.email || 'N/A'}
            </FieldText>
          </Flex>

          <Flex
            mt={39}
            sx={{
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            data-testid="CustomerDetails-card"
          >
            <Label data-testid="CustomerDetails-card">{t("TransactionDetails.card_number")}</Label>
            <FieldText data-testid="CustomerDetails-card-value">{transactionDetails?.cardNumber}</FieldText>
          </Flex>

          <Flex
            mt={39}
            sx={{
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            data-testid="Bank"
          >
            <Label data-testid="Bank-info">{t("TransactionDetails.bank")}Bank</Label>
            <FieldText data-testid="Bank-info-value">{transactionDetails?.bankName}</FieldText>
          </Flex>
        </>
      )}
    </Box>
  );
}
