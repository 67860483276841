import { colors } from "../colors";

export const transactionTypeData = [
  {
    label: "Dashboard.transaction_all",
    color: "",
    value: "All",
  },
  {
    label: "Dashboard.transaction_cash",
    color: "blue",
    colorConst: colors.blue,
    value: "Cash",
  },
  {
    label: "Dashboard.transaction_card",
    color: "brightNavyBlue",
    colorConst: colors.brightNavyBlue,
    value: "Cards",
  },
  {
    label: "Dashboard.transaction_clicks",
    color: "caribbeanGreen",
    colorConst: colors.caribbeanGreen,
    value: "Clicks",
  },
];

export const transactionFilterData = [
  {
    label: "Dashboard.transaction_amount",
    type: "transaction_amount",
    action: "sum",
  },
  {
    label: "Dashboard.number_of_transactions",
    type: "number_transaction",
    action: "count",
  },
];

export const filterAmountTypeData = [
  {
    label: "Dashboard.transaction_all",
    type: "all",
  },
  {
    label: "Dashboard.credit",
    type: "credit",
  },
  {
    label: "Dashboard.debit",
    type: "debit",
  },
];

export const filterTransactionTypeData = [
  {
    label: "Dashboard.transaction_all",
    type: "all",
  },
  {
    label: "Dashboard.transaction_cash",
    type: "cash",
  },
  {
    label: "Dashboard.transaction_card",
    type: "cards",
  },
  {
    label: "Dashboard.transaction_clicks",
    type: "clicks",
  },
];

export const filterPaymentMethods = [
  {
    label: "Dashboard.transaction_all",
    type: "all",
    transactionType: ["all", "cards", "clicks"],
    disabled: false,
  },
  {
    label: "Maestro",
    type: "maestro",
    transactionType: ["all", "cards"],
    disabled: false,
  },
  {
    label: "V PAY",
    type: "vpay",
    transactionType: ["all", "cards"],
    disabled: false,
  },
  {
    label: "Mastercard",
    type: "mastercard",
    transactionType: ["all", "cards", "clicks"],
    disabled: false,
  },
  {
    label: "Visa",
    type: "visa",
    transactionType: ["all", "cards", "clicks"],
    disabled: false,
  },

  {
    label: "iDeal",
    type: "ideal",
    transactionType: ["all", "clicks"],
    disabled: false,
  },

  {
    label: "Bancontact",
    type: "bancontact",
    transactionType: ["all", "cards", "clicks"],
    disabled: false,
  },

  {
    label: "Paypal",
    type: "paypal",
    transactionType: ["all", "clicks"],
    disabled: false,
  },
];

export const filterLocationData = [
  {
    label: "All locations",
    type: "all",
  },
];
