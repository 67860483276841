// --------------------------------------------------------------------------------
//  EXTERNAL IMPORTS
// --------------------------------------------------------------------------------
import { Box, Flex } from "theme-ui";
import { useTranslation } from "react-i18next";

// --------------------------------------------------------------------------------
//  INTERNAL IMPORTS
// --------------------------------------------------------------------------------
import Label from "./Label";
import Heading from "./Heading";
import FieldText from "./FieldText";

import { TransactionDetails } from "@brinks/common/api/Api";
interface Props {
  transactionDetails: TransactionDetails | null;
}

export default function LocationDetails({ transactionDetails }: Props) {
  const { t } = useTranslation();

  return (
    <Box data-testid="LocationDetails">
      <Heading data-testid="LocationDetails-Heading">
        {t("location_Details")}
      </Heading>
      <Flex
        mt={39}
        sx={{ flexDirection: "column" }}
        data-testid="LocationDetails-Location"
      >
        <Label data-testid="LocationDetails-Location-label">
          {t("TransactionDetails.location")}
        </Label>
        <FieldText data-testid="LocationDetails-Location-label-value">
          {transactionDetails?.locationName}
        </FieldText>
      </Flex>
      {
        transactionDetails && transactionDetails.merchant &&
        <Flex
          mt={39}
          data-testid="LocationDetails-Location-Address"
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Label data-testid="LocationDetails-Location-Address-value">
            {t("address")}
          </Label>
          <FieldText>{transactionDetails.address}</FieldText>
        </Flex>
      }
      <Flex
        mt={39}
        data-testid="LocationDetails-Location-code"
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Label data-testid="LocationDetails-Location-code">
          {t("TransactionDetails.code")}
        </Label>
        <FieldText data-testid="LocationDetails-Location-code-value">
          {transactionDetails?.locationCode}
        </FieldText>
      </Flex>

      {transactionDetails?.terminalId ? (
        <Flex
          mt={39}
          data-testid="LocationDetails-Location-Terminal-Id"
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Label data-testid="LocationDetails-Location-Terminal-Id-label">
            {t("TransactionDetails.terminal_id")}
          </Label>
          <FieldText data-testid="LocationDetails-Location-Terminal-Id-value">
            {transactionDetails?.terminalId || "N/A"}
          </FieldText>
        </Flex>
      ) : (
        ""
      )}

      {transactionDetails?.bankAccount ? (
        <Flex
          mt={39}
          data-testid="LocationDetails-Location-Bank-Account-Number"
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Label data-testid="LocationDetails-Location-Bank-Account-Number-label">
            {t("TransactionDetails.bank_acc_number")}
          </Label>
          <FieldText data-testid="LocationDetails-Location-Bank-Account-Number-label-value">
            {transactionDetails?.bankAccount || "N/A"}
          </FieldText>
        </Flex>
      ) : (
        ""
      )}

      {transactionDetails?.bankName ? (
        <Flex
          mt={39}
          data-testid="LocationDetails-bank"
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Label data-testid="LocationDetails-bank-label">
            {t("TransactionDetails.bank")}
          </Label>
          <FieldText data-testid="LocationDetails-bank-value">
            {transactionDetails?.bankName || "N/A"}
          </FieldText>
        </Flex>
      ) : (
        ""
      )}
    </Box>
  );
}
