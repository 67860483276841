/** @jsxImportSource theme-ui */
import { Flex, Box } from "theme-ui";

export const DeleteIcon = ({ color, width, height }) => {
  return (
    <Flex sx={{ cursor: "pointer", justifyContent: "flex-end",
    "&:hover": {
      "> div": {
        transition: "none",
      },
    }, }}>
      <Box
        as="svg"
        width={width || "12"}
        height={height || "14"}
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={{ "& path": { fill: color || "#476185" } }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.20003 0.599854C4.89701 0.599854 4.62 0.771055 4.48448 1.04208L3.9056 2.19985H1.20002C0.758197 2.19985 0.400024 2.55803 0.400024 2.99985C0.400024 3.44168 0.758197 3.79985 1.20002 3.79985L1.20002 11.7999C1.20002 12.6835 1.91637 13.3999 2.80002 13.3999H9.20003C10.0837 13.3999 10.8 12.6835 10.8 11.7999V3.79985C11.2419 3.79985 11.6 3.44168 11.6 2.99985C11.6 2.55803 11.2419 2.19985 10.8 2.19985H8.09445L7.51557 1.04208C7.38005 0.771055 7.10304 0.599854 6.80003 0.599854H5.20003ZM3.60002 5.39985C3.60002 4.95803 3.9582 4.59985 4.40002 4.59985C4.84185 4.59985 5.20002 4.95803 5.20002 5.39985V10.1999C5.20002 10.6417 4.84185 10.9999 4.40002 10.9999C3.9582 10.9999 3.60002 10.6417 3.60002 10.1999V5.39985ZM7.60003 4.59985C7.1582 4.59985 6.80002 4.95803 6.80002 5.39985V10.1999C6.80002 10.6417 7.1582 10.9999 7.60003 10.9999C8.04185 10.9999 8.40003 10.6417 8.40003 10.1999V5.39985C8.40003 4.95803 8.04185 4.59985 7.60003 4.59985Z"
        />
      </Box>
    </Flex>
  );
};
