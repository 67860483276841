// ------------------------------------------------------------
// EXTERNAL IMPORTS
// ------------------------------------------------------------
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ------------------------------------------------------------
// INTERNAL IMPORTS
// ------------------------------------------------------------
import { formatDenominationValues } from "../utils";
import {
  Api,
  Denomination,
  OrderWithMerchant,
  OrderDetailsWithMerchant,
  OrderDetailsWithMerchantAndBusRules,
} from "../api/Api";

// ------------------------------------------------------------
// TYPES
// ------------------------------------------------------------
interface OrderDetailsWithMerchantSliceState {
  id: string;
  loading: boolean;
  grandTotal: number;
  totalOfNotes: number;
  totalOfCoins: number;
  pickupFrequency: any;
  pickupStartDate: Date;
  declaredNoteValues: any;
  declaredCoinValues: any;
  orderChangeResponse: any;
  totalWithCurrency: String;
  locationBusinessRules: any;
  pickupendDate: Date | null;
  selectedPickupLocation: any;
  orderTransportResponse: any;
  successOrderChange: Boolean;
  orders: OrderWithMerchant[];
  successOrderPickup: Boolean;
  showAllDenomination: Boolean;
  reviewDenominations: [] | null;
  selectedPickupDate: Date | null,
  totalOfNotesWithCurrency: String;
  totalOfCoinsWithCurrency: String;
  orderChangeResponseError: string;
  currentDenominationStateType: any;
  denominations: Denomination[] | null;
  order: OrderDetailsWithMerchantAndBusRules | null;
  firstSelectablePickupStartDate: Date | null,
}

const initialState: OrderDetailsWithMerchantSliceState = {
  id: "",
  orders: [],
  order: null,
  grandTotal: 0,
  loading: false,
  totalOfNotes: 0,
  totalOfCoins: 0,
  pickupendDate: null,
  denominations: null,
  totalWithCurrency: "0",
  reviewDenominations: [],
  orderChangeResponse: [],
  declaredNoteValues: null,
  selectedPickupDate: null,
  declaredCoinValues: null,
  successOrderChange: false,
  successOrderPickup: false,
  locationBusinessRules: {},
  orderTransportResponse: [],
  showAllDenomination: false,
  selectedPickupLocation: "",
  pickupStartDate: new Date(),
  orderChangeResponseError: "",
  totalOfNotesWithCurrency: "0",
  totalOfCoinsWithCurrency: "0",
  firstSelectablePickupStartDate: null,
  currentDenominationStateType: "notes",
  pickupFrequency: { value: "ONCE", label: "Once" },
};

export const getDenominations = createAsyncThunk<
  any,
  any,
  {}
>("denominations", (data, thunkAPI) => {
  const res = new Api().denominations.getDenominations(data.transactionType);
  return res;
});

export const getOrdersWithMerchant = createAsyncThunk<
  any,
  any,
  {}
>("get-orders-with-merchant", (data, thunkAPI) => {
  const res = new Api().ordersWithMerchant.getOrdersWithMerchant();
  return res;
});

export const getOrderWithMerchant = createAsyncThunk<
  any,
  any,
  {}
>("get-order-with-merchant", (orderId, thunkAPI) => {
  const res = new Api().ordersWithMerchant.getOrderWithMerchant(orderId, {});
  return res;
});

export const getLocationBusinessRules = createAsyncThunk<
  any,
  any,
  {}
>("business-rules", (data: any, thunkAPI) => {
  const res = new Api().locations.getLocationBusinessRules(data.salesforceId);
  return res;
});

export const submitOrderChange = createAsyncThunk<
  any,
  any,
  {}
>("submitOrderChange", (data: any, thunkAPI) => {
  const res = new Api().orders.saveOrders(data);
  return res;
});

export const submitOrderTransport = createAsyncThunk<
  any,
  any,
  {}
>("submitOrderTransport", (data: any, thunkAPI) => {
  const res = new Api().collections.saveCollections(data);
  return res;
});

export const orderDetailsWithMerchantSlice = createSlice({
  name: "orderDetailsWithMerchantSlice",
  initialState,
  reducers: {
    setDenominations: (state, action) => {
      state.reviewDenominations = action.payload;
    },
    setPickupLocation: (state, action) => {
      state.selectedPickupLocation = action.payload;
    },
    setGrandTotal: (state, action) => {
      state.grandTotal = action.payload;
    },
    setTotalWithCurrency: (state, action) => {
      state.totalWithCurrency = action.payload;
    },
    setNoteTotal: (state, action) => {
      state.totalOfNotes = action.payload;
    },
    setNoteTotalWithCurrency: (state, action) => {
      state.totalOfNotesWithCurrency = action.payload;
    },
    setCoinTotal: (state, action) => { 
      state.totalOfCoins = action.payload;
    },
    setCoinTotalWithCurrency: (state, action) => {
      state.totalOfCoinsWithCurrency = action.payload;
    },
    handleDeclaredNoteQuantity: (state, action) => {
      state.declaredNoteValues = action.payload;
    },
    handleDeclaredCoinQuantity: (state, action) => {
      state.declaredCoinValues = action.payload;
    },
    setPickupStartDate: (state, action) => {
      state.pickupStartDate = action.payload;
      if(state.pickupendDate && state.pickupStartDate > state.pickupendDate) {
        state.pickupendDate = action.payload;
      }
    },
    setFirstSelectablePickUpStartDate: (state, action) => {
      state.firstSelectablePickupStartDate = action.payload;

      if(state.pickupendDate && state.firstSelectablePickupStartDate && 
        state.firstSelectablePickupStartDate > state.pickupendDate) {
        state.pickupendDate = action.payload;
      }
    },
    setSelectedPickupDate: (state, action) => {
      state.selectedPickupDate = action.payload;
    },
    setPickupendDate: (state, action) => {
      state.pickupendDate = action.payload;
    },
    setPickupFrequency: (state, action) => {
      state.pickupFrequency = action.payload;
    },
    setSuccessOrderChangeMsg: (state, action) => {
      state.successOrderChange = action.payload;
    },
    setSuccessOrderPickupMsg: (state, action) => {
      state.successOrderPickup = action.payload;
    },
    setCurrentDenominationType: (state, action) => {
      state.currentDenominationStateType = action.payload;
    },
    setBusinessRules: (state, action) => {
      state.locationBusinessRules = action.payload;
    },
    setShowAllDenomination: (state, action)=>{
      state.showAllDenomination = action.payload;
    },
    resetResponseError: (state) => {
      state.orderChangeResponseError = "";
    },
    resetState: () => initialState,
  },
  extraReducers: {
    [getDenominations.pending.toString()]: (state: OrderDetailsWithMerchantSliceState) => {
      (state.loading = true), (state.denominations = null);
    },
    [getDenominations.fulfilled.toString()]: (
      state: OrderDetailsWithMerchantSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
        (state.denominations = payload.data.denominations),
        (state.declaredNoteValues = formatDenominationValues(
          payload.data.denominations,
          "Note"
        )),
        (state.declaredCoinValues = formatDenominationValues(
          payload.data.denominations,
          "Coin"
        ));
    },
    [getDenominations.rejected.toString()]: (state: OrderDetailsWithMerchantSliceState) => {
      state.loading = false;
    },
    [getOrdersWithMerchant.pending.toString()]: (state: OrderDetailsWithMerchantSliceState) => {
      (state.loading = true), 
      (state.orders = []);
    },
    [getOrdersWithMerchant.fulfilled.toString()]: (
      state: OrderDetailsWithMerchantSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
      (state.orders = payload.data.orders.sort(function (
        a: { orderDateTime: string },
        b: { orderDateTime: string }
      ) {
        return b.orderDateTime > a.orderDateTime ? 1 : -1;
      }))
    },
    [getOrdersWithMerchant.rejected.toString()]: (state: OrderDetailsWithMerchantSliceState) => {
      state.loading = false;
    },
    [getOrderWithMerchant.pending.toString()]: (state: OrderDetailsWithMerchantSliceState) => {
      (state.loading = true), 
      (state.order = null);
    },
    [getOrderWithMerchant.fulfilled.toString()]: (
      state: OrderDetailsWithMerchantSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
      (state.order = payload.data.orders[0])
    },
    [getOrderWithMerchant.rejected.toString()]: (state: OrderDetailsWithMerchantSliceState) => {
      state.loading = false;
    },
    [submitOrderChange.pending.toString()]: (state: OrderDetailsWithMerchantSliceState) => {
      state.loading = true;
    },
    [submitOrderChange.fulfilled.toString()]: (
      state: OrderDetailsWithMerchantSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
        (state.orderChangeResponse = payload.data.orders);
    },
    [submitOrderChange.rejected.toString()]: (
      state: OrderDetailsWithMerchantSliceState
    ) => {
      state.loading = false;
      state.orderChangeResponseError = "Something went wrong";
    },
    [submitOrderTransport.pending.toString()]: (
      state: OrderDetailsWithMerchantSliceState
    ) => {
      state.loading = true;
    },
    [submitOrderTransport.fulfilled.toString()]: (
      state: OrderDetailsWithMerchantSliceState,
      { payload }: any
    ) => {
      state.loading = false;
      state.orderTransportResponse = payload.data.collections;
    },
    [submitOrderTransport.rejected.toString()]: (
      state: OrderDetailsWithMerchantSliceState
    ) => {
      state.loading = false;
    },
    [submitOrderTransport.pending.toString()]: (
      state: OrderDetailsWithMerchantSliceState
    ) => {
      state.loading = true;
    },
    [submitOrderTransport.fulfilled.toString()]: (
      state: OrderDetailsWithMerchantSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
        (state.orderTransportResponse = payload.data.collections);
    },
    [submitOrderTransport.rejected.toString()]: (
      state: OrderDetailsWithMerchantSliceState
    ) => {
      state.loading = false;
    },
    [getLocationBusinessRules.pending.toString()]: (
      state: OrderDetailsWithMerchantSliceState
    ) => {
      state.loading = true;
    },
    [getLocationBusinessRules.fulfilled.toString()]: (
      state: OrderDetailsWithMerchantSliceState,
      { payload }: any
    ) => {
      if (payload.data["businessRules"]) { 
        (state.loading = false),
          (state.locationBusinessRules =  payload?.data["businessRules"]?.length > 0 && payload?.data["businessRules"][0]) ,
          (state.currentDenominationStateType =
            state.locationBusinessRules.allowNotes === false
              ? "coins"
              : "notes");
      }
    },
    [getLocationBusinessRules.rejected.toString()]: (
      state: OrderDetailsWithMerchantSliceState
    ) => {
      state.loading = false;
    },
  },
});

export const {
  resetState,
  setCoinTotal,
  setNoteTotal,
  setGrandTotal,
  setDenominations,
  setPickupendDate,
  setBusinessRules,
  setPickupLocation,
  setPickupStartDate,
  setPickupFrequency,
  resetResponseError,
  setTotalWithCurrency,
  setSelectedPickupDate,
  setShowAllDenomination,
  setCoinTotalWithCurrency,
  setNoteTotalWithCurrency,
  setSuccessOrderChangeMsg,
  setSuccessOrderPickupMsg,
  setCurrentDenominationType,
  handleDeclaredNoteQuantity,
  handleDeclaredCoinQuantity,
  setFirstSelectablePickUpStartDate,
} = orderDetailsWithMerchantSlice.actions;

export default orderDetailsWithMerchantSlice.reducer;
