import { OrderItemDetails } from "@brinks/common/api/Api";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { formatCurrency } from "@brinks/common/utils";
import { useEffect } from "react";
import { Flex, Text, Divider } from "theme-ui";
import { getSkus } from "@brinks/common/reducers/skusDetailSlice";

interface Props {
  orderItemDetail: OrderItemDetails;
  isNotesQty: boolean;
  merchantCountry?: string | null;
}
export default function OrderDetailDenomination({
  orderItemDetail,
  isNotesQty,
  merchantCountry,
}: Props) {
  const currentyType = orderItemDetail.denomination?.type;

  const { skus } = useAppSelector((state) => state.skusDetailSlice);

  const dispatch = useAppDispatch();

  const onGetSkus = () => {
    dispatch(getSkus(undefined));
  };

  useEffect(() => {
    onGetSkus();
  }, []);

  return (
    <>
      <Divider sx={{ width: 945 }} mt={16} mb={24} color="divider" />
      <Flex
        sx={{
          width: currentyType === "Note" && isNotesQty ? 450 : 800,
          justifyContent: "space-between",
        }}
      >
        <Text
          sx={{
            color: "shade_500",
            fontSize: "body",
            fontWeight: "weight_400",
            width: 70,
          }}
        >
          {formatCurrency(
            orderItemDetail.denomination?.faceValue,
            orderItemDetail?.denomination?.currency,
            merchantCountry
          )}
        </Text>
        <Text
          sx={{
            color: "shade_500",
            fontSize: "body",
            fontWeight: "weight_400",
            width: 100,
          }}
        >
          {currentyType === "Note"
            ? orderItemDetail.increments
            : skus?.filter((item) => item.id === orderItemDetail.skuId)[0]
                .quantity}
        </Text>
        <Text
          sx={{
            color: "shade_500",
            fontSize: "body",
            fontWeight: "weight_400",
            width: 150,
          }}
        >
          {orderItemDetail.quantity}
        </Text>
      </Flex>
    </>
  );
}
