import { useColorMode } from "theme-ui";

export default function useTheme() {

  const [colorMode, setColorMode] = useColorMode();

  // TODO: use this when deployed to different URLs
  //const theme = process.env.REACT_APP_THEME;

  return colorMode;
}