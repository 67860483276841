// @ts-nocheck

import React, { useEffect, useMemo, useState } from "react";
import { Column, useTable, useFilters, useGlobalFilter } from "react-table";
import { DefaultFilterForColumn } from "./Filter";
import { DeleteIcon } from "@brinks/common/Icons/Components/delete";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { getStoreLocations } from "@brinks/common/reducers/storeLocationSlice";
import { useOktaAuth } from "@okta/okta-react";
import { getCurrentUserForApi } from "@brinks/common/utils";

interface TableProps {
  data: any[];
  columns: Column[];
  globalFilter: string;
  dataOriginID: (obj: any, type?: string, showDeleteIcon?: boolean) => void;
}

export function Table<T>({
  data,
  columns,
  globalFilter,
  dataOriginID,
}: TableProps): React.ReactElement {
  const defaultColumn = useMemo<Partial<Column>>(
    () => ({
      maxWidth: 200,
      Filter: DefaultFilterForColumn,
    }),
    []
  );

  const dispatch = useAppDispatch();

  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [isIconHovered, setIsIconHovered] = useState(false);

  const { oktaAuth } = useOktaAuth();
  const token: any = oktaAuth.getAccessToken();
  const userRole = getCurrentUserForApi(token);
  const userType = userRole;

  const { storeLocations } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  useEffect(() => {
    if (!storeLocations) {
      dispatch(getStoreLocations({ userType: userType }));
    }
  }, []);

  const checkPhysicalLocationNL = (order: any) => {
    if (!storeLocations) return false;
    const location = storeLocations.find((item) => item.id === order.storeId);
    return location?.physicalLocation?.country === "NL";
  };

  const handleShowDeleteIcon = (order: any) => {
    const cancelCutOffTime = order.businessRules?.cancelCutOffTime;

    if (!cancelCutOffTime) {
      return false;
    }

    const orderDate = new Date(order.createdAt).toISOString();
    const orderHour = orderDate.slice(11, 19);

    const currentDate = new Date().toISOString();
    const currentHour = currentDate.slice(11, 19);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const sameDay = orderDate.slice(0, 10) === currentDate.slice(0, 10);
    const isYesterday =
      orderDate.slice(0, 10) === yesterday.toISOString().split("T")[0];

    return (
      (sameDay &&
        ((orderHour < cancelCutOffTime && currentHour < cancelCutOffTime) ||
          (orderHour > cancelCutOffTime && currentHour > cancelCutOffTime))) ||
      (isYesterday &&
        orderHour > cancelCutOffTime &&
        currentHour < cancelCutOffTime)
    );
  };

  const shouldShowDeleteIcon = (order: any) => {
    const isPhysicalLocationNL = checkPhysicalLocationNL(order);
    return (
      (!isPhysicalLocationNL && order.businessRules?.cancelLeadDays) ||
      (isPhysicalLocationNL && handleShowDeleteIcon(order))
    );
  };

  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    setGlobalFilter,
    getTableBodyProps,
  } = useTable(
    {
      data,
      columns,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter
  );

  useEffect(() => {
    setGlobalFilter(globalFilter);
  }, [globalFilter]);

  return (
    <table
      className="react-table"
      style={{ tableLayout: "fixed" }}
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                {!column.disableFilters ? (
                  <div
                    className="filter-col"
                    style={{ width: column.width + "px" }}
                  >
                    {column.render("Filter")}
                  </div>
                ) : (
                  <div></div>
                )}
                {column.render("Header")}
                {/* Render the columns filter UI */}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              onClick={(e) =>
                dataOriginID(
                  row?.original,
                  "click",
                  shouldShowDeleteIcon(row.original)
                )
              }
              {...row.getRowProps()}
              onMouseEnter={() => setHoveredRow(row.id)}
              onMouseLeave={() => setHoveredRow(null)}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}

              {
                <td
                  onMouseEnter={() => setIsIconHovered(true)}
                  onMouseLeave={() => setIsIconHovered(false)}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      row?.original &&
                      row?.original.status === "NEW" &&
                      row?.original.businessRules?.cancelAllow === true &&
                      shouldShowDeleteIcon(row.original)
                    ) {
                      dataOriginID(
                        row?.original,
                        "delete",
                        shouldShowDeleteIcon(row.original)
                      );
                    }
                  }}
                  style={{
                    borderBottom: "1px solid #E2E5E9",
                    borderTop: "1px solid #E2E5E9",
                  }}
                >
                  <div
                    style={{
                      width: "34px",
                      height: "34px",
                    }}
                  >
                    <div
                      style={{
                        width: "60%",
                        height: "60%",
                      }}
                    >
                      {row?.original?.status === "NEW" &&
                      hoveredRow === row.id &&
                      row.original.businessRules?.cancelAllow === true &&
                      shouldShowDeleteIcon(row.original) ? (
                        <DeleteIcon
                          color={isIconHovered ? "red" : "order_primary"}
                          width={34}
                          height={34}
                        />
                      ) : null}
                    </div>
                  </div>
                </td>
              }
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
