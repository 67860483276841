import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  Api,
  BusinessRuleLocationServicesMoneyOrder,
  BusinessRuleLocationServicesMoneyOrdersResponse,
} from "../api/Api";

// Define the initial state using that type
const initialState: businessRulesMoneyOrdersDetailSliceState = {
  loadingBusinessRulesMoneyOrders: false,
  businessRulesMoneyOrders: null,
  currentBusinessRulesMoneyOrders: null,
};

interface businessRulesMoneyOrdersDetailSliceState {
  loadingBusinessRulesMoneyOrders: boolean;
  businessRulesMoneyOrders: BusinessRuleLocationServicesMoneyOrder[] | null;
  currentBusinessRulesMoneyOrders: BusinessRuleLocationServicesMoneyOrder | null;
}

export const getRulesMoneyOrders = createAsyncThunk<any, any, {}>(
  "BusinessRuleLocationServicesMoneyOrdersResponse",
  (data, thunkAPI) => {
    const res = new Api().businessRules.getRulesMoneyOrders(data);
    return res;
  }
);

export const businessRulesMoneyOrdersSlice = createSlice({
  name: "businessRulesMoneyOrdersSlice",
  initialState,
  reducers: {
    setBusinessRulesMoneyOrders: (state, action) => {
      state.businessRulesMoneyOrders = action.payload;
    },
    setCurrentBusinessRulesMoneyOrders: (state, action) => {
      state.currentBusinessRulesMoneyOrders = action.payload;
    },
    setLoadingBusinessRulesMoneyOrders: (state, action) => {
      state.loadingBusinessRulesMoneyOrders = action.payload;
    },
    resetWebFilter: () => initialState,
  },
  extraReducers: {
    [getRulesMoneyOrders.pending.toString()]: (
      state: businessRulesMoneyOrdersDetailSliceState
    ) => {
      (state.loadingBusinessRulesMoneyOrders = true),
        (state.businessRulesMoneyOrders = null);
    },
    [getRulesMoneyOrders.fulfilled.toString()]: (
      state: businessRulesMoneyOrdersDetailSliceState,
      { payload }: any
    ) => {
      (state.loadingBusinessRulesMoneyOrders = false),
        (state.businessRulesMoneyOrders =
          payload.data);
    },
    [getRulesMoneyOrders.rejected.toString()]: (
      state: businessRulesMoneyOrdersDetailSliceState
    ) => {
      state.loadingBusinessRulesMoneyOrders = false;
    },
  },
});

export const {
  setBusinessRulesMoneyOrders,
  setCurrentBusinessRulesMoneyOrders,
  resetWebFilter,
  setLoadingBusinessRulesMoneyOrders,
} = businessRulesMoneyOrdersSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default businessRulesMoneyOrdersSlice.reducer;
