export type UserLocationFilter = (
  | "ALL" 
  | "ACTIVE" 
  | "RECOVERY" 
  | "UNKNOWN"
  | "SUSPENDED" 
  | "LOCKED_OUT" 
  | "PROVISIONED" 
  | "DEPROVISIONED" 
  | "PASSWORD_EXPIRED"
);


export const isUserLocationFilter = (value: string): value is UserLocationFilter => {
  return ["ALL", "ACTIVE", "RECOVERY", "UNKNOWN", "SUSPENDED", "LOCKED_OUT", "PROVISIONED", "DEPROVISIONED", "PASSWORD_EXPIRED"].includes(value);
}

export const isAllUserLocationOptionFilter = (value: string): value is "ALL" => {
  return value === "ALL";
}