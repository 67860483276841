import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

import {
  Api,
  CashDevice,
  GetLocationsResponse,
  StoreLocation,
} from "../api/Api";
import { state } from "../test-data";

interface storeLocationSliceState {
  loading: boolean;
  storeLocations: StoreLocation[] | null;
  cashDevices: CashDevice[];
  selectedWebLocation: Array<String>;
  selectedLocation:StoreLocation[] | null;
  locationName: string;
  locationId: string;
  externalReferenceSf: string;
}

export interface LocationQueryParams {
  hasCashDevices?: boolean;
  userType?: string;
}
export interface GetCashDevicesQueryParams {
  storeId: string;
}
interface LocationType {
  id: string;
  label: string;
  name: string;
  value: string;
}
const initialState: storeLocationSliceState = {
  loading: false,
  storeLocations: null,
  cashDevices: [],
  selectedWebLocation: [],
  selectedLocation: null,
  locationName: "",
  locationId: "",
  externalReferenceSf: "",
};

function addPropertyIsSelectedByDefault(storeLocations: any) {
  const locations: any =
    storeLocations &&
    storeLocations.map((obj: any) => {
      return { ...obj, isSelected: false };
    });

  return locations;
}

export const getStoreLocations = createAsyncThunk<
  any,
  any,
  {}
>("storeLocations", (data, thunkAPI) => {
  const res = new Api().locations.getStoreLocations(data);
  return res;
});

export const getCashDevices = createAsyncThunk<
  any,
  GetCashDevicesQueryParams,
  {}
>("cashDevices", (data, thunkAPI) => {
  const res = new Api().devices.getCashDevices(data);
  return res;
});

export const storeLocationSlice = createSlice({
  name: "storeLocationSlice",
  initialState,
  reducers: {
    setWebLocation: (state, action) => {
      state.selectedWebLocation = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setCleanLocation: (state, action) => {
      state.storeLocations = action.payload;
    },
  
    resetWebFilter: () => initialState,
  },
  extraReducers: {
    [getStoreLocations.pending.toString()]: (
      state: storeLocationSliceState
    ) => {
      (state.loading = true), (state.storeLocations = null);
    },
    [getCashDevices.pending.toString()]: (
      state: storeLocationSliceState
    ) => {
      (state.loading = true), (state.cashDevices = []);
    },
    [getStoreLocations.fulfilled.toString()]: (
      state: storeLocationSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
        (state.storeLocations = addPropertyIsSelectedByDefault(
          payload.data.locations
        ));
    },
    [getCashDevices.fulfilled.toString()]: (
      state: storeLocationSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
      (state.cashDevices = payload.data.devices)
    },
    [getStoreLocations.rejected.toString()]: (
      state: storeLocationSliceState
    ) => {
      state.loading = false;

    },
    [getCashDevices.rejected.toString()]: (
      state: storeLocationSliceState
    ) => {
      state.loading = false;
    },
  },
});

export const { setWebLocation, resetWebFilter, setCleanLocation, setSelectedLocation } =
  storeLocationSlice.actions;

export default storeLocationSlice.reducer;
