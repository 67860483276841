// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";

import { useTranslation } from "react-i18next";
import { useState, useEffect, CSSProperties } from "react";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { MerchantWithDependentsAndLocations } from "@brinks/common/api/Api";
import { isAllUserLocationOptionFilter } from "../shared/types/user-location-filter.types";
import { Options, generateMerchantOptionsFromMerchantWithLocations } from "../../../utils/merchants";

// ---------------------------------------------------------
// STYLE
// ---------------------------------------------------------
const customControlStyles: CSSProperties = {
  minWidth: "240px",
  height: "54px",
  marginTop: "4px",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface AddUserMerchantSelectorProps {
  onChange: (status: null | string) => void;
  merchants: MerchantWithDependentsAndLocations[];
}

export const AddUserMerchantSelector = ({
  onChange,
  merchants
}: AddUserMerchantSelectorProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<Options[]>([
    { value: 'ALL', label: t("UserLocationFilter.all") }
  ]);

  useEffect(() => {
    handleGenerateOptions(merchants);
  }, [merchants]);

  const handleGenerateOptions = (merchantsWithLocations: MerchantWithDependentsAndLocations[]) => {
    const optionsToAdd: Options[] = generateMerchantOptionsFromMerchantWithLocations(merchantsWithLocations);
    setOptions([{ value: 'ALL', label: t("UserLocationFilter.all") }, ...optionsToAdd]);
  }

  const handleChange = (e: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Action: "Merchant " + e.value,
        Page_title: "User management | User overview",
      }
    });

    const option = options.find((option) => option.value === e.value);

    if(option && option.value) {
      onChange(
        isAllUserLocationOptionFilter(option.value)
          ? null
          : option.value
        );
    }
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      onChange={handleChange}
      data-testid="advanceFilter-UserLocationMerchant-select"
      placeholder={`${t("UserLocationFilter.merchant_placeholder")}: ${options[0].label.toUpperCase()}`}
    />
  );
}
