/** @jsxImportSource theme-ui */
import { Box, Text, Flex, Label, Radio, Input } from "theme-ui";
import { filterAmountTypeData } from "@brinks/common/constants/transactionTypeData";
import { ReactComponent as RightArrow } from "@brinks/common/Images/arrow-right.svg";
import { ReactComponent as BottomArrow } from "@brinks/common/Images/arrow-bottom.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
interface Props {
  minAmount: number | null;
  maxAmount: number | null;
  amountType: string;
  setMinAmount: (data: number) => void;
  setMaxAmount: (data: number) => void;
  setAmountType: (data: string) => void;
}
const Amount = ({
  amountType,
  minAmount,
  maxAmount,
  setMinAmount,
  setMaxAmount,
  setAmountType,
}: Props) => {
  const [isAmountFilterExpanded, setAmountFilterExpanded] = useState(true);
  const { t } = useTranslation();
  const downArrow = (isExpanded: boolean, setExpanded: (a: boolean) => void) =>
    isExpanded && (
      <BottomArrow
        onClick={(e) => setExpanded(!isExpanded)}
        data-testid="advanceFilterAmount-title-texticon-bottom-arrow"
        style={{ cursor: "pointer" }}
      />
    );
  const rightArrow = (isExpanded: boolean, setExpanded: (a: boolean) => void) =>
    !isExpanded && (
      <RightArrow
        onClick={(e) => setExpanded(!isExpanded)}
        data-testid="advanceFilterAmount-title-texticon-right-arrow"
        style={{ cursor: "pointer" }}
      />
    );

  return (
    <>
      <Flex
        data-testid="advanceFilterAmount"
        bg={"white"}
        sx={{
          flexDirection: "column",
          pt: 10,
        }}
      >
        <Flex
          sx={{ justifyContent: "space-between" }}
          mb={10}
          data-testid="advanceFilterAmount-title"
        >
          <Text
            sx={{
              fontWeight: "body",
            }}
            data-testid="advanceFilterAmount-title-text"
          >
            {t("Dashboard.amount_lable")}
          </Text>
          <Box pr={"12px"} data-testid="advanceFilterAmount-title-texticon">
            {downArrow(isAmountFilterExpanded, setAmountFilterExpanded)}
            {rightArrow(isAmountFilterExpanded, setAmountFilterExpanded)}
          </Box>
        </Flex>

        {isAmountFilterExpanded &&
          filterAmountTypeData.map((data, index) => (
            <Label
              key={index}
              data-testid="advanceFilterAmountlabelcredit"
              mt={3}
              sx={{
                justifyContent: "space-between",
                fontSize: "body",
                fontWeight: "weight_400",
                lineHeight: "body",
                color: "shade_800",
              }}
            >
              {t(data.label)}
              <Radio
                data-testid="advanceFilterAmount-title-text"
                name="type"
                value={amountType}
                onChange={() => setAmountType(data.type)}
                checked={amountType === data.type ? true : false}
              />
            </Label>
          ))}
        {isAmountFilterExpanded && (
          <>
            <Flex
              data-testid="advanceFilterAmountinput"
              sx={{ justifyContent: "space-between", alignItems: "baseline" }}
            >
              <Input
                data-testid="advanceFilterAmountinput-min"
                mt={3}
                placeholder={t("Dashboard.min")}
                type="number"
                onKeyPress={(e: any) => {
                  e = e || window.event;
                  const charCode =
                    typeof e.which == "undefined" ? e.keyCode : e.which;
                  const charStr = String.fromCharCode(charCode);
                  if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
                }}
                onChange={(e: any) => {
                  setMinAmount(parseInt(e.target.value));
                }}
                value={minAmount && minAmount || undefined}
                sx={{
                  width: 197,
                  border: "1px solid",
                  borderColor: "shade_200",
                  borderRadius: "6px",
                  fontSize: "body",
                  fontWeight: "weight_400",
                  lineHeight: "body",
                }}
              />
              <Text
                data-testid="advanceFilterAmountinput-to"
                sx={{
                  fontSize: "body",
                  color: "shade_800",
                  fontWeight: "weight_400",
                  lineHeight: "body",
                }}
              >
                to
              </Text>
              <Input
                data-testid="advanceFilterAmountinput-max"
                type="number"
                mt={3}
                pattern="[0-9]*"
                onKeyPress={(e: any) => {
                  e = e || window.event;
                  const charCode =
                    typeof e.which == "undefined" ? e.keyCode : e.which;
                  const charStr = String.fromCharCode(charCode);
                  if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
                }}
                onChange={(e: any) => {
                  setMaxAmount(parseInt(e.target.value));
                }}
                value={maxAmount && maxAmount || undefined}
                placeholder={t("Dashboard.max")}
                sx={{
                  width: 197,
                  border: "1px solid",
                  borderColor: "shade_200",
                  borderRadius: "6px",

                  fontWeight: "weight_400",
                  lineHeight: "body",
                }}
              />
            </Flex>

            {minAmount && maxAmount && minAmount > maxAmount ? (
              <Text
                data-testid="advanceFilterAmountinput-error"
                color="raspberry_500"
                sx={{
                  fontSize: "subText",
                  fontWeight: "weight_400",
                  mt: 3,
                }}
              >
                {t("Dashboard.min_max_msg")}
              </Text>
            ) : (
              ""
            )}
          </>
        )}
      </Flex>
    </>
  );
};

export default Amount;
