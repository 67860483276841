/** @jsxImportSource theme-ui */
import {
  Box,
  Text,
  Flex,
  Label,
  Checkbox,
} from "theme-ui";
import {
  filterPaymentMethods,
} from "@brinks/common/constants/transactionTypeData";
import { ReactComponent as RightArrow } from "@brinks/common/Images/arrow-right.svg";
import { ReactComponent as BottomArrow } from "@brinks/common/Images/arrow-bottom.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onUpdatePaymentMethod: (type: string) => void;
  advanceTransactionType: string;
  paymentMethod: Array<String>;
}

const PaymentMethods = ({
  onUpdatePaymentMethod,
  advanceTransactionType,
  paymentMethod,
}: Props) => {
  const [isPaymentFilterExpanded, setPaymentFilterExpanded] = useState(true);
  const { t } = useTranslation();
  const downArrow = (isExpanded: boolean, setExpanded: (a: boolean) => void) =>
    isExpanded && (
      <BottomArrow
        onClick={(e) => setExpanded(!isExpanded)}
        data-testid="advanceFilter-Transaction-bottom"
        style={{
          cursor: "pointer"
        }}
      />
    );
  const rightArrow = (isExpanded: boolean, setExpanded: (a: boolean) => void) =>
    !isExpanded && (
      <RightArrow
        onClick={(e) => setExpanded(!isExpanded)}
        data-testid="advanceFilter-Transaction-right"
        style={{
          cursor: "pointer"
        }}
      />
    );
  return (
    <>
      <Flex
        data-testid="advanceFilterTransaction"
        bg={"white"}
        sx={{
          flexDirection: "column",
          pt: 10,
        }}
      >
        <Flex
          sx={{ justifyContent: "space-between" }}
          mb={10}
          data-testid="advanceFilter-Transaction-Flex"
        >
          <Text
            data-testid="advanceFilterTransaction-heading"
            sx={{
              fontWeight: "body",
            }}
          >
           {t("Dashboard.payment_methods")} 
          </Text>
          <Box pr={"12px"}>
            {downArrow(isPaymentFilterExpanded, setPaymentFilterExpanded)}
            {rightArrow(isPaymentFilterExpanded, setPaymentFilterExpanded)}
          </Box>
        </Flex>

        {isPaymentFilterExpanded &&
          filterPaymentMethods.map((row, index) => (
            <Label
              key={index}
              data-testid="advanceFilterTransactionlabel-all"
              mt={3}
              sx={{
                justifyContent: "space-between",
                fontSize: "body",
                fontWeight: "weight_400",
                lineHeight: "body",
                color: "shade_800",
              }}
            >
              {t(row.label)}
              <Checkbox
                data-testid="advanceFilterTransaction-checkbox"
                sx={{
                  opacity: row.disabled ? 0.6 : 1,
                }}
                disabled={row.disabled}
                name="dark-mode"
                value="false"
                onChange={() => onUpdatePaymentMethod(row.type)}
                checked={
                  (paymentMethod && paymentMethod.includes(row.type)) ||
                  (paymentMethod.length === 0 &&
                    "all" === row.type &&
                    "cash" !== advanceTransactionType?.toLowerCase())
                }
              />
            </Label>
          ))}
      </Flex>
    </>
  );
};

export default PaymentMethods;
