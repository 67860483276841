import { Flex } from "theme-ui";
import { StoreLocationWithContact } from "@brinks/common/api/Api";
import Label from "./Label";
import FieldText from "./FieldText"; 
import { useTranslation } from "react-i18next";
interface Props {
  locationDetails: StoreLocationWithContact | null;
}
export const  LocationContactDetails = ({ locationDetails }: Props) => {
  const { t} = useTranslation();  
  return (
    <>
        <Flex
            data-testid="LocationDetails-field1"
            sx={{
            flexDirection: "column",
            }}
        >
            <Label data-testid="LocationDetails-name">{t("name")}</Label>
              <FieldText data-testid="LocationDetails-name-value">
               {locationDetails?.contacts?.[0].firstName || ''}  {locationDetails?.contacts?.[0].lastName || ''}
            </FieldText>
        </Flex>
        <Flex
            mt={39}
            sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            }}
            data-testid="LocationDetails-email"
        >
            <Label data-testid="LocationDetails-email-label">{t("email")}</Label>
            <FieldText data-testid="LocationDetails-email-value">
            {locationDetails?.contacts?.[0].email || ''}
            </FieldText>
        </Flex>
        <Flex
            mt={39}
            sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            }}
            data-testid="LocationDetails-phone"
        >
            <Label data-testid="LocationDetails-phone-label">{t("mobile_phone")}</Label>
            <FieldText data-testid="LocationDetails-phone-value">
            {locationDetails?.contacts?.[0].mobilePhone || ''}
            </FieldText>
        </Flex>
    </>
  );
}
export default LocationContactDetails;