import React from "react";
import Modal from "react-modal";


import { Flex, Box,Button } from "theme-ui";

const bg = {
  
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "420",
    height: "322",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    backgroundColor: "white",
    width: "45rem",
    maxWidth: "27%",
    padding: "30px",
    maxHeight: "50%",
    overflowY: "auto",
    position: "relative",
    borderRadius: "16px",
  },
};
const AuthRequiredModal = ({
  authRequiredModalOpen,
  setAuthRequiredModalOpen,
  triggerLogin,
}) => {
  const closeModal = () => {
    setAuthRequiredModalOpen(false);
  };

  const confirmModal = () => {
    setAuthRequiredModalOpen(false);
    triggerLogin();
  };

  return (
    <Modal
    backdropOpacity={0.5}
    closeTimeoutMS={500}
    isOpen={authRequiredModalOpen}
    style={bg}
    transparent={true}
    center
      >
      <Box>Auth required</Box>
      <Box>
        <Box>
          <p>Do you want to re-authenticate?</p>
        </Box>
      </Box>
      <Box>
        <Button onClick={closeModal}>No</Button>
        <Button positive onClick={confirmModal}>
          Yes
        </Button>
      </Box>
    </Modal>
  );
};
export default AuthRequiredModal;
