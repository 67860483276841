// ------------------------------------------------------------
// EXTERNAL IMPORTS
// ------------------------------------------------------------
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Api,
  Case,
  Post,
  Comment,
  CaseTemplate,
  BrinksOds001,
  BrinksOds007,
  GetPostsResponse,
  GetCommentsResponse,
  CaseWithMerchant,
  MerchantWithMerchantsAssociated,
} from "../api/Api";

// ------------------------------------------------------------
// TYPES
// ------------------------------------------------------------
interface supportCaseSliceState {
  loading: boolean;
  posts: Post[] | null;
  selectedFiles:File[];
  loadingPost: boolean;
  selectedLocation: any;
  subjetDescription: any;
  loadingComment: boolean;
  supportCaseResponse: any;
  selectedPostFiles:File[];
  loadingTemplates: boolean;
  comments: Comment[] | null;
  selectedCommentFiles:File[];
  successSupportCase: Boolean;
  newPostResponseError: string;
  selectedSubjet: string | null;
  locationListDisabled: boolean;
  newCommentResponseError: string;
  templates: CaseTemplate[] | null;
  casesWithMerchant: CaseWithMerchant[];
  newPostResponse: GetPostsResponse | null;
  newCommentResponse: GetCommentsResponse | null;
  supportCaseResponseError: BrinksOds001 | BrinksOds007;
  selectedCaseMerchant: null | MerchantWithMerchantsAssociated;
}

// Define the initial state using that type
const initialState: supportCaseSliceState = {
  posts: null,
  loading: false,
  comments: null,
  templates: null,
  selectedFiles:[],
  selectedSubjet: "",
  loadingPost: false,
  selectedLocation: "",
  subjetDescription: "",
  loadingComment: false,
  newPostResponse: null,
  selectedPostFiles: [],
  casesWithMerchant: [],
  loadingTemplates: false,
  newPostResponseError: "",
  newCommentResponse: null,
  selectedCommentFiles: [],
  supportCaseResponse: null,
  successSupportCase: false,
  locationListDisabled: true,
  newCommentResponseError: "",
  selectedCaseMerchant: null,
  supportCaseResponseError: null,

};

export const geCasesWithMerchant = createAsyncThunk<any, any, {}>(
  "CasesWithMerchant",
  (data, thunkAPI) => {
    const res = new Api().casesWithMerchant.getCasesWithMerchant(data.transactionType);
    return res;
  }
);

export const getTemplates = createAsyncThunk<any, any, {}>(
  "get-templates",
  (data, thunkAPI) => {
    const res = new Api().cases.getTemplates({ countryCode: data || `XX` });
    return res;
  }
);

export const getPosts = createAsyncThunk<any, any, {}>(
  "Posts",
  (caseSalesforceId, thunkAPI) => {
    const res = new Api().cases.getPosts(caseSalesforceId);
    return res;
  }
);
export const getComments = createAsyncThunk<any, any, {}>(
  "Comments",
  ({ caseSalesforceId, postSalesforceId }, thunkAPI) => {
    const res = new Api().cases.getComments(caseSalesforceId, postSalesforceId);
    return res;
  }
);
export const submitComment = createAsyncThunk<any, any, {}>(
  "submitComment",
  ({ caseSalesforceId, postSalesforceId, text, files }, thunkAPI) => {
    const res = new Api().cases.saveComment(
      caseSalesforceId,
      postSalesforceId,
      { text, files }
    );
    return res;
  }
);

export const submitSupportCase = createAsyncThunk<any, any, {}>(
  "submitSupportCase",
  async (data: any, { rejectWithValue }) => {
    try {
      const res = await new Api().cases.saveCase(data);
      return res;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitPost = createAsyncThunk<any, any, {}>(
  "submitPost",
  (data: any, thunkAPI) => {
    const res = new Api().cases.savePost(data.id, { ...data });
    return res;
  }
);
export const supportCaseWithMerchantSlice = createSlice({
  name: "supportCaseSlice",
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setSubject: (state, action) => {
      state.selectedSubjet = action.payload;
      state.subjetDescription = state.templates?.find(
        (t) => t.subject === action.payload
      )?.description;
    },
    setsubjetDescription: (state, action) => {
      state.subjetDescription = action.payload;
    },
    setFiles: (state, action) => {
      state.selectedFiles = action.payload;
    },
    setPostFiles: (state, action) => {
      state.selectedPostFiles = action.payload;
    },
    setCommentFiles: (state, action) => {
      state.selectedCommentFiles = action.payload;
    },
    setSuccessSupportCaseMsg: (state, action) => {
      state.successSupportCase = action.payload;
    },
    setLocationListDisabled: (state, action) => { 
      state.locationListDisabled = action.payload;
    },
    setSelectedCaseMerchant: (state, action) => {
      state.selectedCaseMerchant = action.payload;
    },
    resetComment: (state) => {
      state.comments = null;
      state.selectedCommentFiles = [];
    },
    resetFiles: (state) => {
      state.selectedFiles = [];
    },
    resetPostFiles: (state) => {
      state.selectedPostFiles = [];
    },
    resetCommentFiles: (state) => {
      state.selectedCommentFiles = [];
    },
    resetState: () => initialState,
  },
  extraReducers: {
    [geCasesWithMerchant.pending.toString()]: (state: supportCaseSliceState) => {
      (state.loading = true), (state.casesWithMerchant = []);
    },
    [geCasesWithMerchant.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
        (state.casesWithMerchant = payload.data.cases.sort(function (
          a: { lastModifiedDate: string },
          b: { lastModifiedDate: string }
        ) {
          return b.lastModifiedDate > a.lastModifiedDate ? 1 : -1;
        }));
    },
    [getPosts.pending.toString()]: (state: supportCaseSliceState) => {
      (state.loadingPost = true), (state.posts = null);
    },
    [getPosts.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loadingPost = false), (state.posts = getPostsWithSorting(payload));
    },
    [getPosts.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loadingPost = false;
    },
    [getComments.pending.toString()]: (state: supportCaseSliceState) => {
      state.loadingComment = true;
    },
    [getComments.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loadingComment = false),
        (state.comments = getCommentsWithSorting(payload));
    },
    [getComments.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loadingComment = false;
    },
    [getTemplates.pending.toString()]: (state: supportCaseSliceState) => {
      state.loadingTemplates = true;
    },
    [getTemplates.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loadingTemplates = false),
        (state.templates = payload.data.caseTemplates);
    },
    [getTemplates.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loadingTemplates = false;
    },
    [geCasesWithMerchant.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loading = false;
    },
    [submitSupportCase.pending.toString()]: (state: supportCaseSliceState) => {
      state.loading = true;
    },
    [submitSupportCase.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loading = false), (state.supportCaseResponse = payload.data);
    },
    [submitSupportCase.rejected.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      state.loading = false;
      state.supportCaseResponseError = payload;
    },
    [submitPost.pending.toString()]: (state: supportCaseSliceState) => {
      state.loadingPost = true;
    },
    [submitPost.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loadingPost = false), 
      (state.newPostResponse = payload.data),
      (state.selectedPostFiles = []);
    },
    [submitPost.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loadingPost = false;
      state.newPostResponseError = "Something went wrong";
    },

    [submitComment.pending.toString()]: (state: supportCaseSliceState) => {
      state.loadingComment = true;
    },
    [submitComment.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loadingComment = false), 
      (state.newCommentResponse = payload.data),
      (state.selectedCommentFiles = []);
    },
    [submitComment.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loadingComment = false;
      state.newCommentResponseError = "Something went wrong";
    },
  },
});

export const {
  resetState,
  setLocation,
  setSubject,
  setsubjetDescription,
  setFiles,
  setPostFiles,
  setCommentFiles,
  setSuccessSupportCaseMsg,
  resetComment,
  resetFiles,
  resetPostFiles,
  resetCommentFiles,
  setSelectedCaseMerchant,
  setLocationListDisabled
} = supportCaseWithMerchantSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default supportCaseWithMerchantSlice.reducer;
function getPostsWithSorting(payload: any): Post[] | null {
  return [...payload.data.posts].sort(function (
    a: { createdDate: string },
    b: { createdDate: string }
  ) {
    return b.createdDate > a.createdDate ? -1 : 1;
  });
}
function getCommentsWithSorting(payload: any): Comment[] | null {
  return payload.data.comments.sort(function (
    a: { createdDate: string },
    b: { createdDate: string }
  ) {
    return b.createdDate > a.createdDate ? -1 : 1;
  });
}
