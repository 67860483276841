
/** @jsxImportSource theme-ui */
export const OrderPickupIcon = ({ color, secondaryColor }) => {
  return (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.1998 9.6001C5.87432 9.6001 4.7998 10.6746 4.7998 12.0001V36.0001C4.7998 37.3256 5.87432 38.4001 7.1998 38.4001H9.71983C10.2758 35.6614 12.6971 33.6001 15.5998 33.6001C18.5026 33.6001 20.9239 35.6614 21.4798 38.4001H23.9998C25.3253 38.4001 26.3998 37.3256 26.3998 36.0001V12.0001C26.3998 10.6746 25.3253 9.6001 23.9998 9.6001H7.1998Z" sx={{fill:color}} />
    <path d="M33.5998 16.8001C32.2743 16.8001 31.1998 17.8746 31.1998 19.2001V33.7201C31.5875 33.6414 31.9888 33.6001 32.3998 33.6001C35.3026 33.6001 37.7239 35.6614 38.2798 38.4001H40.7998C42.1253 38.4001 43.1998 37.3256 43.1998 36.0001V24.0001C43.1998 23.3636 42.9469 22.7531 42.4969 22.303L37.6969 17.503C37.2468 17.053 36.6363 16.8001 35.9998 16.8001H33.5998Z" sx={{fill:color}} />
    <path d="M19.2 39.6C19.2 41.5882 17.5882 43.2 15.6 43.2C13.6118 43.2 12 41.5882 12 39.6C12 37.6118 13.6118 36 15.6 36C17.5882 36 19.2 37.6118 19.2 39.6Z" sx={{fill:secondaryColor}} />
    <path d="M36 39.6C36 41.5882 34.3882 43.2 32.4 43.2C30.4118 43.2 28.8 41.5882 28.8 39.6C28.8 37.6118 30.4118 36 32.4 36C34.3882 36 36 37.6118 36 39.6Z" sx={{fill:secondaryColor}} />
  </svg>
  );
};
