import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Heading, Flex } from "theme-ui";
import PageHeader from "../../Shared/PageHeader";
import Wrapper from "../../Shared/Wrapper/Wrapper";
import Language from "./Language";
import TermsAndConditionsLink from "./TermsAndConditionsLink";
import TagManager from "react-gtm-module";

const Setting: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Settings | Settings"
      }
    })
  }, []);
  return (
    <>

      <PageHeader>
        <Heading>Settings</Heading>
      </PageHeader>

      <Wrapper>
        <Language />
      </Wrapper>

      <Flex sx={{
        bottom:"15px",
        position:"fixed"
      }}>
        <TermsAndConditionsLink
          url={t("TermsAndConditions.link")}
          text={t("Setting.click_here")}
          anchorText={t("Setting.terms_conditions")}
        />
      </Flex>


    </>
  );
};

export default Setting;
