// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import {
  Api,
  User,
} from "../api/Api";
import { getDeviceId } from "../utils/index";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface UserSliceState {
  errMsg:any
  id: string;
  users: User[];
  updateUser: any;
  success: boolean;
  isError: boolean;
  isFetching: boolean;
  isSaveUserError:boolean;
  isUpdateUserError:boolean;
}

const initialState: UserSliceState = {
  id: "",
  errMsg:'',
  users: [],
  success: false,
  isError: false,
  updateUser: null,
  isFetching: false,
  isSaveUserError:false,
  isUpdateUserError:false,
};

export const saveUsers = createAsyncThunk<
  any,
  any,
  {}
>("addUsers", async (data, { rejectWithValue }) => { 
  
  const token = localStorage.getItem("mfaToken");
  
  const params = {
    headers: {
      "mfa-token": `${token}`,
      "device-id": getDeviceId(),
    },
  };

  try {
    const res = await new Api().users.saveUsers(data, params);
    return res;

  } catch (error: any) { 
    if (!error?.response) { 
      throw error
    }
    return rejectWithValue(error.response.data)
  }
});



export const updateUser = createAsyncThunk<
  any,
  any,
  {}
>("updateUser", async (data ,{ rejectWithValue }) => { 
  const token = localStorage.getItem("mfaToken");
  const params = {
    headers: {
      "mfa-token": `${token}`,
      "device-id": getDeviceId(),
    },
  };

  try {
    const res:any = await new Api().users.updateUser(data.userId,data,params);
    getUsers({})
    return res;

  } catch (error: any) { 
    if (!error?.response) {
      throw error
    }
    
    return rejectWithValue(error.response.data)
  }
});

export const getUsers = createAsyncThunk<
  any,
  any,
  {}
>("user", () => { 
  const res = new Api().users.getUsers();
  return res;
});

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    closeMessage: () => initialState,
    resetState:()=>initialState
   
  },
  extraReducers: {
    [saveUsers.pending.toString()]: (state: UserSliceState) => {
      (state.isFetching = true), (state.success = false);
    },
    [saveUsers.fulfilled.toString()]: (state: UserSliceState, { payload }: any) => {
      (state.isFetching = false),
      (state.success = payload.status === 200 ? true : false);
    },
    [saveUsers.rejected.toString()]: (state: UserSliceState, {payload}) => { 
      state.errMsg = payload;
      state.isFetching = false;
      state.isSaveUserError = true;
    },
    [getUsers.pending.toString()]: (state, { payload }: any) => {
      state.success = false;
      state.isFetching = true;
    },
    [getUsers.fulfilled.toString()]: (state, { payload }: any) => { 
      state.isFetching = false;
      state.users = payload.data.users;
    },
    [getUsers.rejected.toString()]: (state, { payload }: any) => {
      state.isError = true;
      state.success = false;
      state.isFetching = false;
    },
    [updateUser.pending.toString()]: (state, { payload }: any) => {
      state.success = false;
      state.isFetching = true;
    },
    [updateUser.fulfilled.toString()]: (state, { payload }: any) => {
      state.updateUser = payload.data;
      state.isFetching = false;
      state.success = true;
    },
    [updateUser.rejected.toString()]: (state, { payload }: any) => {
      state.success = false;
      state.errMsg = payload;
      state.isFetching = false;
      state.isUpdateUserError = true;
    },
  },
});

export const { closeMessage, resetState } = userSlice.actions;
export default userSlice.reducer;
