import { Button, Input, Label, Text } from "theme-ui";
import { PeperClip } from "@brinks/common/Icons/Components/peper-clip";
import { CloseIcon } from "@brinks/common/Icons/Components/close_modal";

interface Props {
    selectedFiles:File[],
    right: number,
    bottom: number
    handleResetFiles: () => void,
    handleFileChange: (files: FileList | null) => void
}
export const FileUpload = ({selectedFiles, handleResetFiles, handleFileChange, right, bottom}: Props) => {
  return (
    <Text
      sx={{
        position: "absolute",
        right: right,
        bottom: bottom,
        cursor: "pointer",
      }}
    >
      {Array.from(selectedFiles).length > 0 ? (
        <Button
          onClick={() => {
            handleResetFiles();
          }}
          mr={20}
          variant="muted"
          sx={{
            background: "none",
            padding: 0,
            cursor: "pointer",
            borderRadius: 0,
          }}
        >
          <CloseIcon
            style={{
              cursor: "pointer",
            }}
            color={"shade_300"}
          />
        </Button>
      ) : (
        <>
          <Input
            type="file"
            onChange={(e) => handleFileChange(e.target.files)}
            sx={{ display: "none" }}
            name="filefield"
            id="filefield"
            multiple={true}
          />
          <Label htmlFor="filefield" sx={{ cursor: "pointer" }}>
            <PeperClip color={"order_primary"} />
          </Label>
        </>
      )}
    </Text>
  );
};
