// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { useMemo } from 'react';
import { Flex, Text } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { StoreLocation } from "@brinks/common/api/Api";
import { countDistinctMerchants } from '../../../../utils/merchants';
import { useUserWithLocations } from '../../../../Hooks/useUserWithLocations';

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface RowMerchantElementToLocationProps {
  location: StoreLocation;
}

export const RowMerchantElementToLocation = ({ 
  location, 
}: RowMerchantElementToLocationProps) => {
  const { merchants } = useUserWithLocations({});

 

  const countOfDistinctMerchants = useMemo(() => {
    return countDistinctMerchants([location]);
  }, [location])

  const merchant = useMemo(() => {
    return merchants.find((merchant) => {
      return merchant.id === location?.merchantId
    });
  }, [merchants])

  if(!location) {
    return (
      <Text sx={{ color: "shade_600", fontWeight: "weight_400"}}>
        {`--`}
      </Text>
    )
  };

  return (
    <Flex sx={{ display: 'flex', alignItems: 'center'}}>
      <Flex sx={{ color: "shade_600", fontWeight: "weight_400"}}>
          {merchant?.name ?? '--'}
      </Flex>
      {countOfDistinctMerchants > 1 &&   
        <Flex
          ml={3}
          sx={{
            width: 45,
            height: 32,
            borderRadius: 4,
            bg: "borderColor",
            color: "shade_500",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {`+ ${countOfDistinctMerchants - 1}`}
        </Flex>
      }
    </Flex>
  );
}
