// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";

import { useTranslation } from "react-i18next";
import { useState, useEffect, CSSProperties } from "react";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { Order } from "@brinks/common/api/Api";
import {
  handleGenerateMerchantOptionsByOrders
} from "../../../../utils/merchants/MerchantWithMerchantsAssociated.utils";
import { Text } from "theme-ui";
import { InputDisabledStyle } from "../../../../helpers/styles";

// ---------------------------------------------------------
// STYLE
// ---------------------------------------------------------
const customControlStyles: CSSProperties = {
  minWidth: "240px",
  height: "54px",
  marginTop: "4px"
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface Options {
  label: string;
  value: string;
}

interface OrderListMerchantSelectorProps {
  orders: Order[];
  onChange: (status: null | string) => void;
}

export const OrderListMerchantSelector = ({ onChange, orders }: OrderListMerchantSelectorProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<Options[]>([
    { value: 'ALL', label: t("UserLocationFilter.all") }
  ]);

  useEffect(() => {
    handleGenerateOptions(orders);
  }, [orders]);

  const handleGenerateOptions = (orders: Order[]) => {
    const merchantOptions = handleGenerateMerchantOptionsByOrders(orders);
    setOptions([{ value: 'ALL', label: t("ManageOrders.all") }, ...merchantOptions]);
  }

  const handleChange = (e: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Action: "Merchant " + e.value,
        Page_title: "Orders management | Order overview",
      }
    });

    const option = options.find((option) => option.value === e.value);

    if(option) {
      onChange(option.value === "ALL" ? null : option.value);
    }
  };

  return options.length === 2 && options.find((x) => x.value !== "ALL") ? (
    <div data-testid="user-location-merchant-label" style={{...InputDisabledStyle, height: "54px", marginTop: "4px"}}>
        {options[1].label}
      </div>
  ) : (
    <Select
      options={options}
      styles={customStyles}
      onChange={handleChange}
      data-testid="advanceFilter-UserLocationMerchant-select"
      placeholder={`${t(
        "ManageOrders.merchant_placeholder"
      )}: ${options[0].label.toUpperCase()}`}
    />
  );
}
