// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { Text } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { UserType } from "@brinks/common/constants/users";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface UserRoleLabelProps {
  type: string;
  userType:string | undefined;
}

export const UserRoleLabel = ({ type , userType}: UserRoleLabelProps) => {
  return (
    <Text
      sx={{
        px: "8px",
        width: 82,
        py: "2px",
        height: 28,
        borderRadius: 4,
        fontSize: "subText",
        textAlign: "center",
        backgroundColor: `${
          type === UserType.STANDARD_USER ? "royalBlue_150" : "secondary_150"
        }`,
        color: `${
          type === UserType.STANDARD_USER ? "royalBlue_500" : "secondry_850"
        }`,
      }}
    >
      {userType}
    </Text>
  );
}
