export const colors = {
  turquoise: '#0C8D74',
  yankeesBlue: '#0F223D',
  blue: '#0A408F',
  royalBlue: '#70A6F5',
  black: '#000000',
  white: '#FFFFFF',
  beaver: '#667B99',
  platinum: '#E2E5E9',
  cadetBlue: '#C2B5A3',
  brightNavyBlue: '#0F61D8',
  lavenderGray: '#C3CAD5',
  metallicBlue: '#264673',
  silverPink: '#A3B0C2',
  darkMidnightBlue: '#09326D',
  bainsboro: '#DBDBDC',
  eerieBlack: '#0D1726',
  red: '#E20B0B',
  antiFlashWhite: '#EDF7F0',
  linen: '#FDE7ED',
  deepMossGreen: '#2E6B3D',
  lightGrey: '#F0F2F4',
  shadeBlue: '#476185',
  fernGreen: '#4DB266',
  caribbeanGreen: '#11CCA8',
  fadedBlue: '#6199C4',
  lightBlue: '#EDF4FE',
  egyptianBlue: '#0A4A8E',
  innerGreen: '#11CCA8', //icon inner green
};
