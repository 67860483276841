import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  Api,
  CurrencyCodes,
  Denomination,
  GetDenominationsResponse,
} from "../api/Api";


// Define the initial state using that type
const initialState: denominationsSliceState = {
    loadingDenominations: false,
    denominations: null,
    currentDenomination: null,
    currentCurrency: CurrencyCodes.EUR
};

interface denominationsSliceState {
    loadingDenominations: boolean;
    denominations: Denomination[] | null,
    currentDenomination: Denomination | null;
    currentCurrency: string
}

export const getDenominations = createAsyncThunk<any, any, {}>(
  "GetDenominationsResponse",
  (data, thunkAPI) => {
    const res = new Api().denominations.getDenominations(data);
    return res;
  }
);

export const denominationsDetailSlice = createSlice({
  name: "denominationsDetailSlice",
  initialState,
  reducers: {
    setDenominations: (state, action) => {
      state.denominations = action.payload;
    },
    setCurrentDenomination: (state, action) => {
      state.currentDenomination = action.payload;
    },
    setCurrentCurrency: (state, action) => {
      state.currentCurrency = action.payload;
    },
    setLoadingDenomination: (state, action) => {
      state.loadingDenominations = action.payload;
    },
    resetWebFilter: () => initialState,
  },
  extraReducers: {
    [getDenominations.pending.toString()]: (state: denominationsSliceState) => {
      (state.loadingDenominations = true), (state.denominations = null);
    },
    [getDenominations.fulfilled.toString()]: (
      state: denominationsSliceState,
      { payload }: any
    ) => {
      (state.loadingDenominations = false), (state.denominations = payload.data.denominations);
    },
    [getDenominations.rejected.toString()]: (state: denominationsSliceState) => {
      state.loadingDenominations = false;
    },
  },
});

export const {setDenominations, setCurrentDenomination,resetWebFilter, setLoadingDenomination, setCurrentCurrency} = denominationsDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default denominationsDetailSlice.reducer;
