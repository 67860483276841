/** @jsxImportSource theme-ui */
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { getToken } from "@brinks/common/reducers/authSlice";
import {
  REACT_APP_NATIVE_APP_BASE_URL,
  REACT_APP_NATIVE_APP_CLIENT_ID,
  REACT_APP_NATIVE_APP_DISCOVERY_URI,
  REACT_APP_NATIVE_APP_REDIRECT_URI,
} from "@brinks/common/utils/Config";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";


function Callback() {
  const location = useLocation();
  const navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [exchangedCode, setExchangedCode] = useState(false);

  const dispatch = useAppDispatch();
  const { accessTokenData, tokenData } = useAppSelector(
    (state) => state.authSlice
  );
  useEffect(() => {
    const data: any = {
      grant_type: `authorization_code`,
      client_id: REACT_APP_NATIVE_APP_CLIENT_ID,
      redirect_uri: REACT_APP_NATIVE_APP_REDIRECT_URI,
      code: searchParams.get("code"),
      code_verifier: localStorage.getItem("code"),
    };
    const formBody = [];
    for (const property in data) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    dispatch(getToken(formBody.join("&")));
    setExchangedCode(true);
  }, []);

  useEffect(() => {
    if (accessTokenData?.access_token) {
      oktaAuth.tokenManager.setTokens({
          accessToken: {
            accessToken: accessTokenData?.access_token,
            claims: { sub: "" },
            tokenType: accessTokenData.token_type,
            userinfoUrl: `${REACT_APP_NATIVE_APP_DISCOVERY_URI}/v1/userinfo`,
            expiresAt: Date.now() + accessTokenData.expires_in,
            scopes: accessTokenData.scope,
            authorizeUrl: `${REACT_APP_NATIVE_APP_DISCOVERY_URI}/v1/authorize`,
          },
          
          idToken: {
            clientId: REACT_APP_NATIVE_APP_CLIENT_ID || "null",
            idToken:accessTokenData.id_token,
            claims: { sub: "" },
            expiresAt: Date.now() + accessTokenData.expires_in,
            scopes: accessTokenData.scope,
            authorizeUrl: `${REACT_APP_NATIVE_APP_DISCOVERY_URI}/v1/authorize`,
            issuer:REACT_APP_NATIVE_APP_BASE_URL ||  "null",

          }
        })
      oktaAuth.authStateManager.updateAuthState();
      const userProfile = {
        id: tokenData?._embedded?.user?.id,
        ...tokenData?._embedded?.user?.profile,
      };
      localStorage.setItem(`user_profile`, JSON.stringify(userProfile));
      navigate("/");
    }
  }, [accessTokenData]);

  useEffect(() => {
    if (accessTokenData?.error) {
      navigate("/login");
    }
  }, [accessTokenData]);

  return <></>;
}

export default Callback;
