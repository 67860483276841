const env = typeof window != "undefined" && window.document ? "WEB" : "MOBILE";
let AsyncStorage = null;
import jwt_decode from "jwt-decode";
import { oktaAuthGlobal, timer } from "../utils";
let oktaRN = null;
if (env === "MOBILE") {
  AsyncStorage = require(`react-native`).AsyncStorage;
  oktaRN = require("@okta/okta-react-native");
}

export const StorageUtil = {
  getItem: async (key) => {
    if (env === "MOBILE") {
      if (`access_token` === key) {
        const isAuthenticatedValue = await oktaRN.isAuthenticated();
        if(!isAuthenticatedValue.isAuthenticated) {
          await oktaRN.refreshTokens();
        }
        const tkn = await oktaRN.getAccessToken();
        return tkn.access_token;
      } else {
        return await AsyncStorage.getItem(key);
      }
    }
    if (env === "WEB") {

      let isTokenValid = validateToken();
      while (oktaAuthGlobal.getAccessToken() && !isTokenValid) {
        await timer(200);
        isTokenValid = validateToken();
      }
      return oktaAuthGlobal.getAccessToken();
    }

    function validateToken() {
      try {
        const decoded: any = jwt_decode(oktaAuthGlobal.getAccessToken());
        if (decoded && decoded.exp && Date.now() / 1000 < decoded.exp) {
          return true;
        } else {
          throw new Error('Token is invalid or has expired');
        }
      } catch (e) {
        console.log('Error validating token:', e);
        throw new Error('Token validation failed');
      }
    }
    
  },
  setItem: (key, value) => {
    if (env === "MOBILE")
      return AsyncStorage.setItem(key, JSON.stringify(value));
    return localStorage.setItem(key, JSON.stringify(value));
  },
};
