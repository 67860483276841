import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { Api, Sku, GetSkusResponse } from "../api/Api";

// Define the initial state using that type
const initialState: skuDetailSliceState = {
  loadingSkus: false,
  skus: null,
  currentSku: null,
};

interface skuDetailSliceState {
  loadingSkus: boolean;
  skus: Sku[] | null;
  currentSku: Sku | null;
}

export const getSkus = createAsyncThunk<any, any, {}>(
  "GetSkusResponse",
  async (data, thunkAPI) => {
    try {
    const res = await new Api().skus.getAllSkus();
    return res;
  } catch (error) {
    console.error("Error in getDenominations:", error);
    throw error;
  }
}
);


export const getSku = createAsyncThunk<any, any, {}>(
  "GetSkusResponse",
  (data, thunkAPI) => {
    const res = new Api().sku.getSkusById(data, { skuId: data });
    return res;
  }
);

export const skuDetailSlice = createSlice({
  name: "skuDetailSlice",
  initialState,
  reducers: {
    setSkus: (state, action) => {
      state.skus = action.payload;
    },
    setCurrentSku: (state, action) => {
      state.currentSku = action.payload;
    },
    setLoadingSkus: (state, action) => {
      state.loadingSkus = action.payload;
    },
    resetWebFilter: () => initialState,
  },
  extraReducers: {
    [getSkus.pending.toString()]: (state: skuDetailSliceState) => {
      (state.loadingSkus = true), (state.skus = null);
    },
    [getSkus.fulfilled.toString()]: (state: skuDetailSliceState, { payload }: any) => {
      state.loadingSkus = false;
      state.skus = payload.data.skus;
    },
    [getSkus.rejected.toString()]: (state: skuDetailSliceState) => {
      state.loadingSkus = false;
    },
  },
});

export const { setSkus, setCurrentSku, resetWebFilter, setLoadingSkus } =
  skuDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default skuDetailSlice.reducer;
