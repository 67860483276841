import React from "react";
import config from "@brinks/common/config";
import Modal from "react-modal";
import { Flex, Box,Button } from "theme-ui";

const bg = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "420",
    height: "322",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    backgroundColor: "white",
    width: "45rem",
    maxWidth: "27%",
    padding: "30px",
    maxHeight: "50%",
    overflowY: "auto",
    position: "relative",
    borderRadius: "16px",
  },
};
const CorsErrorModal = ({ corsErrorModalOpen, setCorsErrorModalOpen }) => {
  // Build URL to "Trusted Origins" page in Admin panel
  const { issuer } = config.oidc;
  const baseUrl = issuer.split("/oauth2")[0];
  const hostParts = new URL(baseUrl).host.split(".");
  hostParts[0] += "-admin";
  const adminHost = hostParts.join(".");
  const corsAdminUrl = `https://${adminHost}/admin/access/api/trusted_origins`;

  // URL to guide for enabling CORS
  const guideUrl =
    "https://developer.okta.com/docs/guides/enable-cors/granting-cors/";

  // CORS error modal
  return (
    <Modal
    backdropOpacity={0.5}
    closeTimeoutMS={500}
    isOpen={corsErrorModalOpen}
    style={bg}
    transparent={true}
    center
      >
      <Box>Network Error</Box>
      <Flex>
        <Box>
          <p>Seems like logout API call resulted with CORS error.</p>
          <p>
            You may need to add your origin {window.location.origin} to list of
            trusted origins in your{" "}
            <a href={corsAdminUrl} target="_blank" rel="noreferrer">
              Okta Administrator Dashboard
            </a>
          </p>
          <p>
            Read{" "}
            <a href={guideUrl} target="_blank" rel="noreferrer">
              this guide
            </a>{" "}
            for more info.
          </p>
        </Box>
      </Flex>
    </Modal>
  );
};
export default CorsErrorModal;
