import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "@brinks/common/hooks/hooks";
import { reset } from "@brinks/common/reducers/authSlice";
import { useOktaAuth } from "@okta/okta-react";

const Logout: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { authState, oktaAuth } = useOktaAuth();

  
  React.useEffect(() => {
    localStorage.clear();
    oktaAuth.signOut();
    oktaAuth.revokeAccessToken();
    oktaAuth.revokeRefreshToken();
    oktaAuth.tokenManager.clear();
    dispatch(reset());
    window.history.replaceState(null, "null", "/login");
    window.location.reload()
  }, []);

  return (
    <>
      <Navigate to="/login" replace state={{ path: location.pathname }} />
    </>
  );
};

export default Logout;
