import { CSSProperties } from "react";
import { Box, Text, Flex } from "theme-ui";
import { useTranslation } from "react-i18next";
import Select, { StylesConfig } from "react-select";
import { useAppSelector } from "@brinks/common/hooks/hooks";

interface OrdersCurrencyProps {
  label: any;
  disabled?: boolean;
  selectedCurrency?: any;
 currencies: any;
  onUpdateCurrency: (currency: string) => void;
}

export const SelectCurrency = ({
  label,
  currencies,
  disabled = false,
  onUpdateCurrency,
  selectedCurrency,
}: OrdersCurrencyProps) => {
  const { t } = useTranslation();

  const {
    selectedPickupLocation
  } = useAppSelector((state) => state.orderDetailsSlice);


  const customControlStyles: CSSProperties = {
    height: "61px",
  };

  const customStyles: StylesConfig<any> = {
    control: (provided, state) => {
      return {
        ...provided,
        ...customControlStyles,
      };
    },
  };

  return (
    <Flex
      bg={"white"}
      data-testid="advanceFilterCurrency"
      sx={{ zIndex: 999, flexDirection: "column", marginTop: "20px" }}
    >
      <Text
        data-testid="flex-currency-text"
        sx={{
          fontWeight: "body",
          fontSize: "subText",
          lineHeight: "register_bag",
        }}
      >
        {label}
      </Text>
      <Box
        className="custom-select"
        sx={{ pt: "8px", width: "455px" }}
        data-testid="advanceFilter-Currency-box"
      >
        <Select
          isSearchable={true}
          styles={customStyles}
          isDisabled={disabled}
          value={selectedCurrency}
          data-testid="advanceFilter-Currency-select"
          placeholder={"Select currency"} //TO DO
          options={currencies}
          onChange={(selectedOption: any) => {
            onUpdateCurrency(selectedOption);
          }}
        />
      </Box>
    </Flex>
  );
};
