/** @jsxImportSource theme-ui */
export const OrderChangeIcon = ({ color }) => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" sx={{fill:color}} xmlns="http://www.w3.org/2000/svg">
      <path d="M0.700012 9.80005V12.5C0.700012 13.9912 3.52062 15.2 7.00001 15.2C10.4794 15.2 13.3 13.9912 13.3 12.5V9.80005C13.3 11.2912 10.4794 12.5 7.00001 12.5C3.52062 12.5 0.700012 11.2912 0.700012 9.80005Z" />
      <path d="M0.700012 5.30005V8.00005C0.700012 9.49122 3.52062 10.7 7.00001 10.7C10.4794 10.7 13.3 9.49122 13.3 8.00005V5.30005C13.3 6.79122 10.4794 8.00005 7.00001 8.00005C3.52062 8.00005 0.700012 6.79122 0.700012 5.30005Z" />
      <path d="M13.3 3.50005C13.3 4.99122 10.4794 6.20005 7.00001 6.20005C3.52062 6.20005 0.700012 4.99122 0.700012 3.50005C0.700012 2.00888 3.52062 0.800049 7.00001 0.800049C10.4794 0.800049 13.3 2.00888 13.3 3.50005Z" />
    </svg>
  );
};
