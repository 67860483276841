import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import {
  handleDeclaredCoinQuantity,
  handleDeclaredNoteQuantity,
  setGrandTotal,
  setTotalWithCurrency,
  setCoinTotal,
  setCoinTotalWithCurrency,
  setNoteTotal,
  setNoteTotalWithCurrency,
  resetState,
  resetDeliveryDateError,
} from "@brinks/common/reducers/orderDetailsSlice";
import {
  Box,
  Flex,
  Label,
  Button,
  Input,
  Divider,
  Text,
  Alert,
  Close,
} from "theme-ui";
import { formatCurrency } from "@brinks/common/utils";
import DenominationRow from "./DenominationRow";
import SelectTimeslotDetails from "./selectTimeslotDetails";
import { resetWebFilter } from "@brinks/common/reducers/storeLocationSlice";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { OrderChangeType } from "@brinks/common/constants/orderChangeType";

export default function SelectDenominations({ nextStep, updateStep }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    grandTotal,
    totalWithCurrency,
    totalOfNotesWithCurrency,
    totalOfCoinsWithCurrency,
    declaredNoteValues,
    declaredCoinValues,
    totalOfCoins,
    totalOfNotes,
    selectedPickupLocation,
    locationBusinessRules,
    currentDenominationStateType,
    selectedOrderMerchant,
    selectedCurrency,
    customerReference,
    deliveryDateResponseError,
    selectedTypeOfChange,
    selectedPickupDate,
  } = useAppSelector((state) => state.orderDetailsSlice);

  const { denominations, loadingDenominations, currentDenomination } =
    useAppSelector((state) => state.denominationsSlice);

  const { businessRulesMoneyOrders, currentBusinessRulesMoneyOrders } =
    useAppSelector((state) => state.businessRulesMoneyOrdersSlice);

  const { currentBusinessRulesMoneyOrdersSku, businessRulesMoneyOrdersSkus } =
    useAppSelector((state) => state.businessRulesMoneyOrdersSkusSlice);

  const { currentSku, skus } = useAppSelector((state) => state.skusDetailSlice);
  const [currentDenominationKey, setCurrentDenominationKey] = useState(
    currentDenominationStateType
  );

  const [isValid, setIsvalid] = useState(false);
  const [isTotalValid, setTotalIsvalid] = useState(false);
  const [total, setTotal] = useState(grandTotal);
  const [coinTotal, setNoteTotalState] = useState(totalOfNotes);
  const [noteTotal, setCoinTotalState] = useState(totalOfCoins);

  let denomininationKeys = [
    { lable: t("ManageOrders.notes"), value: "notes" },
    { lable: t("ManageOrders.coins"), value: "coins" },
  ];

  const orderMaximumAmount = businessRulesMoneyOrders
    ? businessRulesMoneyOrders[0].orderMaximumAmount
    : "0";

  const orderAllowCustomDates = businessRulesMoneyOrders
    ? businessRulesMoneyOrders[0].orderAllowCustomDates
    : false;

  const [declaredNoteDeposit, setDeclaredNoteDeposit] = useState<any[]>([]);
  const [declaredCoinDeposit, setDeclaredCoinDeposit] = useState<any[]>([]);

  useEffect(() => {
    const arr: any[] = [];

    if (businessRulesMoneyOrdersSkus && skus) {
      for (
        let index = 0;
        index < businessRulesMoneyOrdersSkus.length;
        index++
      ) {
        const myBusinessSkuId = businessRulesMoneyOrdersSkus[index].skuId;
        const mySkuObject = skus.find((x) => x.id === myBusinessSkuId);

        if (mySkuObject) {
          let declarObj: any = {
            denominationId: mySkuObject?.denomination?.id,
            skuId: mySkuObject?.id,
            totalPrice: 0,
            currency: mySkuObject?.denomination?.currency,
            denominationQuantity: 0,
            quantity: 0,
            faceValue: mySkuObject?.denomination?.faceValue,
            unitPrice: mySkuObject?.price,
            total: 0,
            type: "ORDER_PRODUCT",
            currencyType: mySkuObject?.denomination?.type,
            status: "New",
            increments: businessRulesMoneyOrdersSkus[index].increments,
            description: mySkuObject?.description,
          };

          if (mySkuObject?.denomination?.type === "Coin" && mySkuObject) {
            declarObj.sku = mySkuObject.quantity;
          }
          arr.push(declarObj);
        }
      }
    }

    if (denominations && skus && businessRulesMoneyOrdersSkus) {
      const notes = arr
        .filter(
          (x) =>
            x.currencyType === "Note" && x.currency === selectedCurrency.value
        )
        .sort((a, b) => a.faceValue - b.faceValue);

      if (declaredNoteValues && declaredNoteValues.length > 0) {
        // Update arr with values from declaredNoteValues
        declaredNoteValues.forEach((declaredNote: any) => {
          const noteIndex = notes.findIndex(
            (note) => note.denominationId === declaredNote.denominationId
          );
          if (noteIndex !== -1) {
            // Update quantity, denominationQuantity, and totalPrice
            notes[noteIndex].quantity = declaredNote.quantity;
            notes[noteIndex].denominationQuantity =
              declaredNote.denominationQuantity;
            notes[noteIndex].totalPrice = declaredNote.totalPrice;
          }
        });
      }
      const coins = arr
        .filter(
          (x) =>
            x.currencyType === "Coin" && x.currency === selectedCurrency.value
        )
        .sort((a, b) => a.faceValue - b.faceValue);

      if (declaredCoinValues && declaredCoinValues.length > 0) {
        // Update arr with values from declaredCoinValues
        declaredCoinValues.forEach((declaredCoin: any) => {
          const coinIndex = coins.findIndex(
            (coin) => coin.denominationId === declaredCoin.denominationId
          );
          if (coinIndex !== -1) {
            // Update quantity, denominationQuantity, and totalPrice
            coins[coinIndex].quantity = declaredCoin.quantity;
            coins[coinIndex].denominationQuantity =
              declaredCoin.denominationQuantity;
            coins[coinIndex].totalPrice = declaredCoin.totalPrice;
          }
        });
      }

      setDeclaredNoteDeposit([...notes]);
      setDeclaredCoinDeposit([...coins]);
    }
  }, [skus, businessRulesMoneyOrdersSkus, selectedCurrency, denominations]);

  const noteIncrement = declaredNoteDeposit[0]?.increments || 1;
  const merchantCountry = selectedPickupLocation?.merchant?.country;
  const isPhysicalLocationNL = !!(
    selectedPickupLocation?.physicalLocation?.country === "NL"
  );

  const handleQuantity = (
    type: string,
    id: string,
    currencyType: string,
    input: number
  ) => {
    if (currencyType === "Note") {
      var newData = declaredNoteDeposit.map((el: any) => {
        if (el.denominationId === id) {
          if (type === "plus") {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title:
                  "Order management | Order Change - Select Denomination",
                Action:
                  "Plus Amount from Notes: " +
                  +el.faceValue +
                  " " +
                  el.currency,
              },
            });
            return Object.assign({}, el, {
              denominationQuantity: parseInt(el.denominationQuantity) + 1,
              quantity: el.denominationQuantity + 1,
              totalPrice:
                el.unitPrice * el.increments * (el.denominationQuantity + 1),
            });
          } else if (type === "input") {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title:
                  "Order management | Order Change - Select Denomination",
                Action:
                  "Input Amount from Notes: " +
                  el.faceValue +
                  " " +
                  el.currency,
              },
            });
            return Object.assign({}, el, {
              denominationQuantity: input,
              quantity: input,
              totalPrice: el.unitPrice * el.increments * input,
            });
          } else {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title:
                  "Order management | Order Change - Select Denomination",
                Action:
                  "Minus Amount from Notes: " +
                  el.faceValue +
                  " " +
                  el.currency,
              },
            });
            return Object.assign({}, el, {
              denominationQuantity: parseInt(el.denominationQuantity) - 1,
              quantity: parseInt(el.denominationQuantity) - 1,
              totalPrice:
                el.unitPrice * el.increments * (el.denominationQuantity - 1),
            });
          }
        }
        return el;
      });

      setDeclaredNoteDeposit(newData);
      dispatch(handleDeclaredNoteQuantity(newData));
      calculateTotal(newData, currencyType);
    } else {
      var newData = declaredCoinDeposit.map((el: any) => {
        if (el.denominationId === id) {
          if (type === "plus") {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title:
                  "Order management | Order Change - Select Denomination",
                Action:
                  "Plus Amount from Coins: " + el.faceValue + " " + el.currency,
              },
            });
            return Object.assign({}, el, {
              quantity: parseInt(el.denominationQuantity + 1),
              denominationQuantity: parseInt(el.denominationQuantity) + 1,
              totalPrice:
                el.unitPrice * (el.denominationQuantity + 1) * el.increments,
            });
          } else if (type === "input") {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title:
                  "Order management | Order Change - Select Denomination",
                Action:
                  "Input Amount from Coins: " +
                  el.faceValue +
                  " " +
                  el.currency,
              },
            });
            return Object.assign({}, el, {
              quantity: input,
              denominationQuantity: input,
              totalPrice: el.unitPrice * input * el.increments,
            });
          } else {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title:
                  "Order management | Order Change - Select Denomination",
                Action:
                  "Minus Amount from Coins: " +
                  el.faceValue +
                  " " +
                  el.currency,
              },
            });
            return Object.assign({}, el, {
              quantity: parseInt(el.denominationQuantity) - 1,
              denominationQuantity: parseInt(el.denominationQuantity) - 1,
              totalPrice:
                el.unitPrice * (el.denominationQuantity - 1) * el.increments,
            });
          }
        }
        return el;
      });

      setDeclaredCoinDeposit(newData);
      dispatch(handleDeclaredCoinQuantity(newData));
      calculateTotal(newData, currencyType);
    }
    if (isValid) {
      setIsvalid(false);
    }
  };

  const onUpdateTotal = (data: any) => {
    setTotal(data);
  };

  const onUpdateNoteTotal = (data: any) => {
    setNoteTotalState(data);
  };

  const onUpdateCoinTotal = (data: any) => {
    setCoinTotalState(data);
  };

  const calculateTotal = (newData: any, currencyType: any) => {
    let total = 0;

    if (currencyType === "Note") {
      newData.map((x: any) => {
        total += x.unitPrice * x.increments * x.denominationQuantity;
      });
      if (
        orderMaximumAmount &&
        totalOfCoins + total > Number(orderMaximumAmount)
      ) {
        setTotalIsvalid(true);
      } else {
        setTotalIsvalid(false);
      }
      dispatch(setNoteTotal(total));
      TagManager.dataLayer({
        dataLayer: {
          event: "Click_event",
          Page_title: "Order management | Order Change - Select Denomination",
          Action: "Total note amount = " + total,
        },
      });
      const noteuro = formatCurrency(
        total,
        selectedCurrency.value,
        merchantCountry
      );

      onUpdateNoteTotal(noteuro);
      dispatch(setNoteTotalWithCurrency(noteuro));
      dispatch(setGrandTotal(totalOfCoins + total));
      const euro = formatCurrency(
        totalOfCoins + total,
        selectedCurrency.value,
        merchantCountry
      );
      onUpdateTotal(euro);
      dispatch(setTotalWithCurrency(euro));
    }

    if (currencyType === "Coin") {
      newData.map((x: any) => {
        total += x.unitPrice * x.increments * x.denominationQuantity;
      });

      if (
        orderMaximumAmount &&
        total + totalOfNotes > Number(orderMaximumAmount)
      ) {
        setTotalIsvalid(true);
      } else {
        setTotalIsvalid(false);
      }
      dispatch(setCoinTotal(total));
      TagManager.dataLayer({
        dataLayer: {
          event: "Click_event",
          Page_title: "Order management | Order Change - Select Denomination",
          Action: "Total coin amount = " + total,
        },
      });

      const coineuro = formatCurrency(
        total,
        selectedCurrency.value,
        merchantCountry
      );
      onUpdateCoinTotal(coineuro);
      dispatch(setCoinTotalWithCurrency(coineuro));
      dispatch(setGrandTotal(totalOfNotes + total));
      const euro = formatCurrency(
        totalOfNotes + total,
        selectedCurrency.value,
        merchantCountry
      );
      onUpdateTotal(euro);
      dispatch(setTotalWithCurrency(euro));
    }
  };

  const onNextStep = () => {
    if (grandTotal !== 0 && grandTotal !== 0.0 && !isTotalValid) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Click_event",
          Page_title: "Order management | Order Change - Select Denomination",
          Action: "Total Change amount = " + grandTotal,
        },
      });
      nextStep();
    } else {
      if (!isTotalValid) {
        setIsvalid(true);
      }
    }
  };

  return (
    <>
      <Flex
        bg="white"
        sx={{
          p: 35,
          rowGap: 4,
          borderRadius: 8,
          flexDirection: "column",
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        data-testid="LocationList"
      >
        <Box sx={{ width: 455 }}>
          <Label
            sx={{
              fontSize: "subText",
              fontWeight: "body",
              lineHeight: "register_bag",
              fontFamily: "body",
              marginBottom: "8px",
            }}
          >
            {t("ManageOrders.merchant_name")}
          </Label>
          <Input
            disabled
            name="location_name"
            sx={{ height: "61px" }}
            defaultValue={
              selectedOrderMerchant
                ? selectedOrderMerchant.name
                : selectedPickupLocation?.merchant.name
            }
          />
        </Box>
        <Box sx={{ width: 455 }}>
          <Label
            sx={{
              fontSize: "subText",
              fontWeight: "body",
              lineHeight: "register_bag",
              fontFamily: "body",
              marginBottom: "8px",
            }}
          >
            {t("ManageOrders.delivery_location")}
          </Label>
          <Input
            sx={{ height: "61px" }}
            defaultValue={selectedPickupLocation?.name}
            name="location_name"
            disabled
          />
        </Box>

        {customerReference && (
          <Box sx={{ width: 455 }}>
            <Label
              sx={{
                fontSize: "subText",
                fontWeight: "body",
                lineHeight: "register_bag",
                fontFamily: "body",
                marginBottom: "8px",
              }}
            >
              {t("ManageOrders.customer_order_label")}
            </Label>
            <Input
              sx={{ height: "61px" }}
              defaultValue={customerReference}
              name="location_name"
              disabled
            />
          </Box>
        )}
      </Flex>
      <Flex
        bg="white"
        mt={32}
        sx={{
          p: 35,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
        data-testid="LocationList"
      >
        <Flex sx={{ borderBottom: "1px solid", borderColor: "borderColor" }}>
          {denomininationKeys.length > 0 &&
            denomininationKeys?.map((el, index) => (
              <Flex
                onClick={() => {
                  setCurrentDenominationKey(el.value);
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "Click_event",
                      Page_title:
                        "Order management | Order Change - Select Denomination",
                      Action: el.value,
                    },
                  });
                }}
                key={index}
                sx={{
                  paddingLeft: 50,
                  paddingRight: 50,
                  paddingBottom: 16,
                  fontSize: "body",
                  cursor: "pointer",
                  fontWeight:
                    currentDenominationKey === el.value ? "body" : "weight_400",
                  lineHeight: "label",
                  color:
                    currentDenominationKey === el.value
                      ? "primary"
                      : "shade_400",
                  borderBottom:
                    currentDenominationKey === el.value ? "2px solid" : "",
                  borderColor:
                    currentDenominationKey === el.value ? "royalBlue_500" : "",
                }}
              >
                {el.lable}
              </Flex>
            ))}
        </Flex>

        {currentDenominationKey === "notes" ? (
          <>
            <Flex
              my={isPhysicalLocationNL ? 16 : 20}
              sx={{
                width: isPhysicalLocationNL ? 500 : 375,
                justifyContent: "space-between",
              }}
            >
              <Text
                sx={{
                  color: "shade_800",
                }}
              >
                {t("ManageSealBags.denomination")}
              </Text>
              {isPhysicalLocationNL && (
                <Text
                  sx={{
                    color: "shade_800",
                  }}
                >
                  {t("ManageOrders.notes_per_bundle")}
                </Text>
              )}
              <Text
                sx={{
                  color: "shade_800",
                  width: 150,
                  textAlign: "center",
                }}
              >
                {isPhysicalLocationNL
                  ? t("ManageOrders.bundles")
                  : t("ManageOrders.no_notes")}
              </Text>
            </Flex>
            {declaredNoteDeposit &&
              declaredNoteDeposit.length > 0 &&
              declaredNoteDeposit?.map((el: any, index: any) => (
                <DenominationRow
                  key={index}
                  data={el}
                  handleQuantity={handleQuantity}
                />
              ))}
          </>
        ) : (
          ""
        )}
        {currentDenominationKey === "coins" ? (
          <>
            <Flex
              my={16}
              sx={{
                width: 500,
                justifyContent: "space-between",
              }}
            >
              <Text
                sx={{
                  color: "shade_800",
                }}
              >
                {t("ManageSealBags.denomination")}
              </Text>
              <Text
                sx={{
                  color: "shade_800",
                }}
              >
                {t("ManageOrders.coins_per_role")}
              </Text>
              <Text
                sx={{
                  color: "shade_800",
                  width: 150,
                  textAlign: "center",
                }}
              >
                {t("ManageOrders.rolls")}
              </Text>
            </Flex>
            {declaredCoinDeposit &&
              declaredCoinDeposit.length > 0 &&
              declaredCoinDeposit?.map((el: any, index: any) => (
                <DenominationRow
                  key={index}
                  data={el}
                  handleQuantity={handleQuantity}
                />
              ))}
          </>
        ) : (
          ""
        )}
        <Divider sx={{ width: "100%" }} color="divider" mt={20} />
        {isValid && (
          <Flex mt={20} color="red">
            {t("ManageSealBags.select_denomination_msg")}
          </Flex>
        )}
        {isTotalValid && orderMaximumAmount && (
          <Flex mt={20} color="red">
            {t("ManageOrders.grantTotal_msg", {
              orderLimit: formatCurrency(
                Number(orderMaximumAmount),
                selectedCurrency.value,
                merchantCountry
              ),
            })}
          </Flex>
        )}
        <Flex
          mt={30}
          mb={20}
          sx={{
            width: 375,
            justifyContent: "space-between",
            fontWeight: "weight_400",
            fontSize: "body",
          }}
        >
          <Text
            sx={{
              fontWeight: "weight_400",
              fontSize: "body",
            }}
          >
            {t("ManageOrders.notes")}
          </Text>
          <Text
            sx={{
              width: 150,
              fontWeight: "weight_400",
              fontSize: "body",
            }}
          >
            {totalOfNotesWithCurrency}
          </Text>
        </Flex>
        <Flex
          my={20}
          sx={{
            width: 375,
            justifyContent: "space-between",
          }}
        >
          <Text
            sx={{
              color: "shade_800",
              fontWeight: "weight_400",
              fontSize: "body",
            }}
          >
            {t("ManageOrders.coins")}
          </Text>
          <Text
            sx={{
              width: 150,
              fontWeight: "weight_400",
              fontSize: "body",
            }}
          >
            {totalOfCoinsWithCurrency}
          </Text>
        </Flex>
        <Flex
          my={20}
          sx={{
            width: 375,
            justifyContent: "space-between",
          }}
        >
          <Text
            sx={{
              color: "shade_800",
              fontWeight: "body",
              fontSize: "body",
              lineHeight: "label",
            }}
          >
            {t("ManageSealBags.grand_total")}
          </Text>
          <Text
            sx={{
              width: 150,
              fontSize: "heading_4",
              fontWeight: "heading",
            }}
          >
            {totalWithCurrency}
          </Text>
        </Flex>
      </Flex>
      <SelectTimeslotDetails />

      <Flex
        mt={32}
        sx={{
          flexDirection: "row-reverse",
        }}
      >
        <Button
          disabled={
            (orderMaximumAmount !== undefined &&
              grandTotal > Number(orderMaximumAmount)) ||
            (!selectedPickupDate &&
              selectedTypeOfChange === OrderChangeType.ON_DEMAND)
          }
          onClick={(e: any) => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title:
                  "Order management | Order Change - Select Denomination",
                Action: "Next",
              },
            });
            onNextStep();
          }}
          sx={{
            height: 49,
            float: "right",
            cursor: "pointer",
            borderRadius: "40px",
            pointerEvents: `auto`,
            width: ["100%", "169px"],
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            "&:disabled": {
              bg: "shade_200",
              color: "shade_400",
              cursor: "not-allowed",
            },
          }}
        >
          {t("ManageSealBags.next")}
        </Button>
        <Button
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title:
                  "Order management | Order Change - Select Denomination",
                Action: "Cancel",
              },
            });
            dispatch(resetState());
            dispatch(resetWebFilter());
            navigate(`/orders`);
          }}
          mr={20}
          variant="muted"
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            backgroundColor: "background",
            color: "royalBlue_500",
            border: "1px solid",
            borderColor: "royalBlue_500",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
      {deliveryDateResponseError && (
        <Alert
          data-testid="error-alert"
          sx={{
            position: "fixed",
            left: "50%",
            top: "13%",
            width: 496,
            boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.1)",
            borderRadius: 4,
            fontSize: "subText",
            fontWeight: "weight_400",
          }}
        >
          {t("ManageOrders.delivery_date_not_available")}

          <Close
            ml="auto"
            sx={{
              cursor: "pointer",
            }}
            mr={-2}
            onClick={() => dispatch(resetDeliveryDateError())}
            data-testid="error-alert-close-icon"
          />
        </Alert>
      )}
    </>
  );
}
