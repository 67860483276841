/** @jsxImportSource theme-ui */
import { Box, Flex, Heading, Divider, Input } from "theme-ui";
import { MinusIcon } from "@brinks/common/Icons/Components/minus";
import { PlusIcon } from "@brinks/common/Icons/Components/plus";
import { useState } from "react";
import { formatCurrency } from "@brinks/common/utils";
import { useAppSelector } from "@brinks/common/hooks/hooks";

interface Props {
  data: any;
  handleQuantity: (
    type: string,
    id: string,
    currencyType: string,
    input: any
  ) => void;
}
export default function DenominationRow({ data, handleQuantity }: Props) {
  const [isInput, showInputValue] = useState(false);
  const [inputValue, setInputValue] = useState(data.denominationQuantity);

  const { selectedPickupLocation } = useAppSelector(
    (state) => state.orderDetailsSlice
  );

  const merchantCountry = selectedPickupLocation.merchant?.country;
  const isPhysicalLocationNL = !!(
    selectedPickupLocation?.physicalLocation?.country === "NL"
  );

  return (
    <>
      <Divider color="divider" mt={10} />
      <Flex
        key={data?.skuId}
        my={16}
        sx={{
          width:
            data.currencyType === "Coin" ||
            (data.currencyType === "Note" && isPhysicalLocationNL)
              ? 500
              : 375,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: 82,
            heigh: 82,
            py: 10,
            color: `${data.denominationQuantity > 0 ? "white" : "shade_700"}`,
            bg: `${
              data.denominationQuantity > 0 ? "royalBlue_500" : "shade_50"
            }`,
            textAlign: "center",
            alignItems: "center",
            cursor: "pointer",

            borderRadius: 8,
          }}
        >
          {formatCurrency(data.faceValue, data.currency, merchantCountry)}
        </Box>
        <Flex
          sx={{
            p: 10,
            color: "shade_800",
          }}
        >
          {data.currencyType === "Coin"
            ? data.sku
            : data.currencyType === "Note" && isPhysicalLocationNL
            ? data.increments
            : ""}
        </Flex>

        <Flex
          sx={{
            alignItems: "center",
            width: 150,
            justifyContent: "space-between",
          }}
        >
          <Flex
            onClick={() => {
              if (data.denominationQuantity !== 0) {
                handleQuantity(
                  "minus",
                  data.denominationId,
                  data.currencyType,
                  null
                );
              }
            }}
            sx={{
              width: 32,
              height: 32,
              bg: "shade_50",
              justifyContent: "center",
              cursor: "pointer",
              alignItems: "center",
              p: 2,
              borderRadius: "50%",
            }}
          >
            <MinusIcon color={"royalBlue_850"} />
          </Flex>
          {isInput ? (
            <>
              <Input
                type={"number"}
                onBlur={() => {
                  showInputValue(false);
                  handleQuantity(
                    "input",
                    data.denominationId,
                    data.currencyType,
                    inputValue
                  );
                }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    showInputValue(false);
                    handleQuantity(
                      "input",
                      data.denominationId,
                      data.currencyType,
                      inputValue
                    );
                  }
                }}
                onChange={(e: any) => {
                  if (parseInt(e.target.value) <= 999) {
                    setInputValue(parseInt(e.target.value));
                  }
                }}
                style={{
                  width: "60px",
                  height: "45px",
                  border: "none",
                  fontSize: "24px",
                  padding: 10,
                }}
                value={inputValue}
              />
            </>
          ) : (
            <>
              <Heading
                onClick={() => {
                  showInputValue(true);
                }}
                sx={{
                  fontSize: "heading_4",
                }}
              >
                {isPhysicalLocationNL
                  ? data.denominationQuantity
                  : data.denominationQuantity * data.increments}
              </Heading>
            </>
          )}
          <Flex
            onClick={() => {
              if (data.denominationQuantity < 999) {
                handleQuantity(
                  "plus",
                  data.denominationId,
                  data.currencyType,
                  null
                );
              }
            }}
            sx={{
              width: 32,
              height: 32,
              p: 2,
              borderRadius: "50%",
              bg: "shade_50",
              justifyContent: "center",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <PlusIcon color={"royalBlue_850"} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
