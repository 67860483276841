import { useMemo, useCallback } from "react";
import { Card, Box, Flex, Button, Text } from "theme-ui";
import Loader from "../../../Shared/Loader";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { useEffect, useState } from "react";
import {
  getLocationBusinessRules,
  resetState,
  setPickupLocation,
} from "@brinks/common/reducers/orderDetailsSlice";
import {
  getRulesTransportOrders,
  resetWebFilter,
} from "@brinks/common/reducers/businessRulesTransportMoneyOrderSlice";
import { OrdersLocation } from "../OrdersLocation";
import { useTranslation } from "react-i18next";
import { ReactComponent as Warning } from "@brinks/common/Icons/warningmsg.svg";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { OrderPickupMerchantSelector } from "./OrderPickupMerchantSelector";

export default function SelectLocation({ nextStep }: any) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLocation, setLocationValidation] = useState(false);

  const { storeLocations, loading } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const { businessRulesTransportOrders, loadingBusinessRulesTransportOrders } =
    useAppSelector((state) => state.businessRulesTransportMoneyOrderSlice);

  const {
    locationBusinessRules,
    selectedPickupLocation,
    loadingOrder,
    selectedPickupOrderMerchant,
    locationListDisabled,
  } = useAppSelector((state) => state.orderDetailsSlice);

  const [location, setLocation] = useState(selectedPickupLocation);

  const onUpdateLocation = (data: any) => {
    setLocation(data);
    dispatch(setPickupLocation(data));
    setLocationValidation(true);
    // dispatch(      getLocationBusinessRules({ salesforceId: data.externalReferenceSf })    );
    dispatch(getRulesTransportOrders({ locationId: data.id }));
  };

  const checkNextStep = () => {
    if (!selectedPickupLocation) {
    } else {
      nextStep();
    }
  };

  const locations = useMemo(() => {
    if (!storeLocations) return [];

    const locations = storeLocations;

    return selectedPickupOrderMerchant
      ? locations.filter(
          (location) => location.merchantId === selectedPickupOrderMerchant.id
        )
      : locations;
  }, [storeLocations, selectedPickupOrderMerchant]);

  if (loadingOrder || loading || loadingBusinessRulesTransportOrders) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Card
        bg="white"
        data-testid="LocationDetails-container-card1"
        sx={{
          alignItems: "center",
          margin: "0 auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          padding: "32px 32px",
        }}
      >
        <Box
          sx={{
            width: "311px;",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Text
            data-testid="flex-location-text"
            sx={{
              fontWeight: "body",
              fontSize: "subText",
              lineHeight: "register_bag",
            }}
          >
            {t("ManageOrders.merchant")}
          </Text>
          <div style={{ width: "455px", marginTop: '8px' }}>
            <OrderPickupMerchantSelector value={selectedPickupOrderMerchant} />
          </div>
          <OrdersLocation
            disabled={locationListDisabled || locations.length === 1}
            lable={t("ManageOrders.pickup_location")}
            selectedLocation={selectedPickupLocation}
            locations={locations}
            onUpdateLocation={(data) => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title:
                    "Order management | Order Pickup - Select location",
                  Action: "Select location",
                },
              });
              onUpdateLocation(data);
            }}
          />
          {selectedPickupLocation &&
            businessRulesTransportOrders &&
            Object.keys(businessRulesTransportOrders).length === 0 && (
              <Flex mt={10} sx={{ width: 450 }} color="red">
                <>
                  <Warning />
                  <span style={{ marginLeft: "10px" }}>
                    {t("ManageOrders.empty_businessRule_msg")}
                  </span>
                </>
              </Flex>
            )}
          {businessRulesTransportOrders &&
          businessRulesTransportOrders.length != 0 &&
          businessRulesTransportOrders[0].transportAllow === false ? (
            <Flex mt={10} sx={{ width: 450 }} color="red">
              <>
                <Warning />
                <span style={{ marginLeft: "10px" }}>
                  {t("ManageOrders.select_pickup_location_msg")}
                </span>
                {TagManager.dataLayer({
                  dataLayer: {
                    event: "Click_event",
                    Page_title:
                      "Order management | Order Pickup - Select location",
                    Action: "Error message",
                  },
                })}
              </>
            </Flex>
          ) : (
            ""
          )}
        </Box>
      </Card>
      <Flex mt={20} sx={{ flexDirection: "row-reverse" }}>
        <Button
          disabled={
            (businessRulesTransportOrders && businessRulesTransportOrders.length == 0) ||
            !selectedPickupLocation
          }
          onClick={(e: any) => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Order management | Order Pickup - Select location",
                Action: "Next",
              },
            });
            checkNextStep();
          }}
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            "&:disabled": {
              bg: "shade_200",
              color: "shade_400",
              cursor: "not-allowed",
            },
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            float: "right",
          }}
        >
          {t("ManageSealBags.next")}
        </Button>
        <Button
          onClick={() => {
            navigate(`/orders`);
            dispatch(resetState());
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Order management | Order Pickup - Select location",
                Action: "Cancel",
              },
            });
          }}
          mr={20}
          variant="muted"
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            backgroundColor: "background",
            color: "royalBlue_500",
            border: "1px solid",
            borderColor: "royalBlue_500",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
    </>
  );
}
