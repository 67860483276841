import { Box } from "theme-ui";

interface Props {
  children: React.ReactNode;
}

export default function Label({ children, ...props }: Props) {
  return (
    <Box
      data-testid="label-wrapper"
      {...props}
      sx={{
        fontSize: "body",
        fontWeight: "body",
        lineHeight: "label",
        color: "black",
        fontFamily:"body"
      }}
    >
      {children}
    </Box>
  );
}
