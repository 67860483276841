import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { Box, Flex, Label, Button, Input, Radio } from "theme-ui";
import { resetWebFilter } from "@brinks/common/reducers/storeLocationSlice";
import {
  setSubject,
  resetState,
} from "@brinks/common/reducers/supportCaseSlice";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";

export default function SelectSubject({ nextStep }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selectedSubjet, templates } = useAppSelector(
    (state) => state.supportCaseWithMerchantSlice
  );

  const { selectedLocation } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const { currentMerchant } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );

  return (
    <>
      <Flex
        bg="white"
        sx={{
          p: 35,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
        data-testid="SupportCaseLocation"
      >
        <Flex sx={{ gap: 3, width: 455, flexDirection: "column" }}>
          <Box>
            <Label
              sx={{
                fontSize: "subText",
                fontWeight: "body",
                lineHeight: "register_bag",
                fontFamily: "body",
                marginBottom: "8px",
              }}
            >
              {t("ManageCase.merchant_name")}
            </Label>
            <Input
              sx={{ height: "61px" }}
              defaultValue={currentMerchant ? currentMerchant.name : ""}
              name="location_name"
              disabled
            />
          </Box>
          <Box>
            <Label
              sx={{
                fontSize: "subText",
                fontWeight: "body",
                lineHeight: "register_bag",
                fontFamily: "body",
                marginBottom: "8px",
              }}
            >
              {t("support.case_for_location")}
            </Label>
            <Input
              sx={{ height: "61px" }}
              defaultValue={selectedLocation ? selectedLocation[0].name : ""}
              name="location_name"
              disabled
            />
          </Box>
        </Flex>
      </Flex>
      <Flex
        bg="white"
        mt={32}
        sx={{
          p: 35,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
        data-testid="SupportCaseLocation"
      >
        <Box sx={{ width: 455 }}>
          <Label
            sx={{
              fontSize: "subText",
              fontWeight: "body",
              lineHeight: "register_bag",
              fontFamily: "body",
              marginBottom: "8px",
            }}
          >
            {t("support.subject")}
          </Label>
          {templates?.map((item, index) => (
            <Label
              key={index}
              data-testid="advanceFilterTransactionlabel-all"
              mt={3}
              sx={{
                justifyContent: "left",
                fontSize: "body",
                fontWeight: "weight_400",
                lineHeight: "body",
                color: "shade_800",
              }}
            >
              <Radio
                data-testid="advanceFilter-Transaction-radio"
                name="dark-mode"
                onChange={() => {
                  dispatch(setSubject(item.subject));
                }}
                checked={selectedSubjet == item.subject}
              />
              {item.subject}
            </Label>
          ))}
        </Box>
      </Flex>
      <Flex
        mt={32}
        sx={{
          flexDirection: "row-reverse",
        }}
      >
        <Button
          disabled={!selectedSubjet}
          onClick={(e: any) => {
            nextStep();
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Support | Create a case - Select Subject",
                Action: "Next",
              },
            });
          }}
          sx={{
            "&:disabled": {
              bg: "shade_200",
              color: "shade_400",
              cursor: "not-allowed",
            },
            width: 164,
            height: 48,
            borderRadius: 40,
            px: 12,
            py: 16,
          }}
        >
          {t("ManageSealBags.next")}
        </Button>
        <Button
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Support | Create a case - Select Subject",
                Action: "Cancel",
              },
            });
            dispatch(resetState());
            dispatch(resetWebFilter());
            navigate(`/support`);
          }}
          mr={20}
          variant="muted"
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            backgroundColor: "background",
            color: "royalBlue_500",
            border: "1px solid",
            borderColor: "royalBlue_500",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
    </>
  );
}
