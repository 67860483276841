// -----------------------------------------------------------------
// EXTERNAL IMPORTS
// -----------------------------------------------------------------
import TagManager from "react-gtm-module";

import { Box, Heading } from "theme-ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// -----------------------------------------------------------------
// INTERNAL IMPORTS
// -----------------------------------------------------------------
import Loader from "../../../Shared/Loader";
import SelectSubject from "./SelectSubject";
import AddDescription from "./AddDescription";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import ReviewNewCaseDetails from "./ReviewNewCaseDetails";

import { SelectLocation } from "./SelectLocation";
import { BackIcon } from "@brinks/common/Icons/Components/back";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { geCasesWithMerchant } from "@brinks/common/reducers/supportCaseWithMerchantSlice";
import { useOktaAuth } from "@okta/okta-react";
import { getCurrentUserForApi } from "@brinks/common/utils";
import { getMerchants } from "@brinks/common/reducers/merchantsDetailSlice";
import { getStoreLocations } from "@brinks/common/reducers/storeLocationSlice";

export const CreateCase = () => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(geCasesWithMerchant({}));
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_title: "Create a Case | Start screen"
      }
    })
  }, []);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, updateStep] = useState(1);

   // TESTE USER
   const { oktaAuth } = useOktaAuth();
   const token: any = oktaAuth.getAccessToken();
   const userRole = getCurrentUserForApi(token);
   const userType = userRole;

  const nextStep = () => {
    updateStep(step + 1);
  };

  const { loading } = useAppSelector((state) => state.storeLocationSlice);
  const { loadingMerchants } = useAppSelector((state) => state.merchantsDetailSlice);

  useEffect(() => {
    dispatch(getMerchants({userType: userType}));
    dispatch(getStoreLocations({userType: userType}));
  }, []);

  const previousStep = () => {
    if (step === 1) {
      navigate(`/support`);
    } else {
      updateStep(step - 1);
    }
  };

  const getCurrentStep = () => {
    switch (step) {
      case 1:
        return <SelectLocation nextStep={nextStep} />;
      case 2:
        return (
          <SelectSubject nextStep={nextStep} />
        );
      case 3:
        return <AddDescription nextStep={nextStep} />;
      case 4:
        return <ReviewNewCaseDetails updateStep={updateStep}/>;
      default:
      // do nothing
    }
  };
  if (loading || loadingMerchants) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }
  return (
    <>
      <PageHeader>
        <Heading>
          <button
            onClick={(e: any) => {
              previousStep();

              TagManager.dataLayer({
                dataLayer: {
                  dataLayer: {
                    Action: "Previous",
                    event: "Click_event",
                    Page_title: "Order management | Order Change - Select location",
                  }
                }
              });
            }}
            style={{ marginRight: "20px", background: "transparent", border: "none", cursor: "pointer" }}
          >
            <BackIcon color={"black"} />
          </button>
          {step === 4 ? t("support.review_case") : t("support.create_a_case")}
          {step === 4 &&
            TagManager.dataLayer({
              dataLayer: {
                event: "page_view",
                Page_title: "Order management | Order Change - Review Order",
                Action: "page_view"
              }
            })
          }
        </Heading>
      </PageHeader>
      <Wrapper>{getCurrentStep()}</Wrapper>
    </>
  );
}
