import { Box, Flex } from "theme-ui";
import "./index.scss";

interface Props {
  title: string;
  color: string;
  isActive: Boolean;
  handleClick: () => void;
}

export default function ButtonPills({
  title,
  color,
  isActive,
  handleClick,
}: Props) {
  let pillsColor: string = "";

  if (title === "Cash") {
    pillsColor = "cash_transaction";
  }
  if (title === "Cards") {
    pillsColor = "cards_transaction";
  }
  if (title === "Clicks") {
    pillsColor = "clicks_transaction";
  }

  return (
    <Flex
      onClick={handleClick}
      sx={{
        justifyContent: 'space-evenly',
        alignItems: 'center',
        cursor: 'pointer',
        background: `${isActive ? '#e2e5e9' : 'white'}`,
        borderRadius: 32,
        textAlign: 'center',
        minWidth: 70,
        p: 11
      }}>
      {title !== 'All' &&
        <Box
          sx={{
            backgroundColor: `${pillsColor}`,
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            mr: 10
          }}
          data-testid="btn-pills-color"
        ></Box>
      }
      {title}
    </Flex>


  );
}
