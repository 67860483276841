import { Card, Box, Text, Flex, Divider, Label } from "theme-ui";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@brinks/common/hooks/hooks";
import { getCurrentUser } from "../../../utils/secure-storage-utils";

export const DepositeDetails = () => {
  const { bagNumber, selectedLocation, selectedCashDevice } = useAppSelector(
    (state) => state.registerSealbagSlice
  );

  const { currentMerchant } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );

  const { t } = useTranslation();
  const user = getCurrentUser();

  return (
    <Box sx={{ width: 1109 }}>
      <Card
        bg="white"
        data-testid="LocationDetails-container-card2"
        sx={{
          alignItems: "center",
          margin: "0 auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
          padding: "32px 32px",
          // height:341,
        }}
      >
        <Box sx={{ width: 305 }}>
          <Flex
            sx={{
              fontSize: "medium",
              fontFamily: "heading",
              fontWeight: "bold",
            }}
          >
            {t("ManageSealBags.deposite_details")}
          </Flex>
          <Divider mt={16} mb={16} color="divider" />
          <Flex
            data-testid="LocationDetails-field1"
            sx={{
              flexDirection: "column",
            }}
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="LocationDetails-name"
            >
              {t("ManageSealBags.merchant_label")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="LocationDetails-name-value"
            >
              {currentMerchant?.name}
            </Text>
          </Flex>
          <Flex
            data-testid="LocationDetails-field1"
            sx={{
              flexDirection: "column",
            }}
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="LocationDetails-name"
            >
              {t("ManageSealBags.location")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="LocationDetails-name-value"
            >
              {selectedLocation.name}
            </Text>
          </Flex>
          <Flex
            data-testid="LocationDetails-cash-device-field1"
            mt={31}
            sx={{
              flexDirection: "column",
            }}
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="LocationDetails-cash-device-name"
            >
              {t("ManageSealBags.cash_device_lable")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="LocationDetails-cash-device-name-value"
            >
              {selectedCashDevice?.deviceName}
            </Text>
          </Flex>
          <Flex
            mt={31}
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            data-testid="LocationDetails-email"
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="LocationDetails-email-label"
            >
              {t("ManageSealBags.bag_number")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="LocationDetails-email-value"
            >
              {bagNumber}
            </Text>
          </Flex>
          <Flex
            mt={31}
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            data-testid="LocationDetails-phone"
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="LocationDetails-phone-label"
            >
              {t("ManageSealBags.prepared_by")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="LocationDetails-phone-value"
            >
              {user?.profile?.firstName} {user?.profile?.lastName}
            </Text>
          </Flex>
        </Box>
      </Card>
    </Box>
  );
};
export default DepositeDetails;
