/** @jsxImportSource theme-ui */
import { Box } from "theme-ui";

import { ReactNode } from "react";
interface Props {
  children: ReactNode;
}
export default function Wrapper({ children }: Props) {
  return (
    <>
      <Box
        data-testid="wrapper-main"
        sx={{
          my: 124,
          mx: 24,
        }}
      >
        {children}
      </Box>
    </>
  );
}
