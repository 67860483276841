import { Flex, Box, Text } from "theme-ui";
import { useTranslation } from "react-i18next";
import avatar_placeholder from "@brinks/common/Images/avatar_placeholder.png";
import { UserRoleLabel } from "../shared/components/UserRoleLabel";

export default function UserAccountDetailsAvatar(userData: any) {
  const { t } = useTranslation();

  let dotColor;
  let statusText;

  let userTypeColor;
  let userTypeBackgroundColor;
  let userTypeText;

  if (userData)
    switch (userData.type) {
      case "STANDARD_USER":
        userTypeColor = "primary";
        userTypeBackgroundColor = "royalBlue_150";
        userTypeText = t("UserList.standard_user");
        break;
      case "SUPER_USER":
        userTypeColor = "secondary";
        userTypeBackgroundColor = "secondary_150";
        userTypeText = t("UserList.super_user");
        break;
      default:
        userTypeColor = "primary";
        userTypeBackgroundColor = "royalBlue_150";
        userTypeText = t("UserList.standard_user");
    }

  if (userData)
    switch (userData.status) {
      case "PROVISIONED":
        dotColor = "primary";
        statusText = t("UserStatusSelect.provisioned");
        break;
      case "ACTIVE":
        dotColor = "secondary_700";
        statusText = t("UserStatusSelect.active");
        break;
      case "RECOVERY":
        dotColor = "raspberry_300";
        statusText = t("UserStatusSelect.recovery");
        break;
      case "SUSPENDED":
        dotColor = "raspberry_500";
        statusText = t("UserStatusSelect.suspended");
        break;
      case "LOCKED_OUT":
        dotColor = "raspberry_500";
        statusText = t("UserStatusSelect.locked_out");
        break;
      case "PASSWORD_EXPIRED":
        dotColor = "raspberry_300";
        statusText = t("UserStatusSelect.password_expired");
        break;
      case "DEPROVISIONED":
        dotColor = "raspberry_500";
        statusText = t("UserStatusSelect.deprovisioned");
        break;
      case "UNKNOWN":
        dotColor = "raspberry_500";
        statusText = t("UserStatusSelect.unknown");
        break;
      default:
        dotColor = "raspberry_500";
        statusText = t("UserStatusSelect.unknown");
    }

  return (
    <>
      <Flex sx={{ width: "100%" }} mb={60} pt={20}>
        <Box mr={22}>
          <img src={avatar_placeholder} width="89px" alt={avatar_placeholder} />
        </Box>
        <Flex
          sx={{
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          <Text sx={{ color: "black" }}>
            {userData && userData.firstName} {userData && userData.lastName}
          </Text>
          <Text
            sx={{
              color: "shade_400",
              fontWeight: "weight_400",
              mt: "8px",
            }}
          >
            {userData && userData.email}
          </Text>
          <Text
            sx={{
              color: "shade_400",
              fontWeight: "weight_400",
              mt: "8px",
            }}
          >
            {userData && userData.phone? userData.phone : userData.primaryPhone} 
          </Text>
          <Flex mt={15} sx={{ alignItems: "center" }}>
            <UserRoleLabel type={userData.type} userType={userTypeText} />
            <Text
              sx={{
                fontSize: "48px",
                color: dotColor,
                ml: 3,
              }}
            >
              &#183;
            </Text>
            <Text
              sx={{
                fontWeight: "weight_400",
                color: "shade_500",
                ml: 2,
              }}
            >
              {statusText}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
