import { Flex, Text } from "theme-ui";
import { ErrorIcon } from "@brinks/common/Icons/Components/error";

interface Props {
  message: string;
}
export default function ErroroLabel({ message }: Props) {
  return (
    <Flex pt={2} data-testid="activation-card-error-flex">
      <ErrorIcon color={'raspberry_500'} data-testid="activation-card-error-icon" />
      <Text
        data-testid="activation-card-invalid-code"
        pl={2}
        color="raspberry_500"
        sx={{
          fontSize: "subText",
          fontWeight: "weight_400",
        }}
      >
        {message}
      </Text>
    </Flex>
  );
}
