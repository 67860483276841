// @ts-ignore
import Modal from "react-modal";
import { Box, Flex } from "theme-ui";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "@brinks/common/Icons/Components/close_modal";
import TagManager from "react-gtm-module";
import { ReactComponent as SuccessIcon } from "@brinks/common/Icons/web_deposit.svg";
import { getOrders } from "@brinks/common/reducers/orderDetailsSlice";
import { useAppDispatch } from "@brinks/common/hooks/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { getCurrentUserForApi } from "@brinks/common/utils";

const bg = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "420",
    height: "322",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    backgroundColor: "white",
    width: "45rem",
    maxWidth: "23%",
    padding: "30px",
    maxHeight: "50%",
    overflowY: "auto",
    position: "relative",
    borderRadius: "16px",
  },
};

export default function SuccessModal({
  isSuccessModalOpen,
  toggleSuccessModal,
}: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { oktaAuth } = useOktaAuth();
  const token: any = oktaAuth.getAccessToken();
  const userRole = getCurrentUserForApi(token);
  const userType = userRole;

  return (
    <Modal
      backdropOpacity={0.5}
      closeTimeoutMS={500}
      isOpen={isSuccessModalOpen}
      style={bg}
      transparent={true}
      center
    >
      <Box>
        <Flex sx={{ flexDirection: "row-reverse" }}>
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Overview orders | Overview",
                  Action: "Cancel",
                },
              });
              dispatch(getOrders({ userType: userType }));
              toggleSuccessModal(!isSuccessModalOpen);
            }}
          >
            <CloseIcon
              color={"shade_300"}
              style={{
                cursor: "pointer",
              }}
            />
          </Box>
        </Flex>
        <Flex
          mt={20}
          mb={20}
          sx={{
            display: "flex",
            alignItems: "center",

            flexDirection: "column-reverse",
          }}
        >
          <Flex
            sx={{
              border: "4px solid",
              borderColor: "royalBlue_500",
              borderRadius: "50%",
              padding: 13,
            }}
          >
            <SuccessIcon />
          </Flex>
        </Flex>
        <Flex
          pt={10}
          sx={{
            fontFamily: "heading",
            fontWeight: "bold",
            fontSize: "heading_4",
            alignContent: "center",
            lineHeight: "register_bag",
            flexDirection: "column-reverse",
            textAlign: "center",
          }}
        >
          {t("ManageOrders.success")}
        </Flex>
        <Flex
          pt={10}
          mb={30}
          sx={{
            fontFamily: "body",
            fontWeight: "normal",
            fontSize: "body",
            lineHeight: "label",
            color: "beaver",
            flexDirection: "column-reverse",
            textAlign: "center",
          }}
        >
          <>
            {t("ManageOrders.order_canceled")}
            {TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Overview orders | Overview | Cancel order",
                Action: "Cancel order - Success",
              },
            })}
          </>
        </Flex>
      </Box>
    </Modal>
  );
}
