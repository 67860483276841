import moment from "moment-timezone";
import {
  OpeningHour,
  Transaction,
  Declared,
  Denomination,
  Sku,
} from "../api/Api";
import {
  DashboardSliceState,
  TransactionMap,
} from "../reducers/dashboardSlice";
import { StorageUtil } from "./storage-utils";
import { UserType } from "@brinks/common/constants/users";
import { OktaAuth } from "@okta/okta-auth-js";
import config from "../config";

export const weekdays = new Array(
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
);

export function getDateByDays(backDays: number): Date {
  const utcDate = getCurrentDate();
  const pastDate = utcDate.getDate() - backDays + 1;
  utcDate.setDate(pastDate);
  return utcDate;
}

export function getBrowserStartOfDayDatTime(date: Date): string {
  const momentDate = moment(date);
  return momentDate.startOf("day").toDate().toISOString();
}

export function getBrowserEndOfDayDatTime(date: Date): string {
  const momentDate = moment(date);
  return momentDate.endOf("day").toDate().toISOString();
}

export function getFutureDateByDays(days: number): Date {
  const utcDate = getCurrentDate();
  const pastDate = utcDate.getDate() + days + 1;
  utcDate.setDate(pastDate);
  return utcDate;
}

export function getCurrentDate() {
  return new Date();
}

export function getNextDate() {
  let date = new Date();

  date.setDate(date.getDate() + 1);

  return date;
}

export function groupByDate(objectArray: Transaction[]) {
  objectArray.sort((a: Transaction, b: Transaction) =>
    a.dateTime > b.dateTime ? -1 : 1
  );
  return objectArray.reduce(
    (transactionMap: TransactionMap, transaction: Transaction) => {
      const key = moment(transaction.dateTime).format("D MMMM  YYYY");
      if (!transactionMap[key]) {
        transactionMap[key] = [];
      }
      transactionMap[key].push(transaction);
      return transactionMap;
    },
    {}
  );
}

export function groupByDay(openingHours: OpeningHour[]) {
  return openingHours.reduce((acc: any, obj: OpeningHour) => {
    const key = obj.dayOfWeek;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
  weekdays.map((day) => day);
}

export const range = (start: number, end: number, step: number) => {
  return Array.from(
    Array.from(Array(Math.ceil((end - start) / step)).keys()),
    (x) => start + x * step
  );
};

export function getDateTimeFormat(dateTime: string) {
  return moment(dateTime).format("DD MMM yyyy hh:mm A");
}

export function getNameInitials(displayName: string) {
  return displayName
    .match(/(\b\S)?/g)
    ?.join("")
    ?.toUpperCase();
}

export function getDisplayName(
  name: string,
  createdByOds: boolean,
  locationName: string
) {
  return createdByOds ? locationName : name;
}

export function getBackgroundColor(name: string) {
  if (!name) {
    return `primary`;
  }
  let stringUniqueHash = Array.from(name).reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}

export function convertToDateTime(date: string) {
  const today = new Date();
  const hours = today.getHours();
  const mins = today.getMinutes();
  const secs = today.getSeconds();

  const newDate = new Date(date).setHours(hours, mins, secs);

  return new Date(newDate);
}

export const getAccessToken = async () => {
  const access_token = await StorageUtil.getItem(`access_token`);
  return access_token;
};

export function getDateFormat(date: Date) {
  return moment(date).format("DD MMM yyyy");
}

export function getHourTime(date: moment.MomentInput) {
  return moment(date, ["h:mm A"]).format("HH:mm");
}

export function formatDeclaredValues(denominations: any[]) {
  const arr: any[] = [];
  const aux = [...denominations];

  aux
    .sort((a: any, b: any) => (a.faceValue < b.faceValue ? -1 : 1))
    .map((d: any) => {
      if (d.type !== "Coin") {
        let declarObj: any = {
          denominationId: "",
          declaredValue: 0,
          currency: "",
          denominationQuantity: 0,
          declaredDateTime: 0,
          faceValue: "",
        };
        declarObj.denominationId = d.id;
        declarObj.declaredValue = 0;
        declarObj.faceValue = d.faceValue;
        declarObj.currency = d.currency;
        declarObj.declaredDateTime = "";
        declarObj.denominationQuantity = 0;
        arr.push(declarObj);
      }
    });

  return arr;
}

export function formatDenominationValues(denominations: any, type: any) {
  const arr: any[] = [];
  denominations.map((d: any) => {
    if (d.type === type && d.sku !== null) {
      let declarObj: any = {
        skuId: "",
        totalPrice: 0,
        currency: "",
        denominationQuantity: 0,
        quantity: 0,
        faceValue: "",
        unitPrice: 0,
        total: 0,
        type: "",
        currencyType: type,
        status: "",
      };
      declarObj.skuId = d.skuId;
      declarObj.totalPrice = 0;
      declarObj.faceValue = d.faceValue;
      declarObj.currency = d.currency;
      declarObj.denominationQuantity = 0;
      declarObj.quantity = 0;
      //  declarObj.unitPrice = parseInt(d.sku.price);
      declarObj.total = 0;
      declarObj.status = "New";
      declarObj.type = "ORDER_PRODUCT";
      //  declarObj.description = d.sku.description;
      if (type == "Coin" && d.sku !== null) {
        // declarObj.sku = d.sku.quantity;
      }
      arr.push(declarObj);
    }
  });
  return arr;
}

export function formatDenominationValuesOnSealBags(
  denominations: Denomination[],
  skus: Sku[]
) {
  const arr: any[] = [];
  denominations.map((d: any) => {
    let filteredSkus: Sku[] = skus.filter((sku: Sku) =>
      denominations.find((x) => x.id == sku.id)
    );

    if (d.sku !== null) {
      let declarObj: any = {
        skuId: "",
        totalPrice: 0,
        currency: "",
        denominationQuantity: 0,
        quantity: 0,
        faceValue: "",
        unitPrice: 0,
        total: 0,
        type: "",
        currencyType: "",
        status: "",
      };
      declarObj.skuId = d.skuId;
      declarObj.totalPrice = 0;
      declarObj.faceValue = d.faceValue;
      declarObj.currency = d.currency;
      declarObj.denominationQuantity = 0;
      declarObj.quantity = 0;
      declarObj.unitPrice = parseInt(d.sku.price);
      declarObj.total = 0;
      declarObj.status = "New";
      declarObj.type = "ORDER_PRODUCT";
      declarObj.description = d.sku.description;
      if (d.type == "Coin" && d.sku !== null) {
        declarObj.sku = d.sku.quantity;
      }
      arr.push(declarObj);
    }
  });
  return arr;
}

export function kFormatter(num: number) {
  const value = Math.sign(num) * Math.abs(num);
  return Math.abs(num) > 999
    ? (value / 1000).toFixed(1) + "k"
    : value < 99.99
    ? value
    : Math.ceil(value);
}

export function getFilterParams(data: DashboardSliceState) {
  let transtype: string = "";

  if (data.selectedPaymentTypeFilter.toUpperCase() == "ALL") {
    transtype = "cards,clicks,cash";
  } else {
    transtype = data.selectedPaymentTypeFilter;
  }

  let selectedPaymentMethodArr: Array<String> = [];
  const selectedPaymentIndex = data.selectedPaymentMethod.findIndex(
    (item) => item == "all"
  );
  if (selectedPaymentIndex === -1) {
    selectedPaymentMethodArr = data.selectedPaymentMethod.map((item) =>
      item.toLowerCase()
    );
  }

  const query = {
    userType: data.userType,
    transactionType: transtype,
    startDate: `${getBrowserStartOfDayDatTime(data.selectedPeriodStartDate)}`,
    endDate: `${getBrowserEndOfDayDatTime(data.selectedPeriodEndDate)}`,
    ...(data.selectedAmountType &&
      data.selectedAmountType !== "all" && {
        creditDebitIndicator: data.selectedAmountType.toUpperCase(),
      }),
    ...(data.selectedMinAmount && { minAmount: data.selectedMinAmount }),
    ...(data.selectedMaxAmount && { maxAmount: data.selectedMaxAmount }),
    ...(selectedPaymentMethodArr.length > 0 && {
      paymentMethod: selectedPaymentMethodArr.join(","),
    }),
    ...(data.selectedLocation.length > 0 && {
      locationId: data.selectedLocation.join(","),
    }),
  };

  return query;
}

export function capitalizeFirstLetter(string: string): string {
  const stringParts = string.split(" ");
  const capitalizeStringParts: Array<String> = [];
  stringParts.forEach((part) => {
    capitalizeStringParts.push(part.charAt(0).toUpperCase() + part.slice(1));
  });
  return capitalizeStringParts.join(" ");
}

export function getCurrentUser(token: string) {
  const base64Url = token?.split(".")[1];
  const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload =
    base64 &&
    decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  let userType = "";
  const user = jsonPayload && JSON.parse(jsonPayload);
  if (user?.bacUserRole === "admin") {
    userType = UserType.SUPER_USER;
  } else {
    userType = UserType.STANDARD_USER;
  }
  return userType;
}

export function getCurrentUserForApi(token: string) {
  const base64Url = token?.split(".")[1];
  const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload =
    base64 &&
    decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  let userType = "";
  const user = jsonPayload && JSON.parse(jsonPayload);
  if (user?.bacUserRole === "admin") {
    userType = "superUser";
  } else {
    userType = "standardUser";
  }
  return userType;
}

export function getUserDetails(token: string) {
  const base64Url = token?.split(".")[1];
  const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload =
    base64 &&
    decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  let userType = "";
  const user = jsonPayload && JSON.parse(jsonPayload);

  return user;
}

export function getDeviceId() {
  let deviceId = localStorage.getItem("deviceId");
  if (deviceId) {
    return deviceId;
  }
  deviceId = generateNewId();
  localStorage.setItem("deviceId", deviceId);
  return deviceId;
}

export function generateNewId() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 13; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getFilteredUsersLocations(storeLocations: any, data: any) {
  const userLocations: any = [];
  data.locations?.map((id: any) => {
    storeLocations &&
      storeLocations?.forEach((el: any) => {
        if (el.id === id) {
          userLocations.push(el);
        }
      });
  });
  return userLocations;
}

export const oktaAuthGlobal: any = new OktaAuth(config.oidc);

export const timer = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const getBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve) => {
    let baseURL = null;
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const convertHTMLEntity = (text: string) => {
  const span = document.createElement("span");
  return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
    span.innerHTML = entity;
    return span.innerText;
  });
};

export function formatCurrency(
  amount: number | null = 0,
  currencyCode?: string | null,
  locale?: string | null
) {
  const baseCurrencyCode = typeof currencyCode === 'string' && currencyCode.length ? currencyCode : "EUR";
  const baseLocale = typeof locale === 'string' && locale.length ? locale : "nl-NL";
  const amountToDisplay = typeof amount === 'number' &&  amount >= 0 ? amount : 0;

  const numberFormatter = new Intl.NumberFormat(baseLocale, {
    style: "currency",
    currency: baseCurrencyCode,
  });

  const formattedAmount = numberFormatter.format(amountToDisplay);

  return formattedAmount;
}

export function getTotalAmount(
  transactions: [],
  currencyCode: string,
  locale: any
) {
  const totalGrossAmount = transactions.reduce(
    (accumulator: number, transaction: Transaction) =>
      accumulator + transaction.grossAmount,
    0
  );
  const formattedAmount = formatCurrency(
    totalGrossAmount,
    currencyCode,
    locale
  );
  return formattedAmount;
}
