// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { Box, Flex, Heading } from "theme-ui";
import { useTranslation } from "react-i18next";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import ErroroLabel from "./ErrorLabel";
import GlobalSearch from "./GlobalSearch";
import Loader from "../../../Shared/Loader";

import { useState } from "react";
import { UserType } from "@brinks/common/constants/users";
import { AddUserMerchantSelector } from "./AddUserMerchantSelector";
import { MerchantWithDependentsAndLocations } from "@brinks/common/api/Api";
import { RowMerchantElementToLocation } from "./RowMerchantElementToLocation";

interface LocationsProps {
  state: any;
  locations: any;
  loading: boolean;
  searchLocation: (search: string) => void;
  merchants: MerchantWithDependentsAndLocations[];
  searchMerchant: (merchantId: null | string) => void;
  handleLocations: (id: string, value: boolean) => void;
}

export const Locations = ({
  state,
  loading,
  locations,
  merchants,
  searchLocation,
  searchMerchant,
  handleLocations,
}: LocationsProps) => {
  
  const { t } = useTranslation();
  const [search, setSearch] = useState('')

  const createMarkup = (html: any) => {
    return { __html: html };
  };
  
  const handleSearch = (search: string) => {
    setSearch(search)
    searchLocation(search);
  };

  return (
    <>
      <Flex mt={35} sx={{ justifyContent: "space-between", alignItems: "center", }}>
        <Flex
          sx={{
            gap: 2,
            flex: 1,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Heading
              sx={{
                fontSize: "medium",
              }}
            >
              {t("AddNewUser.location")}
            </Heading>
            <Box
              ml={3}
              px={2}
              py={1}
              sx={{
                width: 58,
                bg: "borderColor",
                color: "shade_500",
                borderRadius: 4,
                textAlign: "center",
              }}
            >
              {locations && locations.length}
            </Box>
          </Flex>
          <Flex
            sx={{
              gap: 2,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <GlobalSearch placeholder="Search locations" searchLocation={(search) => handleSearch(search)} />
            <AddUserMerchantSelector merchants={merchants} onChange={(merchantId) => searchMerchant(merchantId)} />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        sx={{
          px: 36,
          pt: 26,
          pb: 21,
          mt: 35,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottom: "2px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "rgba(240, 242, 244, 0.28)",
        }}
        data-testid="location"
      >
        <Flex sx={{ width: "100%" }}>
          <Box color={"shade_400"} sx={{ flex: 2 }}>
            Location Names
          </Box>
          <Box color={"shade_400"} sx={{ flex: 2 }}>
            {t("UserList.merchant")}
          </Box>
          <Box color={"shade_400"} sx={{ flex: 3 }}>
            Address
          </Box>
          <Box color={"shade_400"} sx={{ flex: 1, textAlign: "right" }}>
            Assign
          </Box>
        </Flex>
      </Flex>
      <Box
        sx={{
          height: "635px",
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <Box>
            <Loader />
          </Box>
        ) : (
          locations &&
          locations.map((location: any, index: number) => (
            <Flex
              key={index}
              bg="white"
              sx={{
                px: 36,
                pt: 26,
                pb: 21,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              data-testid="location"
            >
              <Flex sx={{ width: "100%", flexWrap: "wrap" }}>
                <Box sx={{ flex: 2, flexWrap: "wrap", color: "shade_800" }} dangerouslySetInnerHTML={createMarkup(
                    search.length >=3 ? location.name.replace(new RegExp(search,'gi'), `<mark>${search}</mark>`): location.name
                  )}>
                </Box>
                <Box sx={{ flex: 2, flexWrap: "wrap", color: "shade_400" }}>
                  <RowMerchantElementToLocation location={location} merchants={merchants} />
                </Box>
                <Box sx={{ flex: 3, flexWrap: "wrap", color: "shade_800" }}>
                  {location.physicalLocation?.street}{" "}
                  {location.physicalLocation?.houseNumber}{" "}
                  {location.physicalLocation?.postalCode}{" "}
                  {location.physicalLocation?.city}{" "}
                </Box>
                <Flex sx={{ flex: 1, justifyContent: "flex-end" }}>
                  <input
                    className="checkbox-location"
                    width="24px"
                    height="24px"
                    type="checkbox"
                    checked={location.isSelected}
                    disabled={state.fields.type === UserType.SUPER_USER}
                    onChange={(e) => { 
                      handleLocations(location.id, location.isSelected);
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          ))
        )}
      </Box>
      {state.errors.locations ? (
        <ErroroLabel message="Please select atleast one location" />
      ) : (
        ""
      )}
    </>
  );
}
