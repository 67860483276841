// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { TFunction } from "i18next";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
export interface StatusResult {
  dotColor: string;
  statusText: string;
}

export function getUserStatus(status: string, t: TFunction): StatusResult {
  let dotColor: string;
  let statusText: string;

  switch (status) {
    case "PROVISIONED":
      dotColor = "primary";
      statusText = t("UserStatusSelect.provisioned");
      break;
    case "ACTIVE":
      dotColor = "secondary_700";
      statusText = t("UserStatusSelect.active");
      break;
    case "RECOVERY":
      dotColor = "raspberry_300";
      statusText = t("UserStatusSelect.recovery");
      break;
    case "SUSPENDED":
      dotColor = "raspberry_500";
      statusText = t("UserStatusSelect.suspended");
      break;
    case "LOCKED_OUT":
      dotColor = "raspberry_500";
      statusText = t("UserStatusSelect.locked_out");
      break;
    case "PASSWORD_EXPIRED":
      dotColor = "raspberry_300";
      statusText = t("UserStatusSelect.password_expired");
      break;
    case "DEPROVISIONED":
      dotColor = "raspberry_500";
      statusText = t("UserStatusSelect.deprovisioned");
      break;
    case "UNKNOWN":
    default:
      dotColor = "raspberry_500";
      statusText = t("UserStatusSelect.unknown");
  }
  return { dotColor, statusText };
}
