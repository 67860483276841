// -----------------------------------------------------------------
// EXTERNAL IMPORTS
// -----------------------------------------------------------------
import TagManager from "react-gtm-module";

import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card, Box, Flex, Button, Text } from "theme-ui";

// -----------------------------------------------------------------
// INTERNAL IMPORTS
// -----------------------------------------------------------------
import Location from "../Location";
import Loader from "../../../Shared/Loader";

import { resetState } from "@brinks/common/reducers/supportCaseSlice";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { resetWebFilter, setSelectedLocation } from "@brinks/common/reducers/storeLocationSlice";
import { SupportCreateMerchantSelector } from "./SupportCreateMerchantSelector";
import { getTemplates } from "@brinks/common/reducers/supportCaseWithMerchantSlice";


export const SelectLocation = ({ nextStep }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { storeLocations, selectedLocation } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const { loading } = useAppSelector(
    (state) => state.supportCaseSlice
  );

  const { currentMerchant } = useAppSelector((state) => state.merchantsDetailSlice);

  const onUpdateLocation = (data: any) => {
    dispatch(setSelectedLocation([data]));
  };

  useEffect(() => {

    if (selectedLocation && selectedLocation.length >= 1) {
      dispatch(getTemplates(selectedLocation[0].physicalLocation?.country));
    }

  }, [selectedLocation]);


  const stores = useMemo(() => {
    if(!storeLocations) return [];

    const locations = storeLocations;

    let teste = currentMerchant
      ? locations.filter((location) => location.merchantId === currentMerchant.id)
      : locations;

      return teste;

  }, [currentMerchant, storeLocations]);

  if (loading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Card
        bg="white"
        data-testid="LocationDetails-container-card1"
        sx={{
          margin: "0 auto",
          borderRadius: "8px",
          alignItems: "center",
          padding: "32px 32px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "455px",
            flexDirection: "column",
          }}
        >
          <Text
            data-testid="flex-location-text"
            sx={{
              fontWeight: "body",
              fontSize: "subText",
              lineHeight: "register_bag",
            }}
          >
            {t("ManageCase.merchant")}
          </Text>
          <div style={{ marginTop: "8px" }}>
            <SupportCreateMerchantSelector value={currentMerchant}  />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Location
              locations={stores}
              selectedLocation={selectedLocation}
              lable={t("support.case_for_location")}
              onUpdateLocation={(data) => {
                onUpdateLocation(data);

                TagManager.dataLayer({
                  dataLayer: {
                    event: "page_view",
                    Page_title: "Support | Create a case - Select location"
                  }
                });
              }}
            />
          </div>
        </Box>
      </Card>
      <Flex mt={20} sx={{ flexDirection: "row-reverse" }}>
        <Button
          disabled={!Boolean(selectedLocation) || currentMerchant == null}
          data-testid="register-sealbag-step1-next-btn"
          onClick={(e: any) => {
            nextStep();
            TagManager.dataLayer({
              dataLayer: {
                Action: "Next",
                event: "Click_event",
                Page_title: "Support | Create a case - Select location",
              }
            });
          }}
          sx={{
            height: 49,
            float: "right",
            cursor: "pointer",
            borderRadius: "40px",
            pointerEvents: `auto`,
            width: ["100%", "169px"],
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            "&:disabled": {
              bg: "shade_200",
              color: "shade_400",
              cursor: "not-allowed",
            },
          }}
        >
          {t("ManageSealBags.next")}
        </Button>
        <Button
          onClick={() => {
            dispatch(resetState());
            dispatch(resetWebFilter());
            navigate(`/support`);
            TagManager.dataLayer({
              dataLayer: {
                dataLayer: {
                  Action: "Cancel",
                  event: "Click_event",
                  Page_title: "Support | Create a case - Select location",
                }
              }
            });
          }}
          mr={20}
          variant="muted"
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            height: 49,
            cursor: "pointer",
            border: "1px solid",
            borderRadius: "40px",
            pointerEvents: `auto`,
            color: "royalBlue_500",
            width: ["100%", "169px"],
            borderColor: "royalBlue_500",
            backgroundColor: "background",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
    </>
  );
}
