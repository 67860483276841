import { Link } from "react-router-dom";
import { Box, Flex, Text } from "theme-ui";
import TransactionLabel from "./TransactionLabel";
import { Transaction } from "@brinks/common/api/Api";
import { formatCurrency } from "@brinks/common/utils";
import { ReactComponent as VISA } from "@brinks/common/Icons/visa.svg";
import { ReactComponent as VPAY } from "@brinks/common/Icons/vpay.svg";
import { ReactComponent as IDEAL } from "@brinks/common/Icons/ideal.svg";
import { ReactComponent as PAYPAL } from "@brinks/common/Icons/paypal.svg";
import { ReactComponent as CASH } from "@brinks/common/Icons/cash_web.svg";
import { ReactComponent as MAESTRO } from "@brinks/common/Icons/maestro.svg";
import { ReactComponent as APPLE_PAY } from "@brinks/common/Icons/apple_pay.svg";
import { ReactComponent as MASTERCARD } from "@brinks/common/Icons/mastercard.svg";
import { ReactComponent as BANCONTACT } from "@brinks/common/Icons/bancontact.svg";
import { ReactComponent as GOOGLE_PAY } from "@brinks/common/Icons/google_pay.svg";
import { ReactComponent as RETURN_PAYMENT } from "@brinks/common/Icons/return_payment.svg";
import { ReactComponent as AMERICAN_EXPRESS } from "@brinks/common/Icons/american_express_web.svg";
import { useAppSelector } from "@brinks/common/hooks/hooks";

interface Props {
  transactionData: Transaction;
}

const components = {
  VISA: VISA,
  VPAY: VPAY,
  CASH: CASH,
  IDEAL: IDEAL,
  PAYPAL: PAYPAL,
  MAESTRO: MAESTRO,
  APPLE_PAY: APPLE_PAY,
  BANCONTACT: BANCONTACT,
  MASTERCARD: MASTERCARD,
  GOOGLE_PAY: GOOGLE_PAY,
  RETURN: RETURN_PAYMENT,
  AMERICAN_EXPRESS: AMERICAN_EXPRESS,
};

export default function TransactionList({ transactionData }: Props) {
  const PaymentMethodIcon = components[transactionData.paymentMethod];

  const locationAndMerchant = transactionData.merchant
    ? `${transactionData.merchant.name} | ${transactionData.address}, ${transactionData.merchant.country}`
    : `${transactionData.address}`;

  return (
    <Link
      style={{ textDecoration: "none" }}
      to={{
        pathname: `/transaction/${transactionData.id}/${transactionData.type}`,
      }}
    >
      <Flex
        bg="white"
        data-testid="TransactionList"
        sx={{
          height: 90,
          borderBottom: "1px solid",
          borderColor: "borderColor",
        }}
      >
        <Flex
          p={2}
          data-testid="TransactionList-icon"
          sx={{ flex: "1 1 auto", alignItems: "center" }}
        >
          <Flex
            data-testid="TransactionList-payment-icon"
            sx={{
              width: 32,
              height: 32,
              borderRadius: "4px",
              border: "1px solid",
              alignItems: "center",
              justifyContent: "center",
              borderColor: "transactionIconBorders",
            }}
          >
            <PaymentMethodIcon
              data-testid={`payment-method-icon ${transactionData.paymentMethod}`}
            />
          </Flex>
          <Flex
            data-testid="TransactionList-payment-name"
            sx={{ pl: "12px", flexDirection: "column" }}
          >
            <Text
              data-testid="TransactionList-payment-name-text"
              sx={{
                fontWeight: "",
                fontSize: "body",
                color: "shade_800",
                lineHeight: "body",
              }}
            >
              {transactionData.name}
            </Text>
            <Text
              data-testid="TransactionList-payment-merchant-and-address"
              sx={{
                fontSize: "small",
                color: "shade_700",
                lineHeight: "16.8px",
                fontWeight: "weight_400",
              }}
            >
              {locationAndMerchant}
            </Text>
          </Flex>
        </Flex>
        <Flex
          p={2}
          data-testid="TransactionList-flex"
          sx={{
            alignItems: "end",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Box
            data-testid="TransactionList-box"
            sx={{
              color: "shade_700",
              fontWeight: "body",
              lineHeight: "22.4px",
            }}
          >
            {formatCurrency(
              transactionData.grossAmount,
              transactionData.merchant?.currency,
              transactionData.merchant?.country
            )}
          </Box>
          <TransactionLabel label={transactionData.type} />
        </Flex>
      </Flex>
    </Link>
  );
}
