import { useAppDispatch } from "@brinks/common/hooks/hooks";
import { Alert, Close } from "theme-ui";
import { closeMessage } from "@brinks/common/reducers/userSlice";

interface Props {
  message: string;
}
export default function Error({ message }: Props) {
  const dispatch = useAppDispatch();

  return (
    <Alert
      data-testid="error-alert"
      sx={{
        position: "fixed",
        left: "50%",
        top: "13%",
        width: 496,
        boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        fontSize: "subText",
        fontWeight: "weight_400",
      }}
    >
      {message || "Some thing went wrong!"}
      <Close
        ml="auto"
        sx={{
          cursor: "pointer",
        }}
        mr={-2}
        onClick={() => dispatch(closeMessage())}
        data-testid="error-alert-close-icon"
      />
    </Alert>
  );
}
