import { Box, Text, Flex } from "theme-ui";
import Select, { StylesConfig } from "react-select";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@brinks/common/hooks/hooks";

interface LocationProps {
  id: string;
  label: string;
  name: string;
  value: string;
}

interface Props {
  locations: any;
  lable: any;
  selectedLocation: any;
  disabled?: boolean;
  onUpdateLocation: ({ id, name, label, value }: LocationProps) => void;
}
const customControlStyles: CSSProperties = {
  height: "61px",

};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

export const Location = ({
  locations,
  onUpdateLocation,
  lable,
  selectedLocation,
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  
  const stores =  locations &&  locations.map((obj: any) => {return { ...obj, label: obj.name, value: obj.id };
    });

    const { currentMerchant } = useAppSelector((state) => state.merchantsDetailSlice);

  return (
    <Flex
      data-testid="advanceFilterLocation"
      bg={"white"}
      sx={{
        flexDirection: "column",
        zIndex: 999,
      }}
    >
      <Text
        sx={{
          fontWeight: "body",
          fontSize: "subText",
          lineHeight: "register_bag",
        }}
        data-testid="flex-location-text"
      >
        {lable}
      </Text>
      <Box
        data-testid="advanceFilter-Location-box"
        className="custome-select"
        sx={{
          pt: "8px",
          width: "455px",
        }}
      >
        <Select
          isDisabled={currentMerchant == null}
          data-testid="advanceFilter-Location-select"
          value={selectedLocation}
          isSearchable={true}
          styles={customStyles}
          onChange={(e: any) => {
            onUpdateLocation(e);
          }}
          options={stores || "Fetching..."}
          placeholder={t("ManageSealBags.select_location")}
        />
      </Box>
    </Flex>
  );
};
export default Location;
