import "react-datepicker/dist/react-datepicker.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Alert, Box, Close, Flex, Label } from "theme-ui";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import {
  setSelectedPickupDate,
  getCalendar,
  resetCalendarError,
} from "@brinks/common/reducers/orderDetailsSlice";
import { validateOrderChangeDate } from "../../../useCases/orderChange/validateOrderChangeDate";
import { OrderChangeType } from "@brinks/common/constants/orderChangeType";
import Loader from "../../../Shared/Loader";

export default function SelectTimeslotDetails({ nextStep }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    selectedPickupDate,
    selectedPickupLocation,
    deliveryDateResponseError,
    selectedTypeOfChange,
    countryDeviceId,
    calendarDates,
    loadingCalendarDates,
    calendarError,
  } = useAppSelector((state) => state.orderDetailsSlice);

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);

  const { businessRulesMoneyOrders } = useAppSelector(
    (state) => state.businessRulesMoneyOrdersSlice
  );

  const { locationsServices } = useAppSelector(
    (state) => state.locationServiceDetailSlice
  );

  const isPhysicalLocationNL = !!(
    selectedPickupLocation?.physicalLocation?.country === "NL"
  );
  const shouldShowCalendar = businessRulesMoneyOrders
    ? businessRulesMoneyOrders[0].orderAllowCustomDates
    : false;

  useEffect(() => {
    if (
      locationsServices &&
      businessRulesMoneyOrders &&
      selectedTypeOfChange === OrderChangeType.DYNAMIC
    ) {
      const validatedStartDate = validateOrderChangeDate({
        locationBusinessRules: businessRulesMoneyOrders[0],
        startDate: new Date(),
        locationServices: locationsServices?.locationServices.filter(
          (item) => item.storeLocationId === selectedPickupLocation.id
        )[0],
      });
      setStartDate(validatedStartDate);
      dispatch(setSelectedPickupDate(validatedStartDate));
    }
  }, []);

  useEffect(() => {
    if (
      isPhysicalLocationNL &&
      selectedTypeOfChange === OrderChangeType.ON_DEMAND &&
      countryDeviceId
    ) {
      dispatch(getCalendar({ partnerLocationId: countryDeviceId }));
    }
  }, []);

  const isAllowTransportDay = (date: Date) => {
    const locationServices = locationsServices
      ? locationsServices?.locationServices.filter(
          (item) => item.storeLocationId === selectedPickupLocation.id
        )[0]
      : date;
    const day = date.toLocaleDateString("US-en", { weekday: "long" });
    const paramName = `servicingOn${day}`;

    if (
      locationServices &&
      (locationServices as any)[paramName] !== undefined
    ) {
      return (locationServices as any)[paramName];
    }

    return false;
  };

  const isTransportPossible = (date: Date) => {
    const dateInfo = calendarDates.find(
      (item: any) =>
        moment(new Date(item.date)).format("YYYY-MM-DD") ===
        moment(new Date(date)).format("YYYY-MM-DD")
    );

    return dateInfo ? dateInfo.transportPossible : false;
  };

  const getClassName = (date: Date) => {
    const dateInfo = calendarDates.find(
      (item: any) =>
        moment(new Date(item.date)).format("YYYY-MM-DD") ===
        moment(new Date(date)).format("YYYY-MM-DD")
    );

    if (!dateInfo) {
      return "";
    }
    if (dateInfo.transportPlanned && dateInfo.transportPossible) {
      return "transport-planned-and-possible";
    }

    if (!dateInfo.transportPlanned && dateInfo.transportPossible) {
      return "transport-not-planned-and-possible";
    }

    return "";
  };

  return (
    <>
      <div>
        <style>{`
        .transport-planned-and-possible {
          background-color: #0F61D8 !important;
          color: white !important;
        }
        .transport-not-planned-and-possible {
          background-color: white !important;
          color: black !important;
        }
      `}</style>
        {((selectedTypeOfChange === OrderChangeType.ON_DEMAND &&
          isPhysicalLocationNL) ||
          !isPhysicalLocationNL && shouldShowCalendar) && (
          <Flex
            mt={32}
            bg="white"
            data-testid="LocationList"
            sx={{
              p: 35,
              borderRadius: 8,
              flexDirection: "column",
              borderBottom: "1px solid borderColor",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box mt={32} sx={{ width: 455 }}>
              <Label
                sx={{
                  fontWeight: "body",
                  fontFamily: "body",
                  fontSize: "subText",
                  marginBottom: "8px",
                  lineHeight: "register_bag",
                }}
              >
                {t("ManageOrders.delivery_date")}
              </Label>

              {!isPhysicalLocationNL && shouldShowCalendar && (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={selectedPickupDate ? selectedPickupDate : startDate}
                  filterDate={isAllowTransportDay}
                  minDate={startDate}
                  data-testid="advanceFilter-Period-input-start"
                  onChange={(e: Date) => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "Click_event",
                        Action: "Select Delivery Date",
                        Page_title:
                          "Order management | Order Change - Select Denomination",
                      },
                    });
                    dispatch(setSelectedPickupDate(e));
                  }}
                />
              )}
              {isPhysicalLocationNL &&
                selectedTypeOfChange === OrderChangeType.ON_DEMAND &&
                !loadingCalendarDates &&
                calendarDates && (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Please select a date"
                    selected={selectedPickupDate}
                    filterDate={isTransportPossible}
                    minDate={new Date(calendarDates[0].date)}
                    data-testid="advanceFilter-Period-input-start"
                    dayClassName={getClassName}
                    onChange={(e: Date) => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "Click_event",
                          Action: "Select Delivery Date",
                          Page_title:
                            "Order management | Order Change - Select Denomination",
                        },
                      });
                      dispatch(setSelectedPickupDate(e));
                    }}
                  />
                )}
              {loadingCalendarDates && (
                <Box>
                  <Loader />
                </Box>
              )}

              {calendarError && (
                <Alert
                  data-testid="error-alert"
                  sx={{
                    position: "fixed",
                    left: "50%",
                    top: "13%",
                    width: 496,
                    boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.1)",
                    borderRadius: 4,
                    fontSize: "subText",
                    fontWeight: "weight_400",
                  }}
                >
                  {calendarError}
                  <Close
                    ml="auto"
                    sx={{
                      cursor: "pointer",
                    }}
                    mr={-2}
                    onClick={() => dispatch(resetCalendarError())}
                    data-testid="error-alert-close-icon"
                  />
                </Alert>
              )}
            </Box>
          </Flex>
        )}
      </div>
    </>
  );
}
