import { Text, Flex } from "theme-ui";
import { ReactComponent as VISA } from "@brinks/common/Icons/visa.svg";
import { ReactComponent as CASH } from "@brinks/common/Icons/cash_web.svg";
import { ReactComponent as MASTERCARD } from "@brinks/common/Icons/mastercard.svg";
import { ReactComponent as AMERICAN_EXPRESS } from "@brinks/common/Icons/american_express_web.svg";
import { ReactComponent as VPAY } from "@brinks/common/Icons/vpay.svg";
import { ReactComponent as MAESTRO } from "@brinks/common/Icons/maestro.svg";
import { ReactComponent as IDEAL } from "@brinks/common/Icons/ideal.svg";
import { ReactComponent as BANCONTACT } from "@brinks/common/Icons/bancontact.svg";
import { ReactComponent as GOOGLE_PAY } from "@brinks/common/Icons/google_pay.svg";
import { ReactComponent as APPLE_PAY } from "@brinks/common/Icons/apple_pay.svg";
import { ReactComponent as PAYPAL } from "@brinks/common/Icons/paypal.svg";
import { ReactComponent as RETURN_PAYMENT} from '@brinks/common/Icons/return_payment.svg';
import { getDateTimeFormat } from "@brinks/common/utils";
import { TransactionDetails } from "@brinks/common/api/Api";

const components = {
  VISA: VISA,
  CASH: CASH,
  MASTERCARD: MASTERCARD,
  AMERICAN_EXPRESS: AMERICAN_EXPRESS,
  VPAY: VPAY,
  MAESTRO: MAESTRO,
  IDEAL: IDEAL,
  BANCONTACT: BANCONTACT,
  GOOGLE_PAY: GOOGLE_PAY,
  APPLE_PAY: APPLE_PAY,
  PAYPAL: PAYPAL,
  RETURN: RETURN_PAYMENT,
};

interface Props {
  transactionDetails: TransactionDetails | null;
}

export const  CardHeader = ({transactionDetails}:Props) => {

  const PaymentMethodIcon =
    components[transactionDetails?.paymentMethod || 'CASH'];
  return (
    <Flex data-testid="CardHeader-flex">
      <Flex
        data-testid="CardHeader-flex-icon"
        sx={{
          width: 48,
          height: 48,
          alignItems: "center",
        }}
      >
      <PaymentMethodIcon data-testid={`payment-method-icon ${transactionDetails?.paymentMethod}`}/>
      </Flex>
      <Flex
        data-testid="CardHeader-flex-title"
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Text
          data-testid="CardHeader-flex-title-text"
          sx={{
            fontSize: "medium",
            color: "shade_700",
            lineHeight: "22px",
            fontWeight: "body",
          }}
        >
          {transactionDetails?.name}
        </Text>
        <Text
          data-testid="CardHeader-flex-title-time"
          sx={{
            fontSize: "subText",
            color: "shade_400",
            lineHeight: "19.6px",
            fontWeight: "weight_400",
          }}
        >
         {transactionDetails?.dateTime ? getDateTimeFormat(transactionDetails?.dateTime): ''}
        </Text>
      </Flex>
    </Flex>
  );
}
export default CardHeader;