import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { Api, LocationService, GetLocationServicesResponse } from "../api/Api";

// Define the initial state using that type
const initialState: locationServiceSliceState = {
    loadingLocationServices: false,
    locationsServices: null,
    currentLocationService: null,
};

interface locationServiceSliceState {
  loadingLocationServices: boolean;
  locationsServices: GetLocationServicesResponse | null;
  currentLocationService: LocationService | null;
}

export const getLocationServices = createAsyncThunk<any, any, {}>(
  "GetLocationServicesResponse",
  (data, thunkAPI) => {
    const res = new Api().locationServices.getLocationServices({serviceId: data});
    return res;
  }
);

export const locationServiceDetailSlice = createSlice({
  name: "locationServiceDetailSlice",
  initialState,
  reducers: {
    setLocationServices: (state, action) => {
      state.locationsServices = action.payload;
    },
    setCurrentLocationServices: (state, action) => {
      state.currentLocationService = action.payload;
    },
    setLoadingLocationServices: (state, action) => {
      state.loadingLocationServices = action.payload;
    },
    resetWebFilter: () => initialState,
  },
  extraReducers: {
    [getLocationServices.pending.toString()]: (state: locationServiceSliceState) => {
      (state.loadingLocationServices = true), (state.locationsServices = null);
    },
    [getLocationServices.fulfilled.toString()]: (
      state: locationServiceSliceState,
      { payload }: any
    ) => {
      (state.loadingLocationServices = false), (state.locationsServices = payload.data);
    },
    [getLocationServices.rejected.toString()]: (state: locationServiceSliceState) => {
      state.loadingLocationServices = false;
    },
  },
});

export const { setLocationServices, setCurrentLocationServices, resetWebFilter, setLoadingLocationServices } =
locationServiceDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default locationServiceDetailSlice.reducer;
