import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Heading, Flex, Text, Card } from "theme-ui";
import { FolderIcon } from "@brinks/common/Icons/Components/folder";
import { FolderAddIcon } from "@brinks/common/Icons/Components/folder_add";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import PageHeader from "../../Shared/PageHeader";
import Wrapper from "../../Shared/Wrapper/Wrapper";

export default function Support() {

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Support | Start screen"
      }
    })

  }, [])

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (<>
    <PageHeader>
      <Heading>
        {t("support.support_label")}
      </Heading>
    </PageHeader>
    <Wrapper>
      <Card
        bg="white"
        data-testid="LocationDetails-container-card1"
        sx={{
          margin: "0 auto",
          borderRadius: "5px",
          alignItems: "center",
          padding: "39px 32px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Flex
          sx={{
            display: "block",
          }}
        >
          <Flex
            onClick={() => {
              navigate(`/support/list`);
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Support | Start screen",
                  Action: "List cases"
                }
              });
            }}
            sx={{
              width: 430,
              height: 142,
              border: "1px solid",
              borderColor: "shade_200",
              borderRadius: 16,
              padding: 40,
              backgroundColor: "white",
              cursor: "pointer",
            }}
          >
            <FolderIcon
              color={"order_primary"}
            />
            <Flex
              ml={37}
              sx={{
                flexDirection: "column",
              }}
            >
              <Text
                mt={10}
                sx={{
                  fontSize: "medium",
                  fontFamily: "heading",
                  fontWeight: "heading",
                  lineHeight: "location_lable",
                  color: "black",
                }}
              >
                {t("support.overview_cases")}
              </Text>
              <Text
                mt={12}
                sx={{
                  fontSize: "subText",
                  fontFamily: "body",
                  fontWeight: "weight_400",
                  lineHeight: "register_bag",
                  color: "shade_400",
                }}
              >
                {t("support.overview_description")}
              </Text>
            </Flex>
          </Flex>
          <Flex
            onClick={() => {
              navigate(`/support/create`);
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Support | Start screen",
                  Action: "Create a case"
                }
              })
            }}
            ml={40}
            sx={{
              width: 434,
              height: 142,
              border: "1px solid",
              borderColor: "shade_200",
              borderRadius: 16,
              padding: 40,
              backgroundColor: "white",
              cursor: "pointer",
            }}
          >
            <FolderAddIcon
              color={"order_primary"}
            />
            <Flex
              ml={37}
              sx={{
                flexDirection: "column",
              }}
            >
              <Text
                mt={10}
                sx={{
                  fontSize: "medium",
                  fontFamily: "heading",
                  fontWeight: "heading",
                  lineHeight: "location_lable",
                  color: "black",
                }}
              >
                {t("support.create_a_case")}
              </Text>
              <Text
                mt={12}
                sx={{
                  fontSize: "subText",
                  fontFamily: "body",
                  fontWeight: "weight_400",
                  lineHeight: "register_bag",
                  color: "shade_400",
                }}
              >
                {t("support.create_case_description")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Wrapper>
  </>);
}
