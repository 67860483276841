/** @jsxImportSource theme-ui */
export const UserIcon = ({ color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" sx={{fill:color}} xmlns="http://www.w3.org/2000/svg">
      <path d="M8.0999 5.39995C8.0999 6.89112 6.89107 8.09995 5.3999 8.09995C3.90873 8.09995 2.6999 6.89112 2.6999 5.39995C2.6999 3.90878 3.90873 2.69995 5.3999 2.69995C6.89107 2.69995 8.0999 3.90878 8.0999 5.39995Z" />
      <path d="M5.3999 9.89995C7.88518 9.89995 9.8999 11.9147 9.8999 14.4V15.3H0.899902V14.4C0.899902 11.9147 2.91462 9.89995 5.3999 9.89995Z" />
      <path d="M15.2999 5.39995C15.2999 6.89112 14.0911 8.09995 12.5999 8.09995C11.1087 8.09995 9.8999 6.89112 9.8999 5.39995C9.8999 3.90878 11.1087 2.69995 12.5999 2.69995C14.0911 2.69995 15.2999 3.90878 15.2999 5.39995Z" />
      <path d="M11.6361 15.3C11.6782 15.006 11.6999 14.7055 11.6999 14.4C11.6999 12.9283 11.1953 11.5745 10.3497 10.5021C11.0116 10.1191 11.7802 9.89995 12.5999 9.89995C15.0852 9.89995 17.0999 11.9147 17.0999 14.4V15.3H11.6361Z" />
    </svg>
  );
};
