// ------------------------------------------------------------
// INTERNAL IMPORTS
// ------------------------------------------------------------
import { 
  Order,
  CaseWithMerchant, 
  StoreLocationWithMerchant, 
  MerchantWithDependentsAndLocations,
} from "@brinks/common/api/Api";

// ------------------------------------------------------------
// TYPES
// ------------------------------------------------------------
interface Options {
  label: string;
  value: string;
}

// ------------------------------------------------------------
// UTILS
// ------------------------------------------------------------
export const getAllMerchantWithMerchantsAssociated = (
  merchant: MerchantWithDependentsAndLocations
): MerchantWithDependentsAndLocations[] => {
  let merchants: MerchantWithDependentsAndLocations[] = [merchant];

  if (merchant.dependents) {
    for (const dependentMerchant of merchant.dependents) {
      merchants = merchants.concat(getAllMerchantWithMerchantsAssociated(dependentMerchant));
    }
  }

  return merchants;
}

const findMerchantInDependents = (
  merchant: MerchantWithDependentsAndLocations, 
  merchantId: string
): null | MerchantWithDependentsAndLocations => {
  if (merchant.id === merchantId) {
    return merchant;
  }

  if (merchant.dependents) {
    for (const dependent of merchant.dependents) {
      const found = findMerchantInDependents(dependent, merchantId);
      if (found) {
        return found;
      }
    }
  }

  return null;
};


export const getMerchantWithMerchantsAssociatedFromStoreLocationWithMerchant = (
  stores: StoreLocationWithMerchant[],
  merchantId: string
): null | MerchantWithDependentsAndLocations => {
  for (const store of stores) {
    if (store.merchant) {
      const foundMerchant = findMerchantInDependents(store.merchant, merchantId);
      if (foundMerchant) {
        return foundMerchant;
      }
    }
  }
  
  return null; 
}

export const handleGenerateMerchantOptionsByLocations = (storeLocationWithMerchant: StoreLocationWithMerchant[]) => {
  const optionsToAdd: Options[] = [];
  let merchants: MerchantWithDependentsAndLocations[] = [];
  const uniqueMerchants: { [key: string]: MerchantWithDependentsAndLocations } = {};

  for (const { merchant } of storeLocationWithMerchant) {
    if (merchant) {
      const merchantsByLocation = getAllMerchantWithMerchantsAssociated(merchant);
  
      for(const merchantsByLocationElement of merchantsByLocation) {
        if (!uniqueMerchants[`${merchantsByLocationElement.id}`]) {
          uniqueMerchants[`${merchantsByLocationElement.id}`] = merchantsByLocationElement;
          merchants.push(merchantsByLocationElement);
        }
      }
    }
  }

  for (const { id, name } of merchants) {
    optionsToAdd.push({ value: `${id}`, label: `${name}` });
  }

  return optionsToAdd;
}

export const handleGenerateMerchantOptionsByCases = (cases: CaseWithMerchant[]) => {
  const optionsToAdd: Options[] = [];
  let merchants: MerchantWithDependentsAndLocations[] = [];
  const uniqueMerchants: { [key: string]: MerchantWithDependentsAndLocations } = {};

  for (const { merchant } of cases) {
    if (merchant) {
      const merchantsByCases = getAllMerchantWithMerchantsAssociated(merchant);
  
      for(const merchantsByCasesElement of merchantsByCases) {
        if (!uniqueMerchants[`${merchantsByCasesElement.id}`]) {
          uniqueMerchants[`${merchantsByCasesElement.id}`] = merchantsByCasesElement;
          merchants.push(merchantsByCasesElement);
        }
      }
    }
  }

  for (const { id, name } of merchants) {
    optionsToAdd.push({ value: `${id}`, label: `${name}` });
  }

  return optionsToAdd;
}

export const handleGenerateMerchantOptionsByOrders = (orders: Order[]) => {
  const optionsToAdd: Options[] = [];
  const seenIds: Set<string> = new Set();

  const uniqueMerchants = orders
    .map(order => ({...order.merchant, id: order.merchantId}))
    .filter((merchant) => {
      if (merchant && !seenIds.has(`${merchant.id}`)) {
        seenIds.add(`${merchant.id}`);
        return true;
      }

      return false;
    });
  
  for (const { id, name } of uniqueMerchants) {
    optionsToAdd.push({ value: `${id}`, label: `${name}` });
  }

  optionsToAdd.sort((a, b) => a.label.localeCompare(b.label));
  return optionsToAdd;
}