import { useEffect, useState } from "react";
import { useAppSelector } from "@brinks/common/hooks/hooks";
import {
  User,
  MerchantWithDependentsAndLocations,
  StoreLocation,
  MerchantsAndLocations,
} from "@brinks/common/api/Api";
import {
  UserLocationFilter,
  isAllUserLocationOptionFilter,
} from "../components/Users/shared/types/user-location-filter.types";

interface UsersFilters {
  status: UserLocationFilter;
}

interface LocationFilters {
  merchantFilter: {
    id: null | string;
  };
}

interface SearchBarFilter {
  text: string | null;
}
export interface UseUserWithLocationsFilters {
  usersFilters: UsersFilters;
  locationsFilters: LocationFilters;
  searchBar: SearchBarFilter;
}
interface UseUserWithLocationsProps {
  filters?: UseUserWithLocationsFilters;
}

export type UserLocation = Omit<User, "locations"> & {
  locations: StoreLocation[];
};
interface UseUserWithLocationsReturn {
  loading: boolean;
  allUsers: UserLocation[];
  locationsFiltering: boolean;
  filteredUsers: UserLocation[];
  filters: UseUserWithLocationsFilters;
  defaultFilters: UseUserWithLocationsFilters;
  merchants: MerchantWithDependentsAndLocations[];
  allDistinctLocations: StoreLocation[];
  handleApplyFilters: (filters: UseUserWithLocationsFilters) => void;
}

export const initialFilterValues: UseUserWithLocationsFilters = {
  usersFilters: {
    status: "ALL",
  },
  locationsFilters: {
    merchantFilter: {
      id: null,
    },
  },
  searchBar: {
    text: null,
  },
};

export const useUserWithLocations = ({
  filters = initialFilterValues,
}: UseUserWithLocationsProps): UseUserWithLocationsReturn => {
  const [usersWithLocations, setUsersWithLocations] = useState<UserLocation[]>(
    []
  );
  const [locationsFilteringLoading, setLocationsFilteringLoading] =
    useState<boolean>(true);
  const [usersWithLocationsDefault, setUsersWithLocationsDefault] = useState<
    UserLocation[]
  >([]);
  const [allDistinctLocations, setAllDistinctLocations] = useState<
    StoreLocation[]
  >([]);
  const [usersWithLocationsFilters, setUsersWithLocationsFilters] =
    useState<UseUserWithLocationsFilters>(filters);
  const [usersWithLocationsMerchants, setUsersWithLocationsMerchants] =
    useState<MerchantsAndLocations[]>([]);

  const { users, isFetching } = useAppSelector((state) => state.userSlice);

  const { loading, storeLocations } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const { loadingMerchants, merchants } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );

  useEffect(() => {
    if (users && storeLocations) {
      const usersLocations = associateUsersAndLocations();
      setUsersWithLocations(usersLocations);
      setUsersWithLocationsDefault(usersLocations);
    }
  }, [users, storeLocations]);

  const associateUsersAndLocations = () => {
    const newUsers: UserLocation[] = [];

    if (users) {
      users.forEach((user) => {
        if (user.locations) {
          const associatedLocations: StoreLocation[] = [];

          user.locations.forEach((locationId) => {
            if (locationId && storeLocations) {
              const currentUserLocation = storeLocations.find((x) => {
                return x.id === locationId;
              });
              currentUserLocation &&
                associatedLocations.push(currentUserLocation);
            }
          });

          newUsers.push({
            ...user,
            locations: associatedLocations,
          });
        }
      });
    }

    return newUsers;
  };

  const handleApplyUserFilters = (
    { status }: UsersFilters,
    userLocations: UserLocation[]
  ): UserLocation[] => {
    if (!isAllUserLocationOptionFilter(status)) {
      return userLocations.filter((user) => {
        return user.status === status;
      });
    }

    return userLocations;
  };

  const handleApplyLocationsFilters = (
    locationFilters: LocationFilters,
    userLocations: UserLocation[],
    searchBarFilter: SearchBarFilter
  ): UserLocation[] => {
    const { merchantFilter } = locationFilters;
    const { id } = merchantFilter;

    const { text } = searchBarFilter;
    const searchTerm = (text || "").toLowerCase();

    let filteredUserLocations = userLocations.filter((user) => {
      return (
        user.locations.some((location) => {
          const { name, locationCode, merchant, merchantId } = location;
          return (
            (name && name.toLowerCase().includes(searchTerm)) ||
            (locationCode && locationCode.toLowerCase().includes(searchTerm)) ||
            (merchant && merchant.name.toLowerCase().includes(searchTerm)) ||
            (merchantId && merchantId.toLowerCase().includes(searchTerm))
          );
        }) ||
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(searchTerm) ||
        user.email.toLowerCase().includes(searchTerm)
      );
    });

    if (id) {
      filteredUserLocations = filteredUserLocations.filter(({ locations }) => {
        return locations.some(({ merchantId }) => {
          return merchantId === id;
        });
      });
    }

    return filteredUserLocations;
  };

  const isInitialFilters = (filters: UseUserWithLocationsFilters) => {
    return JSON.stringify(initialFilterValues) === JSON.stringify(filters);
  };

  const handleApplyFilters = async (filters: UseUserWithLocationsFilters) => {
    setUsersWithLocationsFilters(filters);

    if (isInitialFilters(filters)) {
      setUsersWithLocations(usersWithLocationsDefault);
    } else {
      const filteredByUser = handleApplyUserFilters(
        filters.usersFilters,
        usersWithLocationsDefault
      );
      const filteredByUserAndLocation = handleApplyLocationsFilters(
        filters.locationsFilters,
        filteredByUser,
        filters.searchBar
      );
      setUsersWithLocations(filteredByUserAndLocation);
    }
  };

  useEffect(() => {
    if (storeLocations && merchants) {
      setUsersWithLocationsMerchants(merchants);
      setAllDistinctLocations(storeLocations);
      setLocationsFilteringLoading(false);
    }
  }, [usersWithLocationsDefault, usersWithLocationsMerchants]);

  return {
    handleApplyFilters,
    allDistinctLocations,
    loading: loading || loadingMerchants || isFetching,
    filteredUsers: usersWithLocations,
    filters: usersWithLocationsFilters,
    defaultFilters: initialFilterValues,
    allUsers: usersWithLocationsDefault,
    merchants: usersWithLocationsMerchants,
    locationsFiltering: locationsFilteringLoading,
  };
};
