/** @jsxImportSource theme-ui */
import {
  Box,
  Text,
  Flex,
  Label,
  Radio,
} from "theme-ui";
import {
  filterTransactionTypeData,
} from "@brinks/common/constants/transactionTypeData";
import { ReactComponent as RightArrow } from "@brinks/common/Images/arrow-right.svg";
import { ReactComponent as BottomArrow } from "@brinks/common/Images/arrow-bottom.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onUpdateTransactionType: (type: string) => void;
  advanceTransactionType: string;
}

const TransactionType = ({
  advanceTransactionType,
  onUpdateTransactionType,
}: Props) => {
  const [isTransactionFilterExpanded, setIsTransactionFilterExpanded] =
    useState(true);
  const { t } = useTranslation();
  const downArrow = (isExpanded: boolean, setExpanded: (a: boolean) => void) =>
    isExpanded && (
      <BottomArrow
        onClick={(e) => setExpanded(!isExpanded)}
        data-testid="advanceFilter-Transaction-bottom-arrow"
        style={{
          cursor: "pointer"
        }}
      />
    );
  const rightArrow = (isExpanded: boolean, setExpanded: (a: boolean) => void) =>
    !isExpanded && (
      <RightArrow
        onClick={(e) => setExpanded(!isExpanded)}
        data-testid="advanceFilter-Transaction-right-arrow"
        style={{
          cursor: "pointer"
        }}
      />
    );
  return (
    <>
      <Flex
        data-testid="advanceFilterTransaction"
        bg={"white"}
        sx={{
          flexDirection: "column",
          pt: 10,
        }}
      >
        <Flex
          sx={{ justifyContent: "space-between" }}
          mb={10}
          data-testid="advanceFilter-Transaction-flex-wrapper"
        >
          <Text
            data-testid="advanceFilterTransaction-heading"
            sx={{
              fontWeight: "body",
            }}
          >
            {t("Dashboard.transaction_type")}
          </Text>
          <Box pr={"12px"}>
            {downArrow(
              isTransactionFilterExpanded,
              setIsTransactionFilterExpanded
            )}
            {rightArrow(
              isTransactionFilterExpanded,
              setIsTransactionFilterExpanded
            )}
          </Box>
        </Flex>

        {isTransactionFilterExpanded &&
          filterTransactionTypeData.map((row, index) => (
            <Label
              key={index}
              data-testid="advanceFilterTransactionlabel-all"
              mt={3}
              sx={{
                justifyContent: "space-between",
                fontSize: "body",
                fontWeight: "weight_400",
                lineHeight: "body",
                color: "shade_800",
              }}
            >
              {t(row.label)}
              <Radio
                data-testid="advanceFilter-Transaction-radio"
                name="dark-mode"
                onChange={() => onUpdateTransactionType(row.type)}
                checked={advanceTransactionType?.toLowerCase() == row.type}
              />
            </Label>
          ))}
      </Flex>
    </>
  );
};

export default TransactionType;
