

/** @jsxImportSource theme-ui */
import LoginLogo from "@brinks/common/Icons/LoginLogo";
import {
    Flex,
    Box,
} from "theme-ui";
import useTheme from '../../Hooks/useTheme';
import { ReactComponent as Union } from "@brinks/common/Icons/Union.svg";
import { ReactComponent as UnionBottom } from "@brinks/common/Icons/UnionBottom.svg";
import { ReactComponent as UnionDark } from "@brinks/common/Icons/Uniondark.svg";
import { ReactComponent as UnionBrinks } from "@brinks/common/Icons/UnionBrinks.svg";
import { ReactComponent as UnionBottomBrinks } from "@brinks/common/Icons/UnionBottomBrinks.svg";
import { ReactComponent as UnionDarkBrinks } from "@brinks/common/Icons/UniondarkBrinks.svg";
export default function LeftSidePage() {
  const themeType = useTheme();
  return (
    <Box
      data-testid="activation-left-pane"
      bg={"royalBlue_500"}
      sx={{
        width: "40%",
        height: "100vh",
        display:['none','block']
        
      }}
    >
      <Flex
        sx={{ justifyContent: "space-between" ,pl:50,pt:35}}
        data-testid="activation-left-pane-icons"
      >
        <LoginLogo themeType={themeType} data-testid="activation-left-pane-logo" />
        {themeType && 
          themeType === 'default' 
          ?
          <Union data-testid="activation-left-pane-union" />
          :
          <UnionBrinks data-testid="activation-left-pane-union" />
        }
      </Flex> 

      <Flex data-testid="activation-left-bottom-icons">
        {themeType && 
          themeType === 'default' 
          ?
          <>
            <UnionBottom
              data-testid="activation-left-bottom-icons-union"
              sx={{ position: "absolute", bottom: 86 }}
            />
            <UnionDark data-testid="activation-left-bottom-icons-union-dark" />
          </>
          :
          <>
            <UnionBottomBrinks
              data-testid="activation-left-bottom-icons-union"
              sx={{ position: "absolute", bottom: 86 }}
            />
            <UnionDarkBrinks data-testid="activation-left-bottom-icons-union-dark" />
          </>
        }
      </Flex>
    </Box>
  );
}
