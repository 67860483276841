// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { UserType } from "@brinks/common/constants/users"

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface IsStandardUserParams {
  role: string;
}

export const isStandardUser = ({ role }: IsStandardUserParams): Boolean => {
  return role === UserType.STANDARD_USER;
}

export const isSuperUser = ({ role }: IsStandardUserParams): Boolean => {
  return role === UserType.SUPER_USER;
}