import React, { CSSProperties } from "react";
import Select, { StylesConfig } from "react-select";
import { Box, Flex, Input } from "theme-ui";
import { SearchLocationIcon } from "@brinks/common/Icons/Components/searchLocation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertToDateTime } from "@brinks/common/utils";

const customControlStyles: CSSProperties = {
  height: "42.4px",
  borderRadius: 8,
  width: 200,

};
const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};
// Component for Default Column Filter
export function DefaultFilterForColumn({
  column: {
    filterValue,
    setFilter,
    Header
  },
}: any) {
  return (
    <Flex
      sx={{
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <Box sx={{ position: "absolute", px: 9, pt: '6px' }}>
        <SearchLocationIcon color={"shade_500"} />
      </Box>
      <Input
        type={"text"}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={Header()}
        sx={{
          bg: "white",
          border: "1px solid",
          borderColor: "shade_200",
          borderRadius: 8,
          padding: "9px 9px 9px 35px",
          textAlign: "left",
          fontSize: 16,
          color: "shade_400",
        }}
      />
    </Flex>
  );
}

// Component for Custom Select Filter
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, SelectOption },
}: any) {
  const optionsUpperScope = React.useMemo(() => {
    const options = SelectOption.map((item: any) => ({ label: item.label, value: item.value }));
    options.unshift({ label: 'All', value: '' });
    return options;
  }, [id, preFilteredRows]);

  const getSelectedFilter = (optionId: number) => {
    const option = SelectOption.find((option: any) => option.value === optionId);
    return (
      (option && {
        value: option?.value,
        label: option?.label,
      }) || {
        value: '',
        label: 'All',
      }
    );
  };
  return (
    <Flex
      sx={{
        alignItems: "center",
        marginTop: 10,
        fontWeight: 400,
      }}
    >
      <Select
        classNamePrefix="select-form-control"
        menuPlacement="auto"
        styles={customStyles}
        isSearchable
        components={{ IndicatorSeparator: () => null }}
        onChange={selectedOption => {
          setFilter(selectedOption.value || '');
        }}
        value={getSelectedFilter(filterValue)}
        options={optionsUpperScope}
      />
    </Flex>
  );
}
export const SelectDateColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id, SelectOption } }: any) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <DatePicker
        placeholderText="dd/mm/yyyy"
        dateFormat="dd/MM/yyyy"
        isClearable
        selected={(filterValue && convertToDateTime(filterValue)) || null}
        onChange={selectedOption => {
          setFilter(selectedOption?.toLocaleDateString('en-CA') || '');
        }}
      />
    </Flex>
  );
};