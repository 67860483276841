import { CSSProperties, useEffect, useMemo } from "react";
import { Box, Text, Flex } from "theme-ui";
import { useTranslation } from "react-i18next";
import Select, { StylesConfig } from "react-select";

import { StoreLocation } from "@brinks/common/api/Api";

interface OnUpdateLocationParams {
  id: string;
  name: string;
  value: string;
  label: string;
}
interface OrdersLocationProps {
  lable: any;
  disabled?: boolean;
  selectedLocation: any;
  locations: StoreLocation[];
  onUpdateLocation: ({
    id,
    name,
    label,
    value,
  }: OnUpdateLocationParams) => void;
}

const customControlStyles: CSSProperties = {
  height: "61px",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

export const OrdersLocation = ({
  lable,
  locations,
  disabled = false,
  onUpdateLocation,
  selectedLocation,
}: OrdersLocationProps) => {
  const { t } = useTranslation();

  const options: any = useMemo(() => {
    if (!locations) return [];

    return locations.map((obj: any) => ({
      ...obj,
      label: obj.name,
      value: obj.id,
    }));
  }, [locations]);

  useEffect(() => {
    if (options && selectedLocation === null && options.length === 1) {
      onUpdateLocation(options[0]);
    }
  }, [options]);

  return (
    <Flex
      bg={"white"}
      data-testid="advanceFilterLocation"
      sx={{ zIndex: 999, flexDirection: "column", marginTop: "20px" }}
    >
      <Text
        data-testid="flex-location-text"
        sx={{
          fontWeight: "body",
          fontSize: "subText",
          lineHeight: "register_bag",
        }}
      >
        {lable}
      </Text>
      <Box
        className="custome-select"
        sx={{ pt: "8px", width: "455px" }}
        data-testid="advanceFilter-Location-box"
      >
        <Select
          isSearchable={true}
          styles={customStyles}
          isDisabled={disabled}
          value={selectedLocation}
          data-testid="advanceFilter-Location-select"
          placeholder={t("ManageSealBags.select_location")}
          options={options}
          onChange={(value: any) => {
            onUpdateLocation(value);
          }}
        />
      </Box>
    </Flex>
  );
};
