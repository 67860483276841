import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  Api,
  GetLocationWithContactsResponse,
  StoreLocationWithContact,
  OpeningHour,
} from "../api/Api";
import { groupByDay } from "../utils";

export interface LocationDetailType {
  locationId: string;
}

export type OpeningTimeMap = Record<string, OpeningHour[]>;

// Define a type for the slice state
interface locationDetailSliceState {
  id: string;
  loading: boolean;
  locationDetails: StoreLocationWithContact | null;
  openingHours: OpeningTimeMap | null;
}

// Define the initial state using that type
const initialState: locationDetailSliceState = {
  id: "",
  loading: false,
  locationDetails: null,
  openingHours: null
};

export const getLocationDetails = createAsyncThunk<
  any,
  any,
  {}
>("locationDetails", (data, thunkAPI) => {
  const res = new Api().locations.getLocationDetails(data.locationId, {});
  return res;
});

export const locationDetailSlice = createSlice({
  name: "locationDetailSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getLocationDetails.pending.toString()]: (
      state: locationDetailSliceState
    ) => {
      state.loading = true,
      state.locationDetails = null
    },
    [getLocationDetails.fulfilled.toString()]: (
      state: locationDetailSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
        (state.locationDetails = payload.data.locations[0]),
        (state.openingHours = groupByDay(payload.data.locations[0].openingHours))
    },
    [getLocationDetails.rejected.toString()]: (
      state: locationDetailSliceState
    ) => {
      state.loading = false
    },
  },
});

export const {} = locationDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default locationDetailSlice.reducer;
