// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";

import { Flex, Text } from "theme-ui";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { RowElement } from "./RowElement";
import { RowLocationElement } from "./RowLocationElement";
import { RowMerchantElement } from "./RowMerchantElement";
import { getUserType } from "../../../shared/utils/UserTypeUtils";
import { getUserStatus } from "../../../shared/utils/UserStatusUtils";
import { UserRoleLabel } from "../../../shared/components/UserRoleLabel";
import { UserLocation } from "../../../../../Hooks/useUserWithLocations";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
export type UserListRowProps = UserLocation;

export const UserRow = ({ 
  type,
  userId,
  status,
  lastName,
  locations,
  firstName,
}: UserListRowProps) => {
  const { t } = useTranslation();

  const userTypeText = getUserType(type, t);
  const { dotColor, statusText } = getUserStatus(`${status}`, t);

  return (
    <Link
      key={userId}
      style={{ textDecoration: "none" }}
      to={{ pathname: `/users/${userId}` }}
      onClick={() => {
        TagManager.dataLayer({
          dataLayer: {
            event: "Click_event",
            Action: "Select User",
            Page_title: "User management | User overview",
          },
        });
      }}
    >
      <Flex
        py={24}
        data-testid="User-details"
        sx={{
          cursor: "pointer",
          borderBottom: "1px solid",
          borderColor: "borderColor",
          ":hover": {
            backgroundColor: "rgba(233, 240, 251, 0.27);",
          },
        }}
      >
        <RowElement width="20%" orientation="left">{`${firstName} ${lastName}`}</RowElement>
        <RowElement width="30%" orientation="left">
          <RowLocationElement locations={locations} />
        </RowElement>
        <RowElement width="20%" orientation="left">
          <RowMerchantElement locations={locations}  />
        </RowElement>
        <RowElement width="15%" orientation="center">
          <Flex sx={{ gap: 2, alignItems: 'center' }}>
            <Text sx={{ fontSize: "48px", color: dotColor }}>
              &#183;
            </Text>
            <Text sx={{ color: "shade_500", fontSize: "subText" }} >
              {statusText}
            </Text>
          </Flex>
        </RowElement>
        <RowElement width="15%" orientation="center">
          <UserRoleLabel type={type} userType={userTypeText} />
        </RowElement>
      </Flex>
    </Link>
  );
}
