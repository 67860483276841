import { useTranslation } from "react-i18next";
import { Box, Flex, Label, Heading, Input } from "theme-ui";
import Select from "react-select";
import { UserType } from "@brinks/common/constants/users";
import { InputDisabledStyle } from "../../../helpers/styles";

interface Props {
  state: any;
  handleState: (key: string, value: string) => void;

}
export default function UserAccess({ state, handleState }: Props) {
  const { t } = useTranslation();
  const option = [
    { value: UserType.STANDARD_USER, label: "Standard User",isFixed: true  },
    { value: UserType.SUPER_USER, label: "Super User" ,isFixed: true },
  ];
  return (
    <>
      <Heading
        mt={35}
        sx={{
          fontSize: "medium",
        }}
      >
        {t("AddNewUser.user_access")}
      </Heading>
      <Flex
        bg="white"
        sx={{
          p: 35,
          mt: 35,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
        data-testid="useraccess"
      >
        <Flex data-testid="useraccess-flex">
          <Box sx={{ width: 455 }} data-testid="useraccess-box-username">
            <Label data-testid="useraccess-box-username-label" mb={3}>
              {t("AddNewUser.username")}
            </Label>
            <div data-testid="useraccess-box-username-input" style={{...InputDisabledStyle, height: "54px"}}>
              { (!state.errors.email && state.fields.email) || t("AddNewUser.usernameIsBasedOnEmail") }
            </div>
          </Box>
          <Box sx={{ width: 455 }} ml={24} data-testid="useraccess-box-type">
            <Label data-testid="useraccess-box-username-label-type" mb={3}>
              {t("AddNewUser.type")}
            </Label>
            <Select className="basic-single"  defaultValue={option[0]} classNamePrefix="select" isClearable={true} name="type"  options={option}  onChange={(e:any)=>{
              handleState('type',e?.value)
            }}/>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}
