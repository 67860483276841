import { Box, Flex, Input } from "theme-ui";
import { SearchLocationIcon } from "@brinks/common/Icons/Components/searchLocation";

interface Props {
  searchLocation: (search: string) => void;
  placeholder: string;
}
export default function GlobalSearch({ searchLocation, placeholder }: Props) {
  return (
    <Flex
      sx={{
        alignItems: "center",
      }}
    >
      <Box sx={{ position: "absolute", px: 17, pt: '6px' }}>
        <SearchLocationIcon color={"shade_500"} />
      </Box>
      <Input
        type={"text"}
        onChange={(e: any) => {
          searchLocation(e.target.value);
        }}
        placeholder={placeholder}
        sx={{
          bg: "white",
          border: "1px solid",
          borderColor: "shade_200",
          borderRadius: 8,
          textAlign: "left",
          pl: 50,
          color: "shade_400",
          minWidth: 240,
          mt: 1,
        }}
      />
    </Flex>
  );
}
