import { Box, Text, Flex, CSSProperties } from "theme-ui";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import TagManager from "react-gtm-module";

const customControlStyles: CSSProperties = {
  height: "61px",

};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

export default function Language() {
  const { t, i18n } = useTranslation();
  const options = [
    { value: "nl", label: t("Dutch") },
    { value: "en", label: t("English") },
    // { value: "de", label: t("German") },
    // { value: "fr", label: t("French") },
  ];
  const currentLanguageCode = cookies.get("i18next") || "en";
  return (
    <Flex
      data-testid="advanceFilterLocation"
      bg={"white"}
      sx={{

        flexDirection: "column",
        px: 40,
        py: 40,
        borderBottom: "1px solid",
        borderColor: "borderColor",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: 8,


      }}
    >
      <Text
        sx={{
          fontWeight: "body",
        }}
      >
        {t("Setting.select")} {t("Setting.language")}
      </Text>
      <Box
        data-testid="advanceFilter-Location-box"
        className="custome-select"
        sx={{
          pt: "30px",
          width: "343px",
        }}
      >
        <Select
          value={options.filter(
            (option) => option.value === currentLanguageCode
          )}
          data-testid="advanceFilter-Location-select"
          isSearchable={true}
          styles={customStyles}
          placeholder={t("Setting.select_language")}
          options={options}
          onChange={(e: any) => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Settings | Settings - Select language",
                Action: "Select language " + e.value
              }
            });
            i18n.changeLanguage(e.value);
          }}
        />
      </Box>
    </Flex>
  );
}
