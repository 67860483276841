import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import {
  Box,
  Flex,
  Button,
  Divider,
  Label,
  Text,
  Alert,
  Close,
} from "theme-ui";
import {
  setSuccessSupportCaseMsg,
  submitSupportCase,
  resetState,
} from "@brinks/common/reducers/supportCaseSlice";
import {
  resetWebFilter,
  setSelectedLocation,
} from "@brinks/common/reducers/storeLocationSlice";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { getCurrentUser } from "../../../utils/secure-storage-utils";
import ConfirmationModal from "../ConfirmationModal";
import { getBase64 } from "@brinks/common/utils";
import { SaveFileRequest } from "@brinks/common/api/Api";
import { setCurrentMerchant } from "@brinks/common/reducers/merchantsDetailSlice";
import Loader from "../../../Shared/Loader";
interface ReviewNewCaseDetailsProps {
  updateStep: (step: number) => void;
}

export default function ReviewNewCaseDetails({
  updateStep,
}: ReviewNewCaseDetailsProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = getCurrentUser();
  const [isConfirmationModalOpen, toggleConfirmationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); 

  const {
    selectedSubjet,
    subjetDescription,
    selectedFiles,
    supportCaseResponse,
    supportCaseResponseError,
  } = useAppSelector((state) => state.supportCaseWithMerchantSlice);

  const { loading, loadingCreateCase } = useAppSelector(
    (state) => state.supportCaseSlice
  );

  const { selectedLocation } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const { currentMerchant } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );

  useEffect(() => {
    if (supportCaseResponse) {
      navigate(`/support/list`);
      resetCasesStates();
      dispatch(resetState());
      dispatch(resetWebFilter());
      dispatch(setSuccessSupportCaseMsg(true));
    }
  }, [supportCaseResponse]);

  const resetCasesStates = () => {
    dispatch(setCurrentMerchant(null));
  };

  const submitCase = async () => {
    toggleConfirmationModal(!isConfirmationModalOpen);

    const createCase: any = {
      subject: selectedSubjet,
      description: subjetDescription,
      merchantId: currentMerchant?.id,
      locationId: selectedLocation != null ? selectedLocation[0]?.id : "",
      files: await Promise.all(
        Array.from(selectedFiles).map(
          async (item): Promise<SaveFileRequest> => {
            const fileContent = (await getBase64(item)) as string;
            return {
              fileContent: fileContent && fileContent.split(",")[1],
              fileName: item.name,
            };
          }
        )
      ),
      locationSalesforceId:
        selectedLocation != null
          ? selectedLocation[0]?.externalReferenceSf
          : "",
    };

    dispatch(submitSupportCase({ case: createCase }));
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Support | Create a Case - Submit Case",
        Action: "Create a Case Successfull",
      },
    });
    setIsSubmitting(false);
  
  };

  if (isSubmitting || loading || loadingCreateCase) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Flex
        mt={32}
        bg="white"
        sx={{
          p: 35,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Flex
            sx={{
              fontSize: "medium",
              fontFamily: "heading",
              fontWeight: "bold",
            }}
          >
            {t("support.case_details")}
          </Flex>
          <Divider sx={{ width: 945 }} mt={16} mb={24} color="divider" />
          <Flex
            data-testid="LocationName-field1"
            sx={{
              flexDirection: "column",
            }}
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="LocationName-name"
            >
              {t("support.location_name")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="LocationName-value"
            >
              {selectedLocation ? selectedLocation[0].name : ""}
            </Text>
          </Flex>
          <Flex
            mt={20}
            data-testid="LocationName-field1"
            sx={{
              flexDirection: "column",
            }}
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="MerchantName-name"
            >
              {t("ManageOrders.merchant_name")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="MerchantName-value"
            >
              {currentMerchant ? currentMerchant.name : ""}
            </Text>
          </Flex>
          <Flex
            mt={39}
            data-testid="Subject-field1"
            sx={{
              flexDirection: "column",
            }}
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="Subject-name"
            >
              {t("support.subject")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="Subject-name-value"
            >
              {selectedSubjet}
            </Text>
          </Flex>
          <Flex
            mt={39}
            data-testid="Description-field1"
            sx={{
              flexDirection: "column",
            }}
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="Description-name"
            >
              {t("support.description")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
                whiteSpace: "pre-wrap",
              }}
              mt={10}
              data-testid="Description-name-value"
              dangerouslySetInnerHTML={{
                __html: subjetDescription.replace(/\n/g, "<br />"),
              }}
            ></Text>
          </Flex>
          {Array.from(selectedFiles).length > 0 && (
            <Flex
              mt={39}
              data-testid="Description-field1"
              sx={{
                flexDirection: "column",
              }}
            >
              <Label
                sx={{
                  fontSize: "subText",
                  color: "seal_bag",
                  fontWeight: "normal",
                  lineHeight: "register_bag",
                }}
                data-testid="Description-name"
              >
                {t("support.files")}
              </Label>
              <ol
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 0,
                  paddingLeft: 30,
                }}
              >
                {Array.from(selectedFiles).map(function (item, i) {
                  return (
                    <li
                      key={i}
                      style={{
                        color: "var(--theme-ui-colors-text)",
                        marginBottom: 8,
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ol>
            </Flex>
          )}
          <Flex
            mt={39}
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            data-testid="LocationDetails-phone"
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="LocationDetails-phone-label"
            >
              {t("support.created_by")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="LocationDetails-phone-value"
            >
              {user?.profile?.firstName} {user?.profile?.lastName}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Flex mt={20} sx={{ flexDirection: "row-reverse" }}>
        <Button
          mt={20}
          variant="primary"
          disabled={isSubmitting}
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Support | Order Change - Review Order",
                Action: "Confirm",
              },
            });
            toggleConfirmationModal(!isConfirmationModalOpen);
          }}
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            "&:disabled": {
              bg: "shade_200",
              color: "shade_400",
              cursor: "not-allowed",
            },
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            float: "right",
          }}
        >
          {t("ManageSealBags.confirm")}
        </Button>
        <Button
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Support | Order Change - Review Order",
                Action: "Cancel",
              },
            });
            resetCasesStates();
            dispatch(resetState());
            dispatch(resetWebFilter());
            navigate(`/support`);
          }}
          mt={20}
          mr={20}
          variant="muted"
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            backgroundColor: "background",
            color: "royalBlue_500",
            border: "1px solid",
            borderColor: "royalBlue_500",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
      <ConfirmationModal
        toggleConfirmationModal={toggleConfirmationModal}
        isConfirmationModalOpen={isConfirmationModalOpen}
        submit={() => {
          submitCase();
          setIsSubmitting(true);
        }}
        confirmationMsg={t("support.supportCase_confirmation")}
        btnText={t("support.create_case")}
        orderType={"create"}
      />

      {supportCaseResponseError && (
        <Alert
          data-testid="error-alert"
          sx={{
            position: "fixed",
            left: "50%",
            top: "13%",
            width: 496,
            boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.1)",
            borderRadius: 4,
            fontSize: "subText",
            fontWeight: "weight_400",
          }}
        >
          {supportCaseResponseError?.code + ": Some thing went wrong!"}
          <Close
            ml="auto"
            sx={{
              cursor: "pointer",
            }}
            mr={-2}
            onClick={() => {
              dispatch(resetState());
              dispatch(resetWebFilter());
              updateStep(1);
            }}
            data-testid="new-case-error-alert-close-icon"
          />
        </Alert>
      )}
    </>
  );
}
