// ------------------------------------------------------------
// EXTERNAL IMPORTS
// ------------------------------------------------------------
import "react-datepicker/dist/react-datepicker.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import TagManager from "react-gtm-module";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select, { StylesConfig } from "react-select";
import { Box, Flex, Label, Button, Input, CSSProperties } from "theme-ui";

// ------------------------------------------------------------
// INTERNAL IMPORTS
// ------------------------------------------------------------
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { convertToDateTime, getFutureDateByDays } from "@brinks/common/utils";
import {
  resetState,
  setPickupendDate,
  setPickupStartDate,
  setPickupFrequency,
} from "@brinks/common/reducers/orderDetailsSlice";

// ------------------------------------------------------------
// VARIABLES
// ------------------------------------------------------------
const customControlStyles: CSSProperties = {
  width: "455px",
  height: "61px",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

// ------------------------------------------------------------
// COMPONENTS
// ------------------------------------------------------------
export default function SelectTimeslotDetails({ nextStep }: any) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    pickupendDate,
    pickupStartDate,
    pickupFrequency,
    locationBusinessRules,
    selectedPickupLocation,
    selectedPickupOrderMerchant
  } = useAppSelector((state) => state.orderDetailsSlice);

  const [endDate, setEndDate] = useState<Date>();
  const [frequency, setFrequency] = useState(pickupFrequency);
  const [startDate, setStartDate] = useState<Date>(pickupStartDate);
  
  const options = [
    { value: "ONCE", label: t("ManageOrders.once") },
    { value: "EVERY_1_WEEK", label: t("ManageOrders.one_week") },
    { value: "EVERY_2_WEEKS", label: t("ManageOrders.two_weeks") },
    { value: "EVERY_4_WEEKS", label: t("ManageOrders.four_weeks") },
  ];

  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const cod = locationBusinessRules && locationBusinessRules?.transportCod

  const getWeekDayName = (cod: any) => {
    const weekDayNo = moment(getFutureDateByDays(cod)).day()
    const allowTransportProp = `allowTransport${weekday[weekDayNo]}`;
    return allowTransportProp;
  }


  let pickUpDate = moment(getFutureDateByDays(cod)).format("YYYY-MM-DD")
  
  if (locationBusinessRules && locationBusinessRules[getWeekDayName(cod)]) {
    pickUpDate = moment(getFutureDateByDays(cod)).format("YYYY-MM-DD")
  } else if (locationBusinessRules && locationBusinessRules[getWeekDayName(cod + 1)]) {
    pickUpDate = moment(getFutureDateByDays(cod + 1)).format("YYYY-MM-DD")

  } else if (locationBusinessRules && locationBusinessRules[getWeekDayName(cod + 2)]) {
    pickUpDate = moment(getFutureDateByDays(cod + 2)).format("YYYY-MM-DD")

  } else if (locationBusinessRules && locationBusinessRules[getWeekDayName(cod + 3)]) {
    pickUpDate = moment(getFutureDateByDays(cod + 3)).format("YYYY-MM-DD")

  } else if (locationBusinessRules && locationBusinessRules[getWeekDayName(cod + 4)]) {
    pickUpDate = moment(getFutureDateByDays(cod + 4)).format("YYYY-MM-DD")

  } else if (locationBusinessRules && locationBusinessRules[getWeekDayName(cod + 5)]) {
    pickUpDate = moment(getFutureDateByDays(cod + 5)).format("YYYY-MM-DD")

  } else if (locationBusinessRules && locationBusinessRules[getWeekDayName(cod + 6)]) {
    pickUpDate = moment(getFutureDateByDays(cod + 6)).format("YYYY-MM-DD")
  }
  
  const isAllowTransportDay = (date: Date) => {
    const day = date.toLocaleDateString('US-en', { weekday: 'short' });
    const paramName  = `allowTransport${day}`;
    return locationBusinessRules[paramName];
  };

  useEffect(() => {
    dispatch(setPickupStartDate(convertToDateTime(pickUpDate)));
  }, []);

  const checkNextStep = () => {
    nextStep();
    if (!selectedPickupLocation) {
    } else {
      nextStep();
    }
  };

  return (
    <>
      <Flex
        bg="white"
        sx={{
          p: 35,
          borderRadius: 8,
          flexDirection: "column",
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        data-testid="LocationList"
      >
        <Box sx={{ width: 455 }}>
          <Label
            sx={{
              fontWeight: "body",
              fontFamily: "body",
              marginBottom: "8px",
              fontSize: "subText",
              lineHeight: "register_bag",
            }}
          >
            {t("ManageOrders.pickup_location")}
          </Label>
          <Input
            disabled
            name="location_name"
            sx={{ height: "61px" }}
            defaultValue={selectedPickupLocation.name}
          />
        </Box>
        <Box sx={{ width: 455 }} mt={32}>
          <Label
            sx={{
              fontWeight: "body",
              fontFamily: "body",
              marginBottom: "8px",
              fontSize: "subText",
              lineHeight: "register_bag",
            }}
          >
            {t("ManageOrders.merchant_name")}
          </Label>
          <Input
            disabled
            name="location_name"
            sx={{ height: "61px" }}
            defaultValue={
              selectedPickupOrderMerchant
                ? selectedPickupOrderMerchant.name
                : selectedPickupLocation?.merchant.name
            }
          />
        </Box>
        <Box sx={{ width: 455 }} mt={32}>
          <Label
            sx={{
              fontWeight: "body",
              fontFamily: "body",
              marginBottom: "8px",
              fontSize: "subText",
              lineHeight: "register_bag",
            }}
          >
            {t("ManageOrders.pickup_date")}
          </Label>
          <DatePicker 
            dateFormat="dd/MM/yyyy"
            selected={pickupStartDate} 
            filterDate={isAllowTransportDay}
            minDate={convertToDateTime(pickUpDate)} 
            data-testid="advanceFilter-Period-input-start"
            onChange={(e: Date) => {
              setStartDate(e);
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Action: "Pickup date " + e,
                  Page_title: "Order management | Order Pickup - Select Date and frequency",
                }
              });
              dispatch(setPickupStartDate(e));
            }}
          />
        </Box>
        <Flex mt={32}>
          <Box sx={{ width: 455 }}>
            <Label
              sx={{
                fontFamily: "body",
                fontWeight: "body",
                fontSize: "subText",
                marginBottom: "8px",
                lineHeight: "register_bag",
              }}
            >
              {t("ManageOrders.frequency")}
            </Label>
            <Select
              options={options}
              isSearchable={true}
              styles={customStyles}
              placeholder={t("Setting.select_language")}
              data-testid="advanceFilter-Location-select"
              value={options.filter(
                (option) => option.value === frequency.value
              )}
              onChange={(e: any) => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "Click_event",
                    Action: "Frequency " + e.value,
                    Page_title: "Order management | Order Pickup - Select Date and frequency",
                  }
                });
                setFrequency(e);
                dispatch(setPickupFrequency(e));
                dispatch(setPickupendDate(null));
              }}
            />
          </Box>
          {frequency.value !== "ONCE" && (
            <Box sx={{ width: 455 }} ml={35}>
              <Label
                sx={{
                  fontFamily: "body",
                  fontWeight: "body",
                  fontSize: "subText",
                  marginBottom: "8px",
                  lineHeight: "register_bag",
                }}
              >
                {t("ManageOrders.end_date")} ({t("ManageOrders.optional")})
              </Label>
              <DatePicker 
                dateFormat="dd/MM/yyyy"
                selected={pickupendDate} 
                placeholderText="dd/mm/yyyy"
                minDate={convertToDateTime(pickUpDate)} 
                data-testid="advanceFilter-Period-input-start"
                onChange={(e: Date) => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "Click_event",
                      Action: "End date " + e,
                      Page_title: "Order management | Order Pickup - Select Date and frequency",
                    }
                  })
                  dispatch(setPickupendDate(e));
                }}
              />
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex mt={20} sx={{ flexDirection: "row-reverse" }}>
        <Button
          onClick={(e: any) => checkNextStep()}
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            height: 49,
            float: "right",
            cursor: "pointer",
            borderRadius: "40px",
            pointerEvents: `auto`,
            width: ["100%", "169px"],
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            "&:disabled": {
              bg: "shade_200",
              color: "shade_400",
              cursor: "not-allowed",
            },
          }}
        >
          {t("ManageSealBags.next")}
        </Button>
        <Button
          mr={20}
          variant="muted"
          onClick={() => {
            navigate(`/orders`);
            dispatch(resetState());
          }}
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            height: 49,
            cursor: "pointer",
            border: "1px solid",
            borderRadius: "40px",
            pointerEvents: `auto`,
            color: "royalBlue_500",
            width: ["100%", "169px"],
            borderColor: "royalBlue_500",
            backgroundColor: "background",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
    </>
  );
}
