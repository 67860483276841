import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Api,
  Case,
  GetPostsResponse,
  Post,
  Comment,
  SaveCaseRequest,
  GetCommentsResponse,
  CaseTemplate,
  BrinksOds001,
  BrinksOds007,
} from "../api/Api";

// Define a type for the slice state
interface supportCaseSliceState {
  loading: boolean;
  loadingCreateCase: boolean;
  loadingPost: boolean;
  selectedLocation: any;
  cases: Case[] | null;
  posts: Post[] | null;
  comments: Comment[] | null;
  templates: CaseTemplate[] | null;
  selectedSubjet: string | null;
  subjetDescription: any;
  selectedFiles: File[];
  selectedPostFiles: File[];
  selectedCommentFiles: File[];
  supportCaseResponse: any;
  newPostResponse: GetPostsResponse | null;
  supportCaseResponseError: BrinksOds001 | BrinksOds007;
  newPostResponseError: string;
  successSupportCase: Boolean;
  loadingComment: boolean;
  loadingTemplates: boolean;
  newCommentResponse: GetCommentsResponse | null;
  newCommentResponseError: string;
}

// Define the initial state using that type
const initialState: supportCaseSliceState = {
  loading: false,
  loadingCreateCase: false,
  loadingPost: false,
  cases: null,
  posts: null,
  comments: null,
  templates: null,
  loadingTemplates: false,
  selectedLocation: "",
  selectedSubjet: "",
  subjetDescription: "",
  selectedFiles: [],
  selectedPostFiles: [],
  selectedCommentFiles: [],
  supportCaseResponse: null,
  supportCaseResponseError: null,
  newPostResponseError: "",
  successSupportCase: false,
  newPostResponse: null,
  loadingComment: false,
  newCommentResponse: null,
  newCommentResponseError: "",
};

export const getTemplates = createAsyncThunk<any, any, {}>(
  "get-templates",
  (data, thunkAPI) => {
    const res = new Api().cases.getTemplates({ countryCode: data || `XX` });
    return res;
  }
);

export const getCases = createAsyncThunk<any, any, {}>(
  "Cases",
  (data, thunkAPI) => {
    const res = new Api().cases.getCases(data.transactionType);
    return res;
  }
);

export const getPosts = createAsyncThunk<any, any, {}>(
  "Posts",
  (caseSalesforceId, thunkAPI) => {
    const res = new Api().cases.getPosts(caseSalesforceId);
    return res;
  }
);
export const getComments = createAsyncThunk<any, any, {}>(
  "Comments",
  ({ caseSalesforceId, postSalesforceId }, thunkAPI) => {
    const res = new Api().cases.getComments(caseSalesforceId, postSalesforceId);
    return res;
  }
);
export const submitComment = createAsyncThunk<any, any, {}>(
  "submitComment",
  ({ caseSalesforceId, postSalesforceId, text, files }, thunkAPI) => {
    const res = new Api().cases.saveComment(
      caseSalesforceId,
      postSalesforceId,
      { text, files }
    );
    return res;
  }
);

export const submitSupportCase = createAsyncThunk<any, any, {}>(
  "submitSupportCase",
  async (data: any, { rejectWithValue }) => {
    try {
      const res = await new Api().cases.saveCase(data);
      return res;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitPost = createAsyncThunk<any, any, {}>(
  "submitPost",
  (data: any, thunkAPI) => {
    const res = new Api().cases.savePost(data.id, { ...data });
    return res;
  }
);
export const supportCaseSlice = createSlice({
  name: "supportCaseSlice",
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setSubject: (state, action) => {
      state.selectedSubjet = action.payload;
      state.subjetDescription = state.templates?.find(
        (t) => t.subject === action.payload
      )?.description;
    },
    setsubjetDescription: (state, action) => {
      state.subjetDescription = action.payload;
    },
    setFiles: (state, action) => {
      state.selectedFiles = action.payload;
    },
    setPostFiles: (state, action) => {
      state.selectedPostFiles = action.payload;
    },
    setCommentFiles: (state, action) => {
      state.selectedCommentFiles = action.payload;
    },
    setSuccessSupportCaseMsg: (state, action) => {
      state.successSupportCase = action.payload;
    },
    resetComment: (state) => {
      state.comments = null;
      state.selectedCommentFiles = [];
    },
    resetFiles: (state) => {
      state.selectedFiles = [];
    },
    resetPostFiles: (state) => {
      state.selectedPostFiles = [];
    },
    resetCommentFiles: (state) => {
      state.selectedCommentFiles = [];
    },
    resetState: () => initialState,
  },
  extraReducers: {
    [getCases.pending.toString()]: (state: supportCaseSliceState) => {
      (state.loading = true), (state.cases = null);
    },
    [getCases.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
        (state.cases = payload.data.cases.sort(function (
          a: { lastModifiedDate: string },
          b: { lastModifiedDate: string }
        ) {
          return b.lastModifiedDate > a.lastModifiedDate ? 1 : -1;
        }));
    },
    [getPosts.pending.toString()]: (state: supportCaseSliceState) => {
      (state.loadingPost = true), (state.posts = null);
    },
    [getPosts.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loadingPost = false), (state.posts = getPostsWithSorting(payload));
    },
    [getPosts.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loadingPost = false;
    },
    [getComments.pending.toString()]: (state: supportCaseSliceState) => {
      state.loadingComment = true;
    },
    [getComments.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loadingComment = false),
        (state.comments = getCommentsWithSorting(payload));
    },
    [getComments.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loadingComment = false;
    },
    [getTemplates.pending.toString()]: (state: supportCaseSliceState) => {
      state.loadingTemplates = true;
    },
    [getTemplates.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loadingTemplates = false),
        (state.templates = payload.data.caseTemplates);
    },
    [getTemplates.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loadingTemplates = false;
    },
    [getCases.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loading = false;
    },
    [submitSupportCase.pending.toString()]: (state: supportCaseSliceState) => {
      state.loading = true;
      state.loadingCreateCase = true;
    },
    [submitSupportCase.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      state.loading = false;
      state.loadingCreateCase = false;
      state.supportCaseResponse = payload.data;
    },
    [submitSupportCase.rejected.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      state.loading = false;
      state.loadingCreateCase = false;
      state.supportCaseResponseError = payload;
    },
    [submitPost.pending.toString()]: (state: supportCaseSliceState) => {
      state.loadingPost = true;
    },
    [submitPost.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loadingPost = false),
        (state.newPostResponse = payload.data),
        (state.selectedPostFiles = []);
    },
    [submitPost.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loadingPost = false;
      state.newPostResponseError = "Something went wrong";
    },

    [submitComment.pending.toString()]: (state: supportCaseSliceState) => {
      state.loadingComment = true;
    },
    [submitComment.fulfilled.toString()]: (
      state: supportCaseSliceState,
      { payload }: any
    ) => {
      (state.loadingComment = false),
        (state.newCommentResponse = payload.data),
        (state.selectedCommentFiles = []);
    },
    [submitComment.rejected.toString()]: (state: supportCaseSliceState) => {
      state.loadingComment = false;
      state.newCommentResponseError = "Something went wrong";
    },
  },
});

export const {
  resetState,
  setLocation,
  setSubject,
  setsubjetDescription,
  setFiles,
  setPostFiles,
  setCommentFiles,
  setSuccessSupportCaseMsg,
  resetComment,
  resetFiles,
  resetPostFiles,
  resetCommentFiles,
} = supportCaseSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default supportCaseSlice.reducer;
function getPostsWithSorting(payload: any): Post[] | null {
  return [...payload.data.posts].sort(function (
    a: { createdDate: string },
    b: { createdDate: string }
  ) {
    return b.createdDate > a.createdDate ? -1 : 1;
  });
}
function getCommentsWithSorting(payload: any): Comment[] | null {
  return [...payload.data.comments].sort(function (
    a: { createdDate: string },
    b: { createdDate: string }
  ) {
    return b.createdDate > a.createdDate ? -1 : 1;
  });
}
