// ----------------------------------------------------------------
// INTERNAL IMPORT
// ----------------------------------------------------------------
import { 
  StoreLocation, 
  StoreLocationWithMerchant, 
  MerchantWithDependentsAndLocations, 
} from "@brinks/common/api/Api";

// ----------------------------------------------------------------
// TYPES
// ----------------------------------------------------------------
export interface Options {
  label: string;
  value: string;
}

// ----------------------------------------------------------------
// UTILS
// ----------------------------------------------------------------
const extractMerchants = (
  merchant: MerchantWithDependentsAndLocations, 
  result: MerchantWithDependentsAndLocations[] = [], 
  seenIds: Set<string> = new Set()
): void => {
  if (merchant.id && seenIds.has(merchant.id)) {
    return;
  }

  if (merchant.id) {
    seenIds.add(merchant.id);
  }

  result.push(merchant);

  if (merchant.dependents) {
    merchant.dependents.forEach(dependent => {
      extractMerchants(dependent, result, seenIds);
    });
  }
}

export const extractAllMerchantsByStoreLocations = (stores: StoreLocationWithMerchant[]): MerchantWithDependentsAndLocations[] => {
  let seenIds: Set<string> = new Set();
  let merchants: MerchantWithDependentsAndLocations[] = [];

  stores.forEach(store => {
    if(store.merchant) {
      extractMerchants(store.merchant, merchants, seenIds);
    }
  });

  return merchants;
}

export const extractAllMerchantsByStoreLocationsAndMerchants = (
  stores: StoreLocationWithMerchant[],
  merchants: MerchantWithDependentsAndLocations[]
): MerchantWithDependentsAndLocations[] => {
 
  const merchantsIds: string[] = stores.map(({ merchantId }) => merchantId ?? '');

  let merchantsToReturn: MerchantWithDependentsAndLocations[] = [];

  merchantsIds.forEach(id => {
    const merchant = merchants.find(({ id: merchantId }) => id ===  merchantId);
    const alreadyAdded = merchantsToReturn.find(merchantToReturn => merchantToReturn.id === id);

    if(merchant && !alreadyAdded) {
      merchantsToReturn.push(merchant);
    }
  });

  return merchantsToReturn;
}

export const extractAllLocationsFromMerchants = (merchants: MerchantWithDependentsAndLocations[]): StoreLocation[] => {
  return merchants.reduce((locations, merchant) => {
    if (merchant.locations) {
      locations.push(...merchant.locations);
    }
    
    return locations;
  }, [] as StoreLocation[])
}
export const extractAllLocationsFromStoreLocationWithMerchants = (stores: StoreLocationWithMerchant[]): StoreLocationWithMerchant[] => {
  const merchants = extractAllMerchantsByStoreLocations(stores);
  const locations = extractAllLocationsFromMerchants(merchants);
  const locationWithMerchant = locations.map((location) => {
    return { ...location, merchant: merchants.find((merchant) => location.merchantId === merchant.id) || null } as StoreLocationWithMerchant;
  }) 
  
  return locationWithMerchant;
}


export const generateMerchantOptionsFromMerchantWithLocations = (merchants: MerchantWithDependentsAndLocations[]) => {
  return merchants.map(merchant => ({
    label: merchant.name,
    value: merchant.id ?? ''
  }) as Options);
}

export const countDistinctMerchants = (storeLocations: StoreLocation[]): number => {
  const uniqueMerchantIds = new Set(storeLocations.map(location => location.merchantId));
  return uniqueMerchantIds.size;
}