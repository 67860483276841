/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";
import { WorldIcon } from "@brinks/common/Icons/Components/world";
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';
import { useState } from "react";
import { CaretDownIcon } from "@brinks/common/Icons/Components/caret-down";
import { TickIcon } from "@brinks/common/Icons/Components/tick-color";
import TagManager from "react-gtm-module";

export default function LanguageSelector() {
  const [show, setShow] = useState(false);
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const { t, i18n } = useTranslation();
   const options = [
    { value: 'nl', label: 'Dutch' },
    { value: 'en', label: 'English' },
    { value: 'de', label: 'German' },
    { value: 'fr', label: 'French' }
   ]
  const currentLanguage = options.find((l) => l.value === currentLanguageCode)
  
  return (
    <>
    <div>
      <Flex
        onClick={() => {
          TagManager.dataLayer({ 
            dataLayer: {
              event: "Click_event",
              Page_title: "User registration | Enter verification code",
              Action: "Select language"
            }
          });
          setShow(!show);
        }}
      data-testid="lang-chaange"
      sx={{
        padding: "10px 16px 10px 10px",
        backgroundColor: "white",
        border: "1px solid",
        borderColor: "shade_200",
        borderRadius: 8,
        width: 144,
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        marginLeft: "140px"
      }}
    >
      <WorldIcon color={'royalBlue_850'} />
          {t(currentLanguage?.label? currentLanguage?.label : 'English')}
        <CaretDownIcon
            color={'shade_400'}
            onClick={() => {
                setShow(!show)
            }}
        />
    </Flex>
        {show &&
          <ul style={{ position:"absolute" ,listStyle: "none", padding: "0px", borderRadius: 8, border: "1px solid", borderColor: "shade_200", marginTop: "2px", width: "261px" ,marginLeft:"24px" }} >
      {options.map(({  label, value }) => (
        <li onClick={() => {
            setShow(false)
            i18n.changeLanguage(value)
          }}
          sx={{ color: currentLanguageCode === value
              ? `royalBlue_500`
            : `body`,
          }}
          key={value}
          style={{ padding: "10px 10px 10px 20px", cursor: "pointer" }}>
          {t(label)}
          <span sx={{
            float : "right"
          }} data-testid="box-tick" >
            {currentLanguageCode === value ? (
              <TickIcon color={'royalBlue_500'} />
            ) : (
              ""
            )}
            </span>
        </li>
      ))}
      </ul> } 
      </div>
      </>
  );
}
