import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Card, Flex, Button, Text, Input } from "theme-ui";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import Loader from "../../../Shared/Loader";
import { OrdersLocation } from "../OrdersLocation";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { OrderChangeMerchantSelector } from "./OrderChangeMerchantSelector";
import { resetWebFilter } from "@brinks/common/reducers/storeLocationSlice";
import { getSkus } from "@brinks/common/reducers/skusDetailSlice";
import { getRulesMoneyOrderSkus } from "@brinks/common/reducers/businessRulesMoneyOrdersSkusSlice";
import { getRulesMoneyOrders } from "@brinks/common/reducers/businessRulesMoneyOrdersSlice";
import { getDenominations } from "@brinks/common/reducers/denominationsSlice";
import { WarningMessageIcon } from "@brinks/common/Icons/Components/warningmsg";
import { StoreLocationWithMerchant } from "@brinks/common/api/Api";
import {
  resetState,
  setPickupLocation,
  setCurrentDenominationType,
  setCurrency,
  setGrandTotal,
  setTotalWithCurrency,
  setCoinTotalWithCurrency,
  setNoteTotalWithCurrency,
  setNoteTotal,
  setCoinTotal,
  handleDeclaredNoteQuantity,
  handleDeclaredCoinQuantity,
  setSelectedPickupDate,
  setCustomerReference,
  setTypeOfChange,
  getCashDevices
} from "@brinks/common/reducers/orderDetailsSlice";
import { SelectCurrency } from "../SelectCurrency";
import { getLocationServices } from "@brinks/common/reducers/locationServicesSlice";
import Select, { StylesConfig } from "react-select";
import { OrderChangeType } from "@brinks/common/constants/orderChangeType";

const customControlStyles: CSSProperties = {
  height: "61px",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

export const SelectLocation = ({ nextStep }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { storeLocations, loading } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const { loadingSkus, skus, currentSku } = useAppSelector(
    (state) => state.skusDetailSlice
  );

  const { denominations, loadingDenominations, currentCurrency } =
    useAppSelector((state) => state.denominationsSlice);

  const { businessRulesMoneyOrders, loadingBusinessRulesMoneyOrders } =
    useAppSelector((state) => state.businessRulesMoneyOrdersSlice);

  const { businessRulesMoneyOrdersSkus, loadingBusinessRulesMoneyOrdersSkus } =
    useAppSelector((state) => state.businessRulesMoneyOrdersSkusSlice);

  const {
    locationListDisabled,
    locationBusinessRules,
    selectedPickupLocation,
    selectedOrderChangeMerchant,
    selectedCurrency,
    customerReference,
    selectedTypeOfChange,
  } = useAppSelector((state) => state.orderDetailsSlice);

  if (locationBusinessRules && locationBusinessRules.allowNotes === false) {
    dispatch(setCurrentDenominationType("coins"));
  }

  useEffect(() => {
    onGetBusinessRulesSkus();
    if (businessRulesMoneyOrders) onGetLocationServices();
  }, [businessRulesMoneyOrders]);

  useEffect(() => {
    if (businessRulesMoneyOrdersSkus) onGetSkus();
  }, [businessRulesMoneyOrdersSkus]);

  useEffect(() => {
    dispatch(getDenominations(undefined));
  }, [currentSku]);

  const onGetMoneyOrderBusinessRules = (storeLocationId: string) => {
    dispatch(
      getRulesMoneyOrders({
        locationId: storeLocationId,
      })
    );
  };

  const onGetBusinessRulesSkus = () => {
    if (businessRulesMoneyOrders && businessRulesMoneyOrders.length > 0) {
      const businessRuleId = businessRulesMoneyOrders[0].id;

      dispatch(
        getRulesMoneyOrderSkus({
          businessRuleId: businessRuleId,
        })
      );
    }
  };

  const onGetSkus = () => {
    dispatch(getSkus(undefined));
  };

  const onGetLocationServices = () => {
    if (businessRulesMoneyOrders && businessRulesMoneyOrders.length > 0) {
      dispatch(getLocationServices(businessRulesMoneyOrders[0].serviceId));
    }
  };

  const onUpdateLocation = (data: Partial<StoreLocationWithMerchant>) => {
    dispatch(setPickupLocation(data));
    dispatch(setCurrency(null));
    onGetMoneyOrderBusinessRules(data.id || "");
    //Set total amount
    dispatch(setGrandTotal(0));
    dispatch(setTotalWithCurrency(0));

    //Set coins total
    dispatch(setCoinTotal(0));
    dispatch(setCoinTotalWithCurrency(0));

    //Set notes total
    dispatch(setNoteTotal(0));
    dispatch(setNoteTotalWithCurrency(0));

    //Reset state for notes and coins
    dispatch(handleDeclaredNoteQuantity([]));
    dispatch(handleDeclaredCoinQuantity([]));

    dispatch(setSelectedPickupDate(null));
  };

  const onUpdateCurrency = (currency: any) => {
    dispatch(setCurrency(currency));
    //Set total amount
    dispatch(setGrandTotal(0));
    dispatch(setTotalWithCurrency(0));

    //Set coins total
    dispatch(setCoinTotal(0));
    dispatch(setCoinTotalWithCurrency(0));

    //Set notes total
    dispatch(setNoteTotal(0));
    dispatch(setNoteTotalWithCurrency(0));

    //Reset state for notes and coins
    dispatch(handleDeclaredNoteQuantity([]));
    dispatch(handleDeclaredCoinQuantity([]));
  };

  const onUpdateTypeOfChange = (selectedOption: any) => {
    dispatch(setTypeOfChange(selectedOption.value));
     if (
      selectedPickupLocation &&
      selectedOption.value === OrderChangeType.ON_DEMAND
    ) {
      dispatch(getCashDevices({ storeId: selectedPickupLocation.id }));
    }
  };

  const locations = useMemo(() => {
    if (!storeLocations) return [];

    const locations = storeLocations;

    return selectedOrderChangeMerchant
      ? locations.filter(
          (location) => location.merchantId === selectedOrderChangeMerchant.id
        )
      : storeLocations;
  }, [selectedOrderChangeMerchant]);

  //Get the list of all currencies
  const [uniqueCurrencies, setUniqueCurrencies] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [currenciesListDisabled, setCurrenciesListDisabled] = useState(false);

  useEffect(() => {
    if (skus && businessRulesMoneyOrdersSkus) {
      const filteredSkus = skus.filter((sku) =>
        businessRulesMoneyOrdersSkus.some(
          (businessSku) => businessSku.skuId === sku.id
        )
      );

      if (filteredSkus.length > 0) {
        const currencies = filteredSkus
          .map((sku) => sku.denomination?.currency)
          .filter((currency) => currency !== undefined) as string[];

        const uniqueCurrenciesSet = new Set(currencies);

        const transformedCurrencies = Array.from(uniqueCurrenciesSet).map(
          (currency) => ({
            label: currency,
            value: currency,
          })
        );

        setUniqueCurrencies(transformedCurrencies);
        if (transformedCurrencies.length === 1) {
          setCurrenciesListDisabled(true);
          dispatch(setCurrency(transformedCurrencies[0]));
        } else {
          setCurrenciesListDisabled(false);
        }
      }
    }
  }, [skus, businessRulesMoneyOrdersSkus]);

  const options = [
    {
      label: t("ManageOrders.dynamic_change_order"),
      value: OrderChangeType.DYNAMIC,
    },
    {
      label: t("ManageOrders.on_demand_change_order"),
      value: OrderChangeType.ON_DEMAND,
    },
  ];

  if (
    loading ||
    loadingBusinessRulesMoneyOrders ||
    loadingBusinessRulesMoneyOrdersSkus ||
    loadingDenominations ||
    loadingSkus
  ) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Card
        bg="white"
        data-testid="LocationDetails-container-card1"
        sx={{
          margin: "0 auto",
          borderRadius: "8px",
          alignItems: "center",
          padding: "32px 32px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            width: "311px;",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Text
            data-testid="flex-location-text"
            sx={{
              fontWeight: "body",
              fontSize: "subText",
              lineHeight: "register_bag",
            }}
          >
            {t("ManageOrders.merchant")}
          </Text>
          <div style={{ width: "455px", marginTop: "8px" }}>
            <OrderChangeMerchantSelector value={selectedOrderChangeMerchant} />
          </div>
          <OrdersLocation
            locations={locations}
            disabled={locationListDisabled || locations.length === 1}
            selectedLocation={selectedPickupLocation}
            lable={t("ManageOrders.delivery_location")}
            onUpdateLocation={(data) => {
              onUpdateLocation(data);

              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Action: "Select location",
                  Page_title:
                    "Order management | Order Change - Select location",
                },
              });
            }}
          />
          {selectedPickupLocation && selectedPickupLocation.physicalLocation.country === "NL" && (
            <Flex
              bg={"white"}
              data-testid="advanceFilterTypeOfChange"
              sx={{ zIndex: 999, flexDirection: "column", marginTop: "20px" }}
            >
              <Text
                data-testid="flex-TypeOfChange-text"
                sx={{
                  fontWeight: "body",
                  fontSize: "subText",
                  lineHeight: "register_bag",
                }}
              >
                {t("ManageOrders.preffered_delivery")}
              </Text>
              <Box
                className="custom-select"
                sx={{ pt: "8px", width: "455px" }}
                data-testid="advanceFilter-TypeOfChange-box"
              >
                <Select
                  isSearchable={true}
                  styles={customStyles}
                  isDisabled={false}
                  value={options.find(
                    (option) => option.value === selectedTypeOfChange
                  )}
                  data-testid="advanceFilter-TypeOfChange-select"
                  options={options}
                  onChange={(selectedOption: any) => {
                    onUpdateTypeOfChange(selectedOption);
                  }}
                />
              </Box>
            </Flex>
          )}
          <Flex
            bg={"white"}
            data-testid="customerReference"
            sx={{ zIndex: 999, flexDirection: "column", marginTop: "20px" }}
          >
            <Text
              data-testid="customer-reference-text"
              sx={{
                fontWeight: "body",
                fontSize: "subText",
                lineHeight: "register_bag",
              }}
            >
              {t("ManageOrders.customer_order_label")}
            </Text>
            <Box
              className="custome-select"
              sx={{ pt: "8px", width: "455px" }}
              data-testid="customer-reference-box"
            >
              <Input
                data-testid="customer-reference-box-input"
                placeholder={t("ManageOrders.customer_order_placeholder")}
                onChange={(e: any) => {
                  const inputValue = e.target.value;
                  const newValue = inputValue.trim() === "" ? "" : inputValue;
                  dispatch(setCustomerReference(newValue));
                }}
                value={customerReference}
                maxLength={48}
                sx={{
                  height: 61,
                  border: "1px solid",
                  borderColor: "shade_200",
                  borderRadius: "6px",
                }}
              />
            </Box>
          </Flex>

          {selectedPickupLocation &&
            selectedPickupLocation.merchant &&
            businessRulesMoneyOrders?.length !== 0 &&
            uniqueCurrencies.length > 1 && (
              <SelectCurrency
                currencies={uniqueCurrencies}
                disabled={currenciesListDisabled}
                selectedCurrency={selectedCurrency}
                label={"Currency"} //TODO
                onUpdateCurrency={(data) => {
                  onUpdateCurrency(data);
                }}
              />
            )}

          {businessRulesMoneyOrders &&
            (businessRulesMoneyOrders.length === 0 ||
              businessRulesMoneyOrders[0].orderAllow === false) && (
              <Flex mt={10} sx={{ width: 450 }} color="red">
                <>
                  <WarningMessageIcon color={"raspberry_500"} />
                  <span style={{ marginLeft: "10px" }}>
                    {t("ManageOrders.empty_businessRule_msg")}
                  </span>
                </>
              </Flex>
            )}
          {businessRulesMoneyOrders &&
            (businessRulesMoneyOrders.length === 0 ||
              businessRulesMoneyOrders[0].orderAllow === false) && (
              <Flex mt={10} sx={{ width: 450 }} color="red">
                <>
                  <WarningMessageIcon color={"raspberry_500"} />
                  <span style={{ marginLeft: "10px" }}>
                    {t("ManageOrders.select_location_msg")}
                  </span>
                  {TagManager.dataLayer({
                    dataLayer: {
                      dataLayer: {
                        event: "Click_event",
                        Action: "Error message",
                        Page_title:
                          "Order management | Order Change - Select location",
                      },
                    },
                  })}
                </>
              </Flex>
            )}
        </Box>
      </Card>
      <Flex mt={20} sx={{ flexDirection: "row-reverse" }}>
        <Button
          data-testid="register-sealbag-step1-next-btn"
          disabled={
            !selectedPickupLocation ||
            !selectedCurrency ||
            !businessRulesMoneyOrders ||
            !businessRulesMoneyOrders[0].orderAllow
          }
          onClick={(e: any) => {
            nextStep();
            TagManager.dataLayer({
              dataLayer: {
                Action: "Next",
                event: "Click_event",
                Page_title: "Order management | Order Change - Select location",
              },
            });
          }}
          sx={{
            height: 49,
            float: "right",
            cursor: "pointer",
            borderRadius: "40px",
            pointerEvents: `auto`,
            width: ["100%", "169px"],
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            "&:disabled": {
              bg: "shade_200",
              color: "shade_400",
              cursor: "not-allowed",
            },
          }}
        >
          {t("ManageSealBags.next")}
        </Button>
        <Button
          mr={20}
          variant="muted"
          data-testid="register-sealbag-step1-next-btn"
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                Action: "Cancel",
                event: "Click_event",
                Page_title: "Order management | Order Change - Select location",
              },
            });
            dispatch(resetState());
            dispatch(resetWebFilter());
            navigate(`/orders`);
          }}
          sx={{
            height: 49,
            cursor: "pointer",
            border: "1px solid",
            borderRadius: "40px",
            pointerEvents: `auto`,
            color: "royalBlue_500",
            width: ["100%", "169px"],
            borderColor: "royalBlue_500",
            backgroundColor: "background",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
    </>
  );
};
