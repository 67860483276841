import { Box, Text, Flex, Input } from "theme-ui";
import { useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { ReactComponent as RightArrow } from "@brinks/common/Images/arrow-right.svg";
import { ReactComponent as BottomArrow } from "@brinks/common/Images/arrow-bottom.svg";
import { useTranslation } from "react-i18next";
import { getDateByDays } from "@brinks/common/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  startDate: Date;
  endDate: Date;
  setStartDate: (data: Date) => void;
  setEndDate: (data: Date) => void;
}

export const Period = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: Props) => {
  const [isPeriodFilterExpanded, setPeriodFilterExpanded] = useState(true);
  const { t } = useTranslation();
  const [dateError, setDateError] = useState(false);
  const downArrow = (isExpanded: boolean, setExpanded: (a: boolean) => void) =>
    isExpanded && (
      <BottomArrow
        onClick={(e) => setExpanded(!isExpanded)}
        data-testid="advanceFilter-Period-bottom-arrow"
        style={{
          cursor: "pointer",
        }}
      />
    );
  const rightArrow = (isExpanded: boolean, setExpanded: (a: boolean) => void) =>
    !isExpanded && (
      <RightArrow
        onClick={(e) => setExpanded(!isExpanded)}
        data-testid="advanceFilter-Period-right-arrow"
        style={{
          cursor: "pointer",
        }}
      />
    );

  return (
    <Flex
      data-testid="advanceFilterDate"
      bg={"white"}
      sx={{
        flexDirection: "column",
        pt: 10,
      }}
    >
      <Flex
        sx={{ justifyContent: "space-between" }}
        data-testid="flex-period-date"
        mb={10}
      >
        <Text
          sx={{
            fontWeight: "body",
          }}
          data-testid="flex-period-date-text"
        >
          {t("Dashboard.date_lable")}
        </Text>
        <Box pr={"12px"} data-testid="advanceFilter-Period-icon-wrapper">
          {downArrow(isPeriodFilterExpanded, setPeriodFilterExpanded)}
          {rightArrow(isPeriodFilterExpanded, setPeriodFilterExpanded)}
        </Box>
      </Flex>
      {isPeriodFilterExpanded && (
        <Flex
          data-testid="advanceFilter-Period-input-wrapper"
          sx={{
            pt: "30px",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box
            sx={{
              width: "197px",
              fontSize: "body",
              fontWeight: "weight_400",
            }}
          >
            <DatePicker
              data-testid="advanceFilter-Period-input-start"
              selected={startDate}
              onChange={(e: Date) => {
                setStartDate(e);
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/MM/yyyy"
              minDate={getDateByDays(540)}
              maxDate={new Date('9999-12-31')}
            />
          </Box>
          <Text
            data-testid="advanceFilter-Period-input-to"
            sx={{
              fontSize: "body",
              color: "shade_800",
              fontWeight: "weight_400",
              lineHeight: "body",
            }}
          >
            {t("Dashboard.to")}
          </Text>
          <Box
            sx={{
              width: "197px",
              fontSize: "body",
              fontWeight: "weight_400",
            }}
          >
            <DatePicker
              data-testid="advanceFilter-Period-input-end"
              selected={endDate}
              onChange={(e: Date) => {
                if (e.getTime() <= startDate.getTime()) {
                  setDateError(true);
                  return;
                } else {
                  setDateError(false);
                  setEndDate(e);
                }
              }}
              placeholderText="dd/MM/yyyy"
              dateFormat="dd/MM/yyyy"
              maxDate={new Date('9999-12-31')}
            />
          </Box>
        </Flex>
      )}
      {dateError && (
        <Text
          data-testid="advanceFilterAmountinput-error"
          color="raspberry_500"
          sx={{
            fontSize: "subText",
            fontWeight: "weight_400",
            mt: 3,
          }}
        >
          {t("Dashboard.date_error_msg")}
        </Text>
      )}
    </Flex>
  );
};
export default Period;
