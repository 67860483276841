/** @jsxImportSource theme-ui */
export const FolderAddIcon = ({ color }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      sx={{fill:color}}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.59999 9.60001C6.94902 9.60001 4.79999 11.749 4.79999 14.4V33.6C4.79999 36.251 6.94902 38.4 9.59999 38.4H38.4C41.051 38.4 43.2 36.251 43.2 33.6V19.2C43.2 16.549 41.051 14.4 38.4 14.4H26.4L21.6 9.60001H9.59999ZM26.4 21.6C26.4 20.2745 25.3255 19.2 24 19.2C22.6745 19.2 21.6 20.2745 21.6 21.6V24H19.2C17.8745 24 16.8 25.0745 16.8 26.4C16.8 27.7255 17.8745 28.8 19.2 28.8H21.6V31.2C21.6 32.5255 22.6745 33.6 24 33.6C25.3255 33.6 26.4 32.5255 26.4 31.2V28.8H28.8C30.1255 28.8 31.2 27.7255 31.2 26.4C31.2 25.0745 30.1255 24 28.8 24H26.4V21.6Z"
      />
    </svg>
  );
};
