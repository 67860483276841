import Select from "react-select";
import { Box, Text, Flex } from "theme-ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { setWebLocation } from "@brinks/common/reducers/storeLocationSlice";
import { useEffect } from "react";
interface LocationProps {
  id: string;
  name: string;
  label: string;
  value: string;
}

interface Props {
  locations: any;
  disabled: boolean;
  onUpdateLocation: ({ id, name, label, value }: LocationProps) => void;
}

export const Location = ({ disabled, locations, onUpdateLocation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedWebLocation } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const stores =
    locations &&
    locations.map((obj: any) => {
      return { ...obj, label: obj.name, value: obj.name };
    });

  useEffect(() => {
    if (
      stores &&
      stores.length === 1 &&
      (!selectedWebLocation || selectedWebLocation.length === 0)
    ) {
      onUpdateLocation(stores);
      dispatch(setWebLocation(stores));
    }
  }, [stores]);

  return (
    <Flex
      bg={"white"}
      data-testid="advanceFilterLocation"
      sx={{ pt: "30px", flexDirection: "column" }}
    >
      <Text sx={{ fontWeight: "body" }} data-testid="flex-location-text">
        {t("Dashboard.location_lable")}
      </Text>
      <Box
        sx={{ pt: "30px" }}
        className="custome-select"
        data-testid="advanceFilter-Location-box"
      >
        <Select
          isMulti={true}
          isSearchable={true}
          isDisabled={disabled || stores.length === 1}
          value={selectedWebLocation}
          options={stores || "Fetching..."}
          data-testid="advanceFilter-Location-select"
          placeholder={t("Dashboard.location_placeholder")}
          onChange={(e: any) => {
            onUpdateLocation(e);
            dispatch(setWebLocation(e));
          }}
        />
      </Box>
    </Flex>
  );
};
export default Location;
