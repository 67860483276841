import { ChangeEvent } from "react";
import { Box, Flex, Input } from "theme-ui";
import { useTranslation } from "react-i18next";
import { ReactComponent as Location } from "@brinks/common/Icons/location.svg";

interface LocationFilterProps {
  onChangeFilter: (filterType: string, value: string) => void;
}

export const LocationFilter = ({ onChangeFilter }: LocationFilterProps) => {
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChangeFilter("location", value);
  };

  return (
    <Flex
      sx={{
        alignItems: "center",
      }}
    >
      <Box sx={{ px: 17, pt: "6px", position: "absolute" }}>
        <Location />
      </Box>
      <Input
        type={"text"}
        onChange={handleChange}
        placeholder={t("UserLocationFilter.search")}
        sx={{
          height: 54,
          p: 15,
          pl: 50,
          mt: 1,
          bg: "white",
          textAlign: "left",
          color: "shade_400",
          border: "1px solid",
          borderColor: "shade_200",
        }}
      />
    </Flex>
  );
};
