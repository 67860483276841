import { Box, Flex, Text } from "theme-ui";
import { getHourTime, weekdays } from "@brinks/common/utils";
import { StoreLocationWithContactAndMerchant } from "@brinks/common/api/Api";
import { useTranslation } from "react-i18next";

interface LocationDetailsProps {
  locationDetails: StoreLocationWithContactAndMerchant | null;
}

export const LocationOpeningTimes = ({
  locationDetails,
}: LocationDetailsProps) => {
  const { t } = useTranslation();

  const openingHours = locationDetails?.openingHours;

  const parseTime = (timeString: string): number => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600000 + minutes * 60000 + seconds * 1000;
  };

  const groupedOpeningHours = weekdays.map((day) => {
    const dayOpeningHours = openingHours?.filter(
      (item) => item.dayOfWeek === day
    );

    dayOpeningHours?.sort(
      (a, b) => parseTime(a.openingTime) - parseTime(b.openingTime)
    );

    const periods = dayOpeningHours?.map((hour) => ({
      openingTime: getHourTime(hour.openingTime),
      closingTime: getHourTime(hour.closingTime),
    }));

    return {
      day,
      periods,
    };
  });

  groupedOpeningHours.sort((a, b) => {
    const dayOrder = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
  });

  return (
    <>
      {groupedOpeningHours.map(({ day, periods }) => (
        <Flex key={day} py={26}>
          <Text
            data-testid="LocationOpening-hours-day"
            sx={{
              width: "90px",
              fontFamily: "body",
              fontWeight: "body",
              fontSize: "body",
              lineHeight: "location_label",
            }}
          >
            {t(day)}
          </Text>
          <Box
            key={day}
            pl={15}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {periods && periods.length > 0 ? (
              periods.map(({ openingTime, closingTime }, index) => (
                <Flex key={index}>
                  <Text
                    data-testid="LocationOpening-opening-time"
                    pl={30}
                    sx={{
                      fontWeight: "normal",
                      fontFamily: "body",
                      fontSize: "body",
                      lineHeight: "label",
                      marginLeft: "auto",
                    }}
                  >
                    {openingTime}
                  </Text>
                  <Text pl={10}>–</Text>
                  <Text
                    data-testid="LocationOpening-closing-time"
                    pl={10}
                    sx={{
                      fontWeight: "normal",
                      fontFamily: "body",
                      fontSize: "body",
                      lineHeight: "label",
                    }}
                  >
                    {closingTime}
                  </Text>
                </Flex>
              ))
            ) : (
              <Text
                data-testid="LocationOpening-closed"
                pl={30}
                sx={{
                  color: "beaver",
                  lineHeight: "label",
                  fontSize: "body",
                  fontWeight: "normal",
                }}
              >
                {t("ManageLocations.closed")}
              </Text>
            )}
          </Box>
        </Flex>
      ))}
    </>
  );
};
export default LocationOpeningTimes;
