import { Box, Text, Flex, Button } from "theme-ui";
import { SearchIcon } from "@brinks/common/Icons/Components/search";
import { FilterIcon } from "@brinks/common/Icons/Components/filter";
import Buttons from "../../Shared/Button";

import { useTranslation } from "react-i18next";

interface Props {
  setFilterPanel: (key: boolean) => void;
  setIsFiltered: (key: boolean) => void;
  resetFilters: () => void;
  loading: boolean;
}
export default function NoDataCard({
  setFilterPanel,
  setIsFiltered,
  resetFilters,
  loading
}: Props) {
   const { t } = useTranslation();
  return (
    <Box
      data-testid="container"
      bg={"white"}
      sx={{
        width: '80%',
        textAlign: "center",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
        margin:'0 auto',
        mt:50
      }}
    >
      <Flex
        data-testid="no-data-flex"
        sx={{
          flexDirection: "column",
        }}
      >
        <Flex
          data-testid="no-data-flex-wrapper"
          mt={56}
          pr={32}
          sx={{
            alignSelf: "flex-end",
          }}
        >
          {" "}
          <Buttons
            variant="outline"
            data-testid="btn-filter"
            onClick={() => {
              setFilterPanel(true);
              setIsFiltered(true);
              document.querySelector("#overlay")?.classList.add("overlay");
              document.body.style.overflow = "hidden";
            }}
          >
            <FilterIcon color={'primary'} />{" "}
            <Text pl={2} color="royalBlue_500" data-testid="btn-filter-txt">
              {t("Dashboard.filter")}
            </Text>
          </Buttons>
        </Flex>
        <Flex
          data-testid="no-data-search-wrapper"
          sx={{
            flexDirection: "column",
            height: "381px",
            marginTop: "93px",
            alignItems: "center",
          }}
        >
          <Box
            data-testid="no-data-search-wrapper-box"
            p={23}
            mb={"16px"}
            bg={"search_background"}
            sx={{
              width: 72,
              height: 72,
              alignItems: "center",
              borderRadius: 50,
            }}
          >
            <SearchIcon color={'royalBlue_300'} data-testid="no-data-search-wrapper-box-icon" />
          </Box>
          <Text
            data-testid="no-data-search-wrapper-box-icon-text"
            mb={"16px"}
            sx={{
              fontSize: "heading_4",
              fontFamily: "heading",
              fontWeight: "body",
              lineHeight: "33.6px",
            }}
          >
            {t("Dashboard.no_data")}  
          </Text>
          <Text
            data-testid="no-data-search-wrapper-box-icon-text-1"
            mb={"16px"}
            sx={{
              fontSize: "body",
              fontWeight: "weight_400",
              lineHeight: "body",
              color: "shade_400",
              alignItems: "center",
            }}
          >
            {t("Dashboard.try_changing_filters")}  
          </Text>
          <Button
            data-testid="no-data-search-reset"
            onClick={() => {
              resetFilters();
            }}
            disabled={loading}
            bg={"royalBlue_500"}
            mt={"56px"}
            sx={{
              borderRadius: "32px",
              width: "343px",
              height: "50px",
              cursor: "pointer",
            }}
          >
            {t("Dashboard.clear_filter")} 
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
