/** @jsxImportSource theme-ui */
export const FolderIcon = ({ color }) => {
  return (
    <svg
      width="70"
      height="48"
      viewBox="0 0 48 48"
      sx={{fill:color}}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.80005 14.4C4.80005 11.749 6.94908 9.60001 9.60005 9.60001H21.6L26.4 14.4H38.4C41.051 14.4 43.2 16.549 43.2 19.2V33.6C43.2 36.251 41.051 38.4 38.4 38.4H9.60005C6.94908 38.4 4.80005 36.251 4.80005 33.6V14.4Z"
        fillRule="evenodd" clipRule="evenodd"
      />
    </svg>
  );
};
