/** @jsxImportSource theme-ui */
import React from "react";

interface Props {
  children?: React.ReactNode;
  onClick: () => void;
  variant: string;
}
const Button = ({
  variant = "primary",
  onClick,
  children,
  ...props
}: Props) => (
  <button
    {...props}
    onClick={onClick}
    sx={{
      appearance: "none",
      cursor:'pointer',
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      lineHeight: "19.6px",
      textDecoration: "none",
      fontSize: "subText",
      fontWeight: "weight_500",
      color: `${variant === "outline" ? "royalBlue_500" : "white"}`,
      backgroundColor: `${variant === "outline" ? "white" : "primary"}`,
      border: `${variant === "outline" ? "1px solid" : 0}`,
      borderColor: `${variant === "outline" ? "shade_200" : 0}`,
      m: 0,
      px: 16,
      py: 9,
      borderRadius: 8,
      // pass variant prop to sx
      variant: `buttons.${variant}`,
    }}
  >
    {children}
  </button>
);

export default Button;
