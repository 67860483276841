import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";
import { CSSProperties, useState } from "react";

const customControlStyles: CSSProperties = {
  minWidth: "160px",
  height: "54px",
  marginTop: "4px"
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

interface StatusSelectorProps {
  onChangeFilter: (filterType: string, value: string) => void;
}

export default function StatusSelector({
  onChangeFilter,
}: StatusSelectorProps) {
  const { t } = useTranslation();

  const options = [
    { label: t("UserStatusSelect.all"), value: "ALL" },
    { label: t("UserStatusSelect.active"), value: "ACTIVE" },
    { label: t("UserStatusSelect.recovery"), value: "RECOVERY" },
    { label: t("UserStatusSelect.suspended"), value: "SUSPENDED" },
    { label: t("UserStatusSelect.locked_out"), value: "LOCKED_OUT" },
    { label: t("UserStatusSelect.provisioned"), value: "PROVISIONED" },
    {
      label: t("UserStatusSelect.password_expired"),
      value: "PASSWORD_EXPIRED",
    },
    { label: t("UserStatusSelect.deprovisioned"), value: "DEPROVISIONED" },
    { label: t("UserStatusSelect.unknown"), value: "UNKNOWN" },
  ];
  const [status, setStatus] = useState(options[0].value);

  const handleChange = (e: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Action: "Status " + e.value,
        Page_title: "User management | User overview",
      },
    });
    setStatus(e.value);
    onChangeFilter("status", e.value);
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      onChange={handleChange}
      placeholder={`Status ${""} ${""}` + status}
      data-testid="advanceFilter-UserStatus-select"
    />
  );
}
