import React from "react";
import { Text } from "theme-ui";

interface Props {
  url: string;
  text: string;
  anchorText: string;
}

const TermsAndConditionsLink: React.FC<Props> = ({
  url,
  text,
  anchorText,
}): JSX.Element => {
  return (
    <React.Fragment>
      <p style={{ marginLeft: "25px" }}>
        <Text sx={{
          fontSize: 'subText',
          fontWeight: 'weight_400'
        }}>{text}{" "}
        </Text>
        <a href={url} target="_blank" rel="noreferrer" className="term-condition">
          <Text color="primary" sx={{
            fontSize: 'subText',
            fontWeight: 'weight_400'
          }}>
            {anchorText}
          </Text>
        </a>
      </p>
    </React.Fragment>
  );
};

export default TermsAndConditionsLink;
