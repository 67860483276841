import { Box, Flex, Button } from "theme-ui";
import { useTranslation } from "react-i18next";
// import GrandTotal from "./GrandTotal";
import LocationDetails from "../LocationDetails";
import Loader from "../../../Shared/Loader";
// @ts-ignore
import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import {
  setSuccessOrderPickupMsg,
  resetState,
  submitOrderTransport,
} from "@brinks/common/reducers/orderDetailsSlice";
import TimeSlotDetails from "../timeSlotDetails";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import TagManager from "react-gtm-module";
import { getCurrentUser } from "../../../utils/secure-storage-utils";

const bg = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "420",
    height: "322",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    backgroundColor: "white",
    width: "45rem",
    maxWidth: "23%",
    padding: "30px",
    maxHeight: "50%",
    overflowY: "auto",
    position: "relative",
    borderRadius: "16px",
  },
};

export default function ReviewDeposite({ updateStep, previousStep }: any) {
  useEffect(() => {
    TagManager.dataLayer({ 
      dataLayer: {
        event: "page_view",
        Page_title: "Order management | Order Pickup - Review Order",
        Action: "Pageview Review your order"
      } 
    })
  }, []);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = getCurrentUser();

  const [isConfirmationModalOpen, toggleConfirmationModal] = useState(false);

  const {
    pickupStartDate,
    pickupendDate,
    pickupFrequency,
    loadingOrder,
    orderTransportResponse,
    selectedPickupLocation,
  } = useAppSelector((state) => state.orderDetailsSlice);

  useEffect(() => {
    if (orderTransportResponse && orderTransportResponse.length > 0) {
      navigate(`/orders`);
      dispatch(resetState());
      dispatch(setSuccessOrderPickupMsg(true));
    }
  }, [orderTransportResponse]);

  const submitOrder = () => {
    toggleConfirmationModal(!isConfirmationModalOpen);
    const collections: any = [
      {
        preparedBy: `${user?.profile?.firstName} ${user?.profile?.lastName}`,
        storeId: selectedPickupLocation.id,
        storeName: selectedPickupLocation.name,
        storeLocationCode: selectedPickupLocation.locationCode,
        order: {
          preparedBy: `${user?.profile?.firstName} ${user?.profile?.lastName}`,
          storeName: selectedPickupLocation.name,
          storeLocationCode: selectedPickupLocation.locationCode,
          orderDateTime: new Date().toISOString(),
          type: "TRANSPORT",
          status: "NEW",
          storeId: selectedPickupLocation.id,
          deliveryDateTime: pickupStartDate
            ? pickupStartDate.toISOString()
            : "",
          isFixedDelivery: false,
          totalAmount: 0,
          currency: "EUR",
          frequency: pickupFrequency.value,
          endDate: pickupendDate ? pickupendDate.toISOString() : "",
          items: [],
        },
        collectionDate: pickupStartDate ? pickupStartDate.toISOString() : "",
      },
    ];
    if (pickupFrequency.value === "ONCE" || collections[0]?.order?.endDate == '') {
      delete collections[0]?.order?.endDate;
    }
    TagManager.dataLayer({ 
      dataLayer: {
        event: "Click_event",
        Page_title: "Order management | Order Pickup - Review Order",
        Action: "Confirm"
      } 
    });
    TagManager.dataLayer({ 
      dataLayer: {
        event: "Click_event",
        Page_title: "Order management | Order Pickup - Order placed successfully",
        Action: "Pickup Order Successfull"
      } 
    })
    dispatch(submitOrderTransport({ collections }));
  };

  if (loadingOrder) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <LocationDetails order="pickup" />
      <TimeSlotDetails
        startDate={pickupStartDate}
        endDate={pickupendDate}
        frequency={pickupFrequency}
        heading={t("ManageOrders.pickup_details")}
        dateLable={t("ManageOrders.pickup_date")}
      />

      <Flex mt={20} sx={{ flexDirection: "row-reverse" }}>
        <Button
          mt={20}
          variant="primary"
          onClick={() => {
            TagManager.dataLayer({ 
              dataLayer: {
                event: "Click_event",
                Page_title: "Order management | Confirm Order Pickup - popup",
                Action: "Toggle"
              } 
            });
            toggleConfirmationModal(!isConfirmationModalOpen)
          }}
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            float: "right",
          }}
        >
          {t("ManageSealBags.order_now")}
        </Button>
        <Button
          onClick={() => {
            TagManager.dataLayer({ 
              dataLayer: {
                event: "Click_event",
                Page_title: "Order management | Order Pickup - Review Order",
                Action: "Cancel"
              } 
            });
            navigate(`/orders`);
            dispatch(resetState());
          }}
          mt={20}
          mr={20}
          variant="muted"
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            backgroundColor: "background",
            color: "royalBlue_500",
            border: "1px solid",
            borderColor: "royalBlue_500",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
      <ConfirmationModal
        toggleConfirmationModal={toggleConfirmationModal}
        isConfirmationModalOpen={isConfirmationModalOpen}
        submit={submitOrder}
        confirmationMsg={t("ManageOrders.please_confirm")}
        btnText={t("ManageOrders.place_order")}
        orderType={"pickup"}
      />
    </>
  );
}
