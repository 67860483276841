// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { TFunction } from "i18next";

export function getUserType(type: string, t: TFunction): string {
  let userTypeText: string;

  switch (type) {
    case "STANDARD_USER":
      userTypeText = t("UserList.standard_user");
      break;
    case "SUPER_USER":
      userTypeText = t("UserList.super_user");
      break;
    default:
      userTypeText = t("UserList.standard_user");
  }
  return userTypeText;
}
