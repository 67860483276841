import { Box } from "theme-ui";

interface Props {
  children: React.ReactNode;
}

export default function FieldText({ children, ...props }: Props) {
  return (
    <Box
      data-testid="FieldText"
      {...props}
      pt={"8px"}
      sx={{
        fontSize: "body",
        fontWeight: "weight-400",
        lineHeight: "body",
        color: "black",
      }}
    >
      {children}
    </Box>
  );
}
