// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";
import { Box, Flex, Button, Heading } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";
import Loader from "../../../Shared/Loader";
import StatusSelector from "./UserStatusSelector";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";

import { UserList } from "./UsersList";
import { isStandardUser } from "../utils";
import { LocationFilter } from "./LocationFilter";
import { getCurrentUser } from "@brinks/common/utils";
import { useAppDispatch } from "@brinks/common/hooks/hooks";
import { UserMerchantSelector } from "./UserMerchantSelector";
import { getUsers, resetState } from "@brinks/common/reducers/userSlice";
import { useUserWithLocations } from "../../../Hooks/useUserWithLocations";
import { isUserLocationFilter } from "../shared/types/user-location-filter.types";
import { getStoreLocations } from "@brinks/common/reducers/storeLocationSlice";
import { getMerchants } from "@brinks/common/reducers/merchantsDetailSlice";

import { AddUserIcon } from "@brinks/common/Icons/Components/add-user";

export const Users = () => {
  const { t } = useTranslation();

  const { oktaAuth } = useOktaAuth();
  const token: any = oktaAuth.getAccessToken();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserRole = getCurrentUser(token);

  const { filters, loading, merchants, filteredUsers, handleApplyFilters } =
    useUserWithLocations({});

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "User management | User overview",
      },
    });

    if (isStandardUser({ role: currentUserRole })) {
      navigate(`/`);
    }

    dispatch(getUsers({}));
    dispatch(getStoreLocations({}));
    dispatch(getMerchants({}));
  }, []);

  const onChangeFilter = (filterType: string, value: any) => {
    switch (filterType) {
      case "status":
        if(isUserLocationFilter(value)) {
          handleApplyFilters({
            ...filters,
            usersFilters: { status: value }
          });
        }
        break;
      case "merchant":
        handleApplyFilters({
          ...filters,
          locationsFilters: {
            ...filters.locationsFilters,
            merchantFilter: {
              ...filters.locationsFilters.merchantFilter,
              id: value,
            },
          },
        });
        break;
      case "location":
        handleApplyFilters({
          ...filters,
          searchBar: {
            text: value,
          },
        });
        TagManager.dataLayer({
          dataLayer: {
            event: "Click_event",
            Page_title: "User management | User overview",
            Action: "Filter by location name or code field",
          },
        });
        break;
      default:
        break;
    }
  };

  if (loading || !filteredUsers) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <PageHeader>
        <Flex
          sx={{
            width: "75%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Heading>{t("AddNewUser.users")}</Heading>
          <Button
            variant="default"
            sx={{
              height: 48,
            }}
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  Action: "Add user",
                  event: "Click_event",
                  Page_title: "User management | User overview",
                },
              });
              dispatch(resetState());
              navigate("/addnewuser");
            }}
          >
            {t("AddNewUser.adduser")}<AddUserIcon color={"currentColor"} />
          </Button>
        </Flex>
      </PageHeader>
      <Wrapper>
        <Flex mb={18} sx={{ gap: 2, alignItems: "center", flexDirection: "row", flexWrap: 'wrap' }}>
          <StatusSelector onChangeFilter={onChangeFilter} />
          <UserMerchantSelector
            merchants={merchants}
            onChangeFilter={onChangeFilter}
          />
          <LocationFilter onChangeFilter={onChangeFilter} />
        </Flex>
        <UserList users={filteredUsers} />
      </Wrapper>
    </>
  );
};
