// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";

import { Box, Heading } from "theme-ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import Loader from "../../../Shared/Loader";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import SelectDenominations from "./SelectDenominations";
import ReviewOrderChangeDetails from "./ReviewOrderChangeDetails";

import { SelectLocation } from "./SelectLocation";
import { BackIcon } from "@brinks/common/Icons/Components/back";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { getDenominations } from "@brinks/common/reducers/orderDetailsSlice";
import { useOktaAuth } from "@okta/okta-react";
import { getCurrentUserForApi } from "@brinks/common/utils";
import { getStoreLocations } from "@brinks/common/reducers/storeLocationSlice";

export const OrderChange = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading } = useAppSelector((state) => state.storeLocationSlice);
  const { loadingSaveOrder } = useAppSelector((state) => state.orderDetailsSlice);
  const [step, updateStep] = useState(1);

  const { oktaAuth } = useOktaAuth();
  const token: any = oktaAuth.getAccessToken();
  const userRole = getCurrentUserForApi(token);
  const userType = userRole;

  useEffect(() => {
    dispatch(getDenominations({}));
  }, []);

  useEffect(() => {
    dispatch(getStoreLocations({ userType: userType }));
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_title: "Order Management | Start screen"
      }
    })
  }, []);

  const nextStep = () => {
    updateStep(step + 1);
  };

  const previousStep = () => {
    if (step == 1) {
      navigate(`/orders`);
    } else {
      updateStep(step - 1);
    }
  };

  const getCurrentStep = () => {
    switch (step) {
      case 1:
        return <SelectLocation nextStep={nextStep} />;
      case 2:
        return (
          <SelectDenominations nextStep={nextStep} updateStep={updateStep} />
        );
      case 3:
        return <ReviewOrderChangeDetails previousStep={previousStep} />;
      default:
      // do nothing
    }
  };

  if (loading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <PageHeader>
        <Heading>
          <button
            style={{ marginRight: "20px", cursor: "pointer", background: "transparent", border: "none" }}
            onClick={(e: any) => {
              previousStep();
              TagManager.dataLayer({
                dataLayer: {
                  dataLayer: {
                    Action: "Previous",
                    event: "Click_event",
                    Page_title: "Order management | Order Change - Select location",
                  }
                }
              });
            }}
          >
            <BackIcon color={"black"} />
          </button>
          {step === 3 ? t("Header.review_order") : t("Header.order_change")}
          {
            step === 3 && (
              TagManager.dataLayer({
                dataLayer: {
                  event: "page_view",
                  Action: "page_view",
                  Page_title: "Order management | Order Change - Review Order",
                }
              })
            )
          }

        </Heading>
      </PageHeader>
      <Wrapper>{getCurrentStep()}</Wrapper>
    </>
  );
}
