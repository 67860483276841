import { Box } from "theme-ui";

interface Props {
  children: React.ReactNode;
}

export default function Label({ children, ...props }: Props) {
  return (
    <Box
      data-testid="label-wrapper"
      {...props}
      sx={{
        fontSize: "body",
        fontWeight: "weight-400",
        lineHeight: "22.4px",
        color: "shade_400",
      }}
    >
      {children}
    </Box>
  );
}
