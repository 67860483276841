import { useMemo } from "react";
import { Box, Text, Flex, Divider, Label } from "theme-ui";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@brinks/common/hooks/hooks";
import { getCurrentUser } from "../../utils/secure-storage-utils";

interface LocationDetailsProps {
  order: "change" | "pickup";
  customerReference?: string | null;
}

export const LocationDetails = ({
  order,
  customerReference,
}: LocationDetailsProps) => {
  const {
    selectedPickupLocation,
    selectedOrderMerchant,
    selectedPickupOrderMerchant,
  } = useAppSelector((state) => state.orderDetailsSlice);
  const { t } = useTranslation();
  const user = getCurrentUser();

  const merchantForUse = useMemo(() => {
    return order === "pickup"
      ? selectedPickupOrderMerchant
      : selectedOrderMerchant;
  }, []);

  const handleGetMerchantAddress = () => {
    const merchant = merchantForUse
      ? merchantForUse
      : selectedPickupLocation?.merchant;

    if (merchant) {
      const { city, state, street, country, postalCode } = merchant;
      let addressParts = [];

      if (street) addressParts.push(street);
      if (city) addressParts.push(city);
      if (state) addressParts.push(state);
      if (country) addressParts.push(country);
      if (postalCode) addressParts.push(postalCode);

      return addressParts.join(", ");
    }

    return "---";
  };

  return (
    <Flex
      mt={32}
      bg="white"
      sx={{
        p: 35,
        borderBottom: "1px solid",
        borderColor: "borderColor",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: 8,
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Flex
          mt={20}
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          data-testid="LocationDetails-phone"
        >
          <Label
            sx={{
              fontSize: "subText",
              color: "seal_bag",
              fontWeight: "normal",
              lineHeight: "register_bag",
            }}
            data-testid="LocationDetails-phone-label"
          >
            {t("ManageOrders.merchant_name")}
          </Label>
          <Text
            sx={{
              fontSize: "body",
              fontWeight: "body",
              lineHeight: "label",
              fontStyle: "normal",
            }}
            mt={10}
            data-testid="LocationDetails-merchant_name_value"
          >
            {merchantForUse
              ? merchantForUse.name
              : selectedPickupLocation?.merchant?.name}
          </Text>
        </Flex>

        <Flex
          mt={20}
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          data-testid="LocationDetails-phone"
        >
          <Label
            sx={{
              fontSize: "subText",
              color: "seal_bag",
              fontWeight: "normal",
              lineHeight: "register_bag",
            }}
            data-testid="LocationDetails-phone-label"
          >
            {t("ManageOrders.merchant_address")}
          </Label>
          <Text
            mt={10}
            data-testid="LocationDetails-merchant_name_value"
            sx={{
              width: "100%",
              fontSize: "body",
              fontWeight: "body",
              lineHeight: "label",
              fontStyle: "normal",
            }}
          >
            {handleGetMerchantAddress()}
          </Text>
        </Flex>
        <Divider sx={{ width: "100%" }} mt={30} mb={30} color="divider" />

        <Flex
          mt={20}
          data-testid="LocationDetails-field1"
          sx={{
            flexDirection: "column",
          }}
        >
          <Label
            sx={{
              fontSize: "subText",
              color: "seal_bag",
              fontWeight: "normal",
              lineHeight: "register_bag",
            }}
            data-testid="LocationDetails-name"
          >
            {t("ManageOrders.pickup_location")}
          </Label>
          <Text
            sx={{
              fontSize: "body",
              fontWeight: "body",
              lineHeight: "label",
              fontStyle: "normal",
            }}
            mt={10}
            data-testid="LocationDetails-name-value"
          >
            {selectedPickupLocation?.name}
          </Text>
        </Flex>
        <Flex
          mt={20}
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          data-testid="LocationDetails-phone"
        >
          <Label
            sx={{
              fontSize: "subText",
              color: "seal_bag",
              fontWeight: "normal",
              lineHeight: "register_bag",
            }}
            data-testid="LocationDetails-phone-label"
          >
            {t("ManageSealBags.prepared_by")}
          </Label>
          <Text
            sx={{
              fontSize: "body",
              fontWeight: "body",
              lineHeight: "label",
              fontStyle: "normal",
            }}
            mt={10}
            data-testid="LocationDetails-phone-value"
          >
            {user?.profile?.firstName} {user?.profile?.lastName}
          </Text>
        </Flex>

        {customerReference && (
          <Flex
            mt={20}
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            data-testid="LocationDetails-customer-reference"
          >
            <Label
              sx={{
                fontSize: "subText",
                color: "seal_bag",
                fontWeight: "normal",
                lineHeight: "register_bag",
              }}
              data-testid="LocationDetails-customer-reference-label"
            >
              {t("ManageOrders.customer_order_label")}
            </Label>
            <Text
              sx={{
                fontSize: "body",
                fontWeight: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
              mt={10}
              data-testid="LocationDetails-customer-reference-value"
            >
              {customerReference}
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
export default LocationDetails;
