import React, { Suspense } from "react";
import "dotenv/config";

import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// @ts-ignore
import ReactModal from "react-modal";
import reportWebVitals from "./reportWebVitals";
import "./i18";
import Loader from "./Shared/Loader";
import { Flex } from "theme-ui";

const pageLoader = (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Loader />
  </Flex>
);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>{pageLoader}</div>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

ReactModal.setAppElement("#root");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
