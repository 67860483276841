// --------------------------------------------------------------------------------
//  EXTERNAL IMPORTS
// --------------------------------------------------------------------------------
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Card, Divider, Heading } from "theme-ui";

// --------------------------------------------------------------------------------
//  INTERNAL IMPORTS
// --------------------------------------------------------------------------------
import CardHeader from "./CardHeader";
import Loader from "../../Shared/Loader";
import CustomerDetails from "./CustomerDetails";
import LocationDetails from "./LocationDetails";
import PageHeader from "../../Shared/PageHeader";
import Wrapper from "../../Shared/Wrapper/Wrapper";
import TransactionDetails from "./TransactionDetails";

import { BackIcon } from "@brinks/common/Icons/Components/back";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { getTransactionDetails } from "@brinks/common/reducers/transactionDetailSlice";

export default function TransactionDetail() {
  const { t } = useTranslation();
  const { id, type } = useParams();
  const dispatch = useAppDispatch();

  const { loading, transactionDetails } = useAppSelector(
    (state) => state.transactionDetailSlice
  );

  useEffect(() => {
    const obj = {
      transactionId: id,
      transactionType: type,
    };

    dispatch(getTransactionDetails(obj));
  }, [id]);

  if (loading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <PageHeader>
        <Heading>
          <button
            onClick={(e: any) => window.history.back()}
            style={{ marginRight: "20px", background: "transparent", border: "none", cursor: "pointer" }}
          >
            <BackIcon color={"black"} />
          </button>
          {t("TransactionDetails.transaction_details")}
        </Heading>
      </PageHeader>
      <Wrapper>
        <Box data-testid="TransactionDetail-container">
          <Card
            bg="white"
            data-testid="TransactionDetail-container-card"
            sx={{
              margin: "0 auto",
              alignItems: "center",
              borderRadius: "16px",
              padding: "39px 32px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardHeader transactionDetails={transactionDetails} />
            <Divider mt={39} color="divider" />
            <TransactionDetails transactionDetails={transactionDetails} />
            {
              transactionDetails?.type === "CLICKS" ||
              transactionDetails?.type === "CARDS" ? (
                <>
                  <Divider mt={39} color="divider" />
                  <CustomerDetails transactionDetails={transactionDetails} />
                </>
              ) : null
            }
            <Divider mt={39} color="divider" />
            <LocationDetails transactionDetails={transactionDetails} />
          </Card>
        </Box>
      </Wrapper>
    </>
  );
}
