// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { useMemo } from 'react';
import { Flex, Text } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { StoreLocation } from "@brinks/common/api/Api";
import { countDistinctMerchants } from "../../../../../../utils/merchants";
import { useUserWithLocations } from "../../../../../../Hooks/useUserWithLocations";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface RowMerchantElementProps {
  locations: StoreLocation[];
}

export const RowMerchantElement = ({ 
  locations, 
}: RowMerchantElementProps) => {
  const { merchants } = useUserWithLocations({});

  const countOfDistinctMerchants = useMemo(() => {
    return countDistinctMerchants(locations);
  }, [locations])

  const merchant = useMemo(() => {
    return merchants.find((merchant) => {
      return merchant.id === locations[0]?.merchantId
    });
  }, [merchants])

  if(!locations || (locations && locations.length === 0)) {
    return (
      <Text sx={{ color: "shade_600", fontWeight: "weight_400"}}>
        {`--`}
      </Text>
    )
  };

  return (
    <Flex sx={{ display: 'flex', alignItems: 'center'}}>
      <Flex sx={{ color: "shade_600", fontWeight: "weight_400"}}>
          {merchant?.name ?? '--'}
      </Flex>
      {countOfDistinctMerchants > 1 &&   
        <Flex
          ml={3}
          sx={{
            width: 45,
            height: 32,
            borderRadius: 4,
            bg: "borderColor",
            color: "shade_500",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {`+ ${countOfDistinctMerchants - 1}`}
        </Flex>
      }
    </Flex>
  );
}
