// --------------------------------------------------------------------------------
//  EXTERNAL IMPORTS
// --------------------------------------------------------------------------------
import moment from "moment-timezone";

import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

// --------------------------------------------------------------------------------
//  INTERNAL IMPORTS
// --------------------------------------------------------------------------------
import { colors } from "../colors";
import { transactionFilterData } from "@brinks/common/constants/transactionTypeData";
import {
  Api,
  MerchantsAndLocations,
  Transaction,
  TransactionType,
} from "../api/Api";
import { 
  groupByDate, 
  getDateByDays, 
  getTotalAmount, 
  getCurrentDate, 
  getFilterParams,
} from "../utils/index";
import { UserType } from "../constants/users";

const cardsDataset = (
  payload: Transaction[],
  labelDates: string[],
  selectedFilterAction: string
) => {
  const transactionType = "CARDS";
  const data = {
    data: mapTransactionsToDate(
      labelDates,
      payload,
      transactionType,
      selectedFilterAction
    ),
    color: colors.brightNavyBlue,
  };
  return data;
};

const cashDataset = (
  payload: Transaction[],
  labelDates: string[],
  selectedFilterAction: string
) => {
  const transactionType = "CASH";
  const data = {
    data: mapTransactionsToDate(
      labelDates,
      payload,
      transactionType,
      selectedFilterAction
    ),
    color: colors.blue,
  };
  return data;
};

const clicksDataset = (
  payload: Transaction[],
  labelDates: string[],
  selectedFilterAction: string
) => {
  const transactionType = "CLICKS";
  const data = {
    data: mapTransactionsToDate(
      labelDates,
      payload,
      transactionType,
      selectedFilterAction
    ),
    color: colors.caribbeanGreen,
  };
  return data;
};

const getTransactionAvailabilityByTransactionType = (
  transactionAvailabilityByTransactionType: TransactionAvailabilityByTransactionType, 
  selectedPaymentTypeFilter: string, 
  transactins: Transaction[]
) => {
  const transactionAvailability =  transactionAvailabilityByTransactionType;
  
  if(selectedPaymentTypeFilter.toUpperCase() === 'ALL') {
    const cardTransaction = transactins.find(t => t.type == TransactionType.CARDS);
    const clickTransaction = transactins.find(t => t.type == TransactionType.CLICKS);
    const cashTransaction = transactins.find(t => t.type == TransactionType.CASH);
    transactionAvailability.cards = cardTransaction ? true : false;
    transactionAvailability.clicks = clickTransaction ? true : false;
    transactionAvailability.cash = cashTransaction ? true : false;
  }

  return transactionAvailability;
}

const getDatasets = (
  selectedPaymentTypeFilter: string,
  selectedQuickTimeFilter: number,
  labelDates: string[],
  payload: any,
  selectedFilterAction: string,
) => {
  let datasets: { data: number[]; color: string }[] = [];
  if (selectedPaymentTypeFilter == "clicks") {
    datasets = [clicksDataset(payload, labelDates, selectedFilterAction)];
  } else if (selectedPaymentTypeFilter == "cards") {
    datasets = [cardsDataset(payload, labelDates, selectedFilterAction)];
  } else if (selectedPaymentTypeFilter == "cash") {
    datasets = [cashDataset(payload, labelDates, selectedFilterAction)];
  } else {
    datasets = [
      cashDataset(payload, labelDates, selectedFilterAction),
      cardsDataset(payload, labelDates, selectedFilterAction),
      clicksDataset(payload, labelDates, selectedFilterAction),
    ];
  }
  return datasets;
};

const getLabels = (periodFilter: PeriodFilterType, days: number) => {
  const goBackDays = days;
  const startDate = new Date(periodFilter.selectedPeriodStartDate);
  const labelNames = [];
  const labelDates = [];
  for (var i = 0; i < goBackDays; i++) {
    let increment = 0;
    if(i !== 0) {
      increment = 1;
    }
    const newDate = new Date(startDate.setDate(startDate.getDate() + increment));
    if (days == 7) {
      labelNames.push(newDate.toLocaleString("en-us", { weekday: "short" }));
    } else {
      labelNames.push(
        `${newDate.toLocaleString("en-us", {
          month: "short",
        })} ${newDate.getDate()}`
      );
    }
    labelDates.push(newDate.toISOString().split("T")[0]);
  }
  if (days != 7) {
    labelNames[28] = labelNames[29];
  }
  return { labelNames, labelDates };
};

export type TransactionMap = Record<string, Transaction[]>;

export interface TransactionFilterType {
  type: string;
  label: string;
  action: string;
}
export interface PeriodFilterType {
  selectedPeriodEndDate: Date;
  selectedPeriodStartDate: Date;
}

export interface TransactionAvailabilityByTransactionType {
  cash: boolean;
  cards: boolean;
  clicks: boolean;
}

export interface AmountFilterType {
  selectedAmountType: string;
  selectedMinAmount: number | null;
  selectedMaxAmount: number | null;
}

export interface PaymentMethodFilter {
  selectedPaymentMethod: Array<String>;
}

export interface LocationFilter {
  selectedLocation: Array<String>;
}

// Define a type for the slice state
export interface DashboardSliceState {
  loading: Boolean;
  userType: UserType;
  totalAmount: string;
  noOfTransactions: number;
  viewAdvanceFilter: boolean;
  selectedAmountType: string;
  selectedPeriodEndDate: Date;
  transactions: TransactionMap;
  selectedPeriodStartDate: Date;
  selectedLocation: Array<String>,
  selectedQuickTimeFilter: number;
  selectedMinAmount: number | null,
  selectedMaxAmount: number | null,
  selectedPaymentTypeFilter: string;
  selectedPaymentMethod: Array<String>,
  transactionsFromBackend: Transaction[];
  selectedTransactionFilterAction: string;
  datasets: { data: number[]; color: string }[];
  selectedTransactionFilterType: TransactionFilterType;
  label: { labelNames: string[]; labelDates: string[] };
  transactionAvailabilityByTransactionType: TransactionAvailabilityByTransactionType;
  currentMerchant: MerchantsAndLocations | null;
  currentCountry: string,
  currentCurrency: string
}

// Define the initial state using that type
const initialState: DashboardSliceState = {
  datasets: [],
  loading: false,
  transactions: {},
  noOfTransactions: 0,
  totalAmount: `€0.00`,
  selectedLocation: [],
  selectedMinAmount: null,
  selectedMaxAmount: null,
  viewAdvanceFilter: false,
  selectedAmountType: 'all',
  selectedQuickTimeFilter: 7,
  transactionsFromBackend: [],
  selectedPaymentMethod: ['all'],
  selectedPaymentTypeFilter: "all",
  userType: UserType.STANDARD_USER,
  selectedPeriodEndDate: getCurrentDate(),
  selectedPeriodStartDate: getDateByDays(7),
  label: { labelNames: [], labelDates: [] },
  selectedTransactionFilterType: transactionFilterData[0],
  selectedTransactionFilterAction: transactionFilterData[0].action,
  transactionAvailabilityByTransactionType: {cards: true, cash: true, clicks: true},
  currentMerchant: null,
  currentCountry: "",
  currentCurrency: ""
};

export const getTransactions = createAsyncThunk<
  any,
  any,
  {}
>("transactions", (data: DashboardSliceState, thunkAPI) => {
  const query = getFilterParams(data);
  const res = new Api().transactions.getTransactions(query);
  return res;
});

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedPeriodFilter: (state, action: PayloadAction<PeriodFilterType>) => {
      const endDate = moment(action.payload.selectedPeriodEndDate);
      const startDate = moment(action.payload.selectedPeriodStartDate);
      const days = endDate.diff(startDate, 'days') + 1;
      state.selectedQuickTimeFilter = days;
      state.selectedPeriodStartDate = action.payload.selectedPeriodStartDate;
      state.selectedPeriodEndDate = action.payload.selectedPeriodEndDate;
      state.label = getLabels(action.payload, days);
    },

    setCurrentMerchant: (state, action) => {
      state.currentMerchant = action.payload;
      state.currentCountry = action.payload.country;
      state.currentCurrency = action.payload.currency;
    },

    resetFilter:() => initialState,

    setSelectedAmountFilter: (state, action: PayloadAction<AmountFilterType>) => {
      state.selectedAmountType = action.payload.selectedAmountType;
      state.selectedMinAmount = action.payload.selectedMinAmount;
      state.selectedMaxAmount = action.payload.selectedMaxAmount;
    },

    setSelectedPaymentMethodFilter: (state, action: PayloadAction<PaymentMethodFilter>) => {
      state.selectedPaymentMethod = action.payload.selectedPaymentMethod;
    },

    setSelectedLocationFilter: (state, action: PayloadAction<LocationFilter>) => {
      state.selectedLocation = action.payload.selectedLocation;
    },

    setSelectedPaymentTypeFilter: (state, action: PayloadAction<string>) => {
      state.selectedPaymentTypeFilter = action.payload;
    },
    setTransactionTypeFilter: (state, action: PayloadAction<TransactionFilterType>) => {
      state.selectedTransactionFilterType = action.payload;
      state.selectedTransactionFilterAction = action.payload?.action;
    },

    setViewAdvanceFilter: (state, action: PayloadAction<boolean>) => {
      state.viewAdvanceFilter = action.payload;
    },
  },
  extraReducers: {
    [getTransactions.pending.toString()]: (state: DashboardSliceState) => {
      state.loading = true;
      state.datasets = [{ data: [0, 0, 0, 0, 0, 0, 0], color: '' }];
      state.transactions = {};
      state.totalAmount = `€0.00`;
      state.noOfTransactions = 0;
    },
    [getTransactions.fulfilled.toString()]: (
      state: DashboardSliceState,
      { payload }: any
    ) => {
      (state.loading = false),
      (state.transactionsFromBackend = payload.data.transactions),
        (state.transactions = groupByDate(payload.data.transactions)),
        (state.totalAmount = getTotalAmount(payload.data.transactions, state.currentCurrency, state.currentCountry));
        (state.noOfTransactions = payload.data.transactions.length);
        state.transactionAvailabilityByTransactionType = getTransactionAvailabilityByTransactionType(state.transactionAvailabilityByTransactionType, state.selectedPaymentTypeFilter, payload.data.transactions);
      state.datasets = getDatasets(
        state.selectedPaymentTypeFilter,
        state.selectedQuickTimeFilter,
        state.label.labelDates,
        payload.data.transactions,
        state.selectedTransactionFilterAction
      );
    },
    [getTransactions.rejected.toString()]: (state: DashboardSliceState,payload) => { 
      state.loading = false;
    },
  },
});

export const {
  resetFilter,
  setViewAdvanceFilter,
  setSelectedPeriodFilter,
  setSelectedAmountFilter,
  setTransactionTypeFilter,
  setSelectedLocationFilter,
  setSelectedPaymentTypeFilter,
  setSelectedPaymentMethodFilter,
  setCurrentMerchant,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
function mapTransactionsToDate(
  labelDates: string[],
  payload: Transaction[],
  transactionType: string,
  selectedFilterAction: string
) {
  return labelDates.map((el) => {
    if (selectedFilterAction == "count") {
      return payload.filter(
        (t) => t.dateTime.split("T")[0] === el && t.type === transactionType
      ).length;
    } else {
      return payload
        .filter(
          (t) => t.dateTime.split("T")[0] === el && t.type === transactionType
        )
        .reduce((a, b) => a + b.grossAmount, 0);
    }
  });
}
