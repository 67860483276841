// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { useMemo } from 'react';
import { Flex, Text } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { StoreLocationWithMerchant, MerchantWithDependentsAndLocations } from "@brinks/common/api/Api";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface RowMerchantElementToLocationProps {
  location: StoreLocationWithMerchant;
  merchants: MerchantWithDependentsAndLocations[];
}

export const RowMerchantElementToLocation = ({ 
  location,
  merchants,
}: RowMerchantElementToLocationProps) => {
  const merchant = useMemo(() => {
    return merchants.find((merchant) => merchant.id === location?.merchantId);
  }, [location])

  if(!location) {
    return (
      <Text sx={{ color: "shade_600", fontWeight: "weight_400"}}>
        {`--`}
      </Text>
    )
  };

  return (
    <Flex sx={{ display: 'flex', alignItems: 'center'}}>
      <Flex sx={{ color: "shade_600", fontWeight: "weight_400"}}>
          {merchant?.name ?? '--'}
      </Flex>
    </Flex>
  );
}
