import { useTranslation } from "react-i18next";
import { Flex } from "theme-ui";

interface Props {
  label: string;
}
export default function TransactionLabel(props: Props) {
  const { label } = props;
  const { t } = useTranslation();
  let color: string = "";
  if (label === "CASH") {
    color = "cash_transaction";
  } else if (label === "CARDS") {
    color = "cards_transaction";
  } else {
    color = "clicks_transaction";
  }
  const labels = label.split('').splice(1,5).join('').toLocaleLowerCase();
  return (
    <Flex
      data-testid="TransactionLabel"
      bg={color}
      sx={{
        width: 45,
        height: 19,
        fontSize: "small",
        textAlign: "center",
        color: "white",
        padding: "1px 8px 1px 8px",
        lineHeight:'16.8px',
        borderRadius: "4px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {t(label.charAt(0) + labels)}
    </Flex>
  );
}
