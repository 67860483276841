// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { Flex, Text } from "theme-ui";
import { useState, useEffect } from 'react';

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { 
  getAllMerchantWithMerchantsAssociated 
} from "../../../../utils/merchants/MerchantWithMerchantsAssociated.utils";
import { 
  StoreLocationWithMerchant,
  MerchantWithDependentsAndLocations
} from "@brinks/common/api/Api";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface LocationMerchantDetailsProps {
  location: StoreLocationWithMerchant;
}

export const LocationMerchantDetails = ({ 
  location, 
}: LocationMerchantDetailsProps) => {
  const [merchants, setMerchants] = useState<MerchantWithDependentsAndLocations[]>([]);


  const validateMerchants = () => {
    if(location.merchant) {
      const merchants = getAllMerchantWithMerchantsAssociated(location.merchant);
      setMerchants(merchants);
    }
  }

  useEffect(() => {
    validateMerchants();
  }, [])

  if(!location) {
    return (
      <Text sx={{ color: "shade_600", fontWeight: "weight_400"}}>
        {`--`}
      </Text>
    )
  };

  return (
    <Flex sx={{ display: 'flex', alignItems: 'center'}}>
      <Flex sx={{ color: "shade_600", fontWeight: "weight_400"}}>
          {location.merchant?.name ?? '--'}
      </Flex>
    </Flex>
  );
}
