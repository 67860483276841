import { Box, Text, Flex } from "theme-ui";
import Select, { StylesConfig } from "react-select";
import { CSSProperties, useEffect } from "react";
import { useAppSelector } from "@brinks/common/hooks/hooks";
import { useTranslation } from "react-i18next";
interface LocationProps {
  id: string;
  label: string;
  name: string;
  value: string;
}

interface Props {
  locations: any;
  onUpdateLocation: ({ id, name, label, value }: LocationProps) => void;
}
const customControlStyles: CSSProperties = {
  height: "61px",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

export const Location = ({ locations, onUpdateLocation }: Props) => {
  const { selectedLocation } = useAppSelector(
    (state) => state.registerSealbagSlice
  );

  const { currentMerchant } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );

  const { t } = useTranslation();

  const stores =
    locations &&
    locations.map((obj: any) => {
      return { ...obj, label: obj.name, value: obj.id };
    });

  useEffect(() => {
    if (stores && selectedLocation === null && stores.length === 1) {
      onUpdateLocation(stores[0]);
    }
  }, [stores]);

  return (
    <Flex
      data-testid="advanceFilterLocation"
      bg={"white"}
      sx={{
        flexDirection: "column",
        zIndex: 999,
      }}
    >
      <Text
        sx={{
          marginTop: "14px",
          fontWeight: "body",
          fontSize: "subText",
        }}
        data-testid="flex-location-text"
      >
        {t("ManageSealBags.location_lable")}
      </Text>
      <Box
        data-testid="advanceFilter-Location-box"
        className="custome-select"
        sx={{
          pt: "8px",
          width: "343px",
        }}
      >
        <Select
          data-testid="advanceFilter-Location-select"
          value={selectedLocation}
          isDisabled={stores.length === 1}
          isSearchable={true}
          styles={customStyles}
          onChange={(e: any) => {
            onUpdateLocation(e);
          }}
          options={(currentMerchant && stores) || "Fetching..."} // @TODO: when clicked an error is thrown
          placeholder={t("ManageSealBags.select_location")}
        />
      </Box>
    </Flex>
  );
};
export default Location;
